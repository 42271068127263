<template>
    <div>
        <el-card v-loading="loading">
            <div slot="header">
                <span><i class="el-icon-data-board" :style="{ marginRight: '5px', color: '#0027ff' }"></i>合同详情</span>
            </div>
            <el-descriptions size="mini" :column="1">

                <!-- 合同状态 -->
                <el-descriptions-item label="合同状态">
                    <el-dropdown trigger="click" @command="ContractStateEdit">
                        <el-link type="primary">{{ contract.state }}<i class="el-icon-edit el-icon--right"></i></el-link>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item icon="el-icon-guide" command="待审核">待审核</el-dropdown-item>
                            <el-dropdown-item icon="el-icon-guide" command="已寄出">已寄出</el-dropdown-item>
                            <el-dropdown-item icon="el-icon-guide" command="已收回">已收回</el-dropdown-item>
                            <el-dropdown-item icon="el-icon-guide" command="待打印签章寄出">待打印签章寄出</el-dropdown-item>
                            <el-dropdown-item icon="el-icon-guide" command="未收回">未收回</el-dropdown-item>
                            <el-dropdown-item icon="el-icon-guide" command="结束">结束</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </el-descriptions-item>

                <!-- 合同编号 & 合同名称 & 关联客户 & 关联项目 -->
                <el-descriptions-item label="合同编号">{{ contract.number }}</el-descriptions-item>
                <el-descriptions-item label="合同名称">{{ contract.name }}</el-descriptions-item>
                <el-descriptions-item label="关联客户">{{ contract.customer?.number + '-' + contract.customer?.name }}</el-descriptions-item>

                <!-- 关联项目 & 关联销售跟单 -->
                <el-descriptions-item label="关联项目">
                    <el-link type="warning" v-if="!contract.project_id">未关联</el-link>
                    <el-link type="primary" v-if="contract.project_id" @click="$refs.projectsDetails.Open(contract.project.id)">
                        {{ contract.project.number + '-' + contract.project?.name }}
                    </el-link>
                </el-descriptions-item>
                <el-descriptions-item label="关联商机">
                    <el-link type="warning" v-if="!contract.sale_documentary_id">未关联</el-link>
                    <el-link type="primary" v-if="contract.sale_documentary_id" @click="$refs.businessDrawer.Open(contract.sale_documentary_id)">
                        {{ contract.sale_documentary.number + '-' + contract.sale_documentary?.name }}
                    </el-link>
                </el-descriptions-item>

                <!-- 销售跟单 -->
                <el-descriptions-item label="合同来源">{{ contract.source.toString() }}</el-descriptions-item>
                <el-descriptions-item label="签单日期"><contract-link-sign-date v-model="contract.sign_date" :contract_id="contract.id" update /></el-descriptions-item>
                <el-descriptions-item label="合同期限"><contract-link-time-limit v-model="contract.time_limit" :contract_id="contract.id" update /></el-descriptions-item>
                <el-descriptions-item label="销售">{{ contract.user_sale.name }}</el-descriptions-item>
                <el-descriptions-item label="商务">{{ contract.user_affair.name }}</el-descriptions-item>
                <el-descriptions-item label="售前">{{ contract.user_presale.name }}</el-descriptions-item>
                <el-descriptions-item label="管理员">{{ contract.user_admin.name }}</el-descriptions-item>
                <el-descriptions-item label="创建日期">{{ contract.created_at }}</el-descriptions-item>
                <el-descriptions-item label="更新日期">{{ contract.updated_at }}</el-descriptions-item>

            </el-descriptions>
        </el-card>

        <crm-business-drawer ref="businessDrawer" @onChange="Search" />
        <pm-projects-drawer ref="projectsDetails" @success="Search" />

    </div>
</template>

<script>
import { Loading } from 'element-ui';

export default {

    props: ['id'],

    data() {
        return {
            loading: false,

            contract: {
                id: null,
                source: [],
                customer: {},
                project: {},
                time_limit: [],
                user_admin: {},
                user_affair: {},
                user_presale: {},
                user_sale: {},
                files_id: []
            }
        }
    },

    watch: {
        id: {
            handler (val) {
                if (val) {
                    this.Search()
                }
            },
            immediate: true
        }
    },

    methods: {

        // 合同 - 查询
        Search() {
            this.loading = true
            this.$http.post(
                '/9api/crm/contract/search-first',
                {
                    id: this.id,
                    append: ['customer', 'user_sale', 'user_admin', 'user_affair', 'user_presale', 'source', 'team_read', 'project', 'sale_documentary'],
                    hidden: ['describe']
                }
            ).then(rps => {
                this.contract = rps.data
                this.loading = false
            })
        },

        // 合同 - 状态修改
        ContractStateEdit(state) {
            this.$http.post('/9api/contract/update-state', {id: this.contract.id, state: state}).then(rps => {
                this.RePage()
            })
        },
    }
}

</script>


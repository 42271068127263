<!---------------------------------------------------------------------------- 
 | 工单报障 - 创建
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | $refs[ref_name].Open(*id) 手动打开窗口,
 | $refs[ref_name].Close() 手动关闭窗口
 |----------------------------------------------------------------------------
 | 回调
 | @onChange = fn (Object) => {}
 |
 ---------------------------------------------------------------------------->

 <template>
    <el-drawer class="w-el-drawer" :visible.sync="visible" :size="960" :with-header="false" direction="rtl" :before-close="Close" append-to-body destroy-on-close :wrapperClosable="false" :close-on-press-escape="false">
        
        <!-- header -->
        <div class="w-el-drawer-header">
            <i class="el-icon-tickets"></i> 新建工单
        </div>

        <!-- body -->
        <div class="w-el-drawer-body"  v-loading="loading">
           
            <!-- 表单 -->
            <el-form :model="form" label-width="120px" size="mini">

                <!-- 关联项目 -->
                <el-form-item label="关联项目">
                    <pm-projects-select ref="projectsSelect" v-model="form.projects_id" @onChange="ProjectsOnChange" clearable />
                </el-form-item>

                <!-- 关联任务 -->
                <el-form-item label="关联任务">
                    <pm-task-select ref="projectsTaskSelect" v-model="form.projects_task_id" :projectsId="form.projects_id" @onChange="ProjectsTaskOnChange" clearable />
                </el-form-item>

                <!-- 关联客户 -->
                <el-form-item :rules="[{ required: true, message: '必填项' }]" label="关联客户" :error="formError.customer_id?.toString()" prop="customer_id">
                    <crm-customer-select v-model="form.customer_id" clearable />
                </el-form-item>

                <!-- 工单信息 -->
                <el-divider content-position="left">工单信息</el-divider>

                <el-form-item label="工单名称" :rules="[{ required: true, message: '必填项' }]" :error="formError.name?.toString()" prop="name">
                    <el-input v-model="form.name" placeholder="请填写工单名称" clearable />
                </el-form-item>

                <el-form-item label="工单模板" :rules="[{ required: true, message: '必选项' }]" :error="formError.template_id?.toString()" prop="template_id">
                    <wo-template-select v-model="form.template_id" />
                </el-form-item>

                <!-- 紧急程度 & 工单单号 -->
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="紧急程度" :rules="[{ required: true, message: '必填项' }]" :error="formError.p?.toString()" prop="p">
                            <projects-task-select-p v-model="form.p" clearable filterable />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="工单单号">
                            <el-input v-model="form.number" placeholder="请填写工单单号,不填写将会自动生成！" clearable />
                        </el-form-item>
                    </el-col>
                </el-row>

                <!-- 收单人 & 协同人 -->
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="收单人" :rules="[{ required: true, message: '必选项' }]" :error="formError.user_receive_id?.toString()" prop="user_receive_id">
                            <users-select v-model="form.user_receive_id"></users-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="协同人">
                            <users-select v-model="form.user_assist_id" multiple></users-select>
                        </el-form-item>
                    </el-col>
                </el-row>

                <!-- 收单人 & 协同人 -->
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="管理员" :rules="[{ required: true, message: '必选项' }]" :error="formError.user_admin_id?.toString()" prop="user_admin_id">
                            <users-select v-model="form.user_admin_id"></users-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="派单人" :rules="[{ required: true, message: '必选项' }]" :error="formError.user_sending_id?.toString()" prop="user_sending_id">
                            <users-select v-model="form.user_sending_id"></users-select>
                        </el-form-item>
                    </el-col>
                </el-row>

                <!-- 起止时间 & 预估工时-->
                <el-row>
                    <el-col :span="12">
                        <el-form-item :rules="[{ required: true, message: '必填项' }]" label="起止时间" :error="formError.range_at?.toString()" prop="range_at">
                            <el-date-picker v-model="form.range_at" style="width: 100%;" type="daterange" value-format="yyyy-MM-dd" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :rules="[{ required: true, message: '必选项' }]" label="预估工时/P" :error="formError.day_predict?.toString()" prop="day_predict">
                            <el-input-number v-model="form.day_predict" :min="0" :precision="2" clearable />
                        </el-form-item>
                    </el-col>
                </el-row>

                <!-- 项目描述 -->
                <el-form-item label="项目描述" >
                    <froala-editor v-model="form.describe" />
                </el-form-item>

                <el-divider content-position="left">更多信息</el-divider>
                <wo-template-form ref="woTemplateForm" v-model="form.template_value" :id="form.template_id" />

                <!-- 附件 -->
                <el-divider content-position="left">附件</el-divider>
                <el-form-item label="附件">
                    <files-upload v-model="form.files_id" model="contract" stage="创建工单" />
                </el-form-item>

            </el-form>
        </div>

        <!-- footer -->
        <div class="w-el-drawer-footer">
            <el-button size="medium" @click="Close()">取消 / 返回</el-button>
            <el-button size="medium" type="primary" @click="FormSubmit()" :loading="loading">提 交</el-button>
        </div>

    </el-drawer>
</template>
    
<script>
export default {
    data() {
        return {
            visible: false,
            loading: false,

            formError: {},
            form: {
                name: null,
                number: null,
                template_id: null,
                projects_id: null,
                projects_task_id: null,
                customer_id: null,
                p: 1,
                user_receive_id: null,
                user_assist_id: null,
                user_sending_id: null,
                user_admin_id: this.$store.state.user.id,
                range_at: null,
                day_predict: null,
                describe: null,
                files_id: [],
                template_value: null,
            }
        }
    },
    
    methods: {
        /**
        |--------------------------------------------------
        | 窗口 - 打开
        |--------------------------------------------------
        |
        */

        Open(config) {
            // 加载默认配置
            if (config) {

                // 项目信息加载
                if (config.projects_id) {
                    this.form.projects_id = config.projects_id
                    this.$http.post('/9api/crm/contract/search-first', { project_id: config.projects_id }).then(rps => {
                        this.form.customer_id = rps.data.customer_id
                        this.form.user_sending_id = rps.data.user_sale_id
                    })
                }

                // 任务信息加载
                if (config.projects_task_id) {
                    this.form.projects_task_id = config.projects_task_id
                    this.$http.post('/9api/projects-task/search-first', { id: config.projects_task_id }).then(rps => {
                        this.form.name = rps.data.name
                        this.form.p = rps.data.p
                        this.form.range_at = rps.data.start_date
                        this.form.day_predict = rps.data.working_days
                        this.form.user_receive_id = rps.data.now_assign
                        this.form.user_assist_id = rps.data.assign
                        this.form.describe = rps.data.describe
                        this.form.files_id = rps.data.files_id
                    })
                }
            }

            // 窗口配置
            this.visible = true
            this.loading = false
        },

        
        /**
        |--------------------------------------------------
        | 窗口 - 关闭
        |--------------------------------------------------
        |
        */

        Close() {
            this.formError = {}
            this.form.name = null
            this.form.number = null
            this.form.template_id = null
            this.form.projects_id = null
            this.form.projects_task_id = null
            this.form.customer_id = null
            this.form.p = null
            this.form.user_receive_id = null
            this.form.user_assist_id = null
            this.form.user_sending_id = null
            this.form.user_admin_id = this.$store.state.user.id
            this.form.range_at = null
            this.form.day_predict = null
            this.form.describe = null
            this.form.files_id = null
            this.form.template_value = null
            this.visible = false
            this.loading = false
        },

        /**
        |--------------------------------------------------
        | 项目信息 - 映射表单
        |--------------------------------------------------
        |
        */

        ProjectsOnChange(data) {
            if (data) {
                this.$http.post('/9api/crm/contract/search-first', { id: data.contract_id }).then(rps => {
                    this.form.customer_id = rps.data.customer_id
                    this.form.user_sending_id = rps.data.user_sale_id
                    this.form.projects_task_id = null
                })
            }
        },

        /**
        |--------------------------------------------------
        | 项目信息(任务) - 映射表单
        |--------------------------------------------------
        |
        */

        ProjectsTaskOnChange(data) {
            this.form.name = data.name
            this.form.p = data.p
            this.form.range_at = data.start_date
            this.form.day_predict = data.working_days
            this.form.user_receive_id = data.now_assign
            this.form.user_assist_id = data.assign
            this.form.describe = data.describe
            this.form.files_id = data.files_id
        },
        
        /**
        |--------------------------------------------------
        | 表单提交
        |--------------------------------------------------
        |
        */

        FormSubmit() {
            
            if (this.$refs.woTemplateForm.Validate()) {

                // 清空错误提示
                this.loading = true
                this.formError = {}

                // 提交表单
                this.$http.post('/9api/wo/create', this.form).then(rps => {
                    
                    this.loading = false

                    if (rps.data.state == 'error') {
                        this.formError = rps.data.body
                    }

                    if (rps.data.state == 'success') {
                        this.$notify.success({title: '提示', message: '添加成功', duration: 2000})
                        this.$emit('onChange', rps.data.body)
                        this.Close()
                    }
                })

            }
        }
    }
}
</script>
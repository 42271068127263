<!---------------------------------------------------------------------------- 
 | 项目任务
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | *v-model     默认值------默认为 null
 | *projects_id 默认值------默认为 null
 | width        表单宽度----默认为 ‘100%’
 | margin       表单外边距--默认为 ‘0px’
 |----------------------------------------------------------------------------
 | 回调
 | @onChange = fn () => {} 返回表单内容
 |
 ---------------------------------------------------------------------------->

 <template>
    <div>
        <el-input v-model="selectName" @focus="DialogOpend" @clear="SelectClear" placeholder="请选择任务" clearable :style="{ width: width, margin: margin }"/>

        <el-dialog :visible.sync="dialogVisible" width="900px" append-to-body title="选择任务（双击选择）" :close-on-click-modal="false" :close-on-press-escape="false">
            
            <!-- Search -->
            <uw-grid-search gutter="0 10px" :column="3" :style="{ marginBottom: '20px' }">

                <!-- full -->
                <template slot="full">
                    <el-input v-model="post.search.name" @input="$lodash.debounce(TableSearch, 500)" clearable placeholder="任务名称" />
                </template>

                <!-- 高级检索 -->
                <el-form-item label="关联项目">
                    <pm-projects-select v-model="post.search.project_id" @onChange="TableSearch" />
                </el-form-item>
                <el-form-item label="里程碑 / 项目阶段">
                    <pm-milestone-select @onChange="TableSearch()" v-model="post.search.milestone_id" :projects_id="post.search.project_id" />
                </el-form-item>
                <el-form-item label="指派给">
                    <users-select v-model="post.search.assign_now_users_ids" multiple @onChange="TableSearch" />
                </el-form-item>
                <el-form-item label="参与者">
                    <users-select v-model="post.search.assign" multiple @onChange="TableSearch" />
                </el-form-item>
                <el-form-item label="任务状态">
                    <el-select v-model="post.search.status" @change="TableSearch()" clearable filterable :style="{ width: '100%' }">
                        <el-option :value="0" label="未开始" />
                        <el-option :value="1" label="进行中" />
                        <el-option :value="2" label="已暂停" />
                        <el-option :value="4" label="已关闭" />
                        <el-option :value="5" label="已完成" />
                        <el-option :value="6" label="已取消" />
                    </el-select>
                </el-form-item>
                <el-form-item label="是否延期">
                    <el-select v-model="post.search.is_overdue" @change="TableSearch()" clearable filterable :style="{ width: '100%' }">
                        <el-option :value="0" label="未延期" />
                        <el-option :value="1" label="已延期" />
                    </el-select>
                </el-form-item>
                <el-form-item label="计划日期">
                    <el-date-picker
                        :style="{ width: '100%' }"
                        v-model="post.search.date"
                        type="daterange"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        @change="TableSearch" />
                </el-form-item>

            </uw-grid-search>

            <vxe-table ref="xTable" height="600px" :data="table" :loading="dialogLoading" @cell-dblclick="TableCellDblclick">
        
                <!-- 管理项目 -->
                <vxe-column min-width="240" field="project" title="项目名称">
                    <template #default="{ row }">
                        <el-span type="primary" @onClick="$refs.projectsDrawer.Open(row.projects.id)" :title="row.projects.number + ' - ' + row.projects.name" />
                    </template>
                </vxe-column>

                <!-- 任务名称 -->
                <vxe-column min-width="300" title="任务名称">
                    <template #default="{ row }">
                        <!-- 重要程度 -->
                        <el-tag :style="{ marginRight: '5px' }" v-if="row.p == 1" effect="plain" type="danger">P1</el-tag>
                        <el-tag :style="{ marginRight: '5px' }" v-if="row.p == 2" effect="plain" type="warning">P2</el-tag>
                        <el-tag :style="{ marginRight: '5px' }" v-if="row.p == 3" effect="plain" type="primary">P3</el-tag>
                        <el-tag :style="{ marginRight: '5px' }" v-if="row.p == 4" effect="plain" type="success">P4</el-tag>
                        <!-- 状态判断 -->
                        <el-tag :style="{ marginRight: '5px' }" v-if="row.status == 0" effect="dark" type="primary">未开始</el-tag>
                        <el-tag :style="{ marginRight: '5px' }" v-if="row.status == 1" effect="dark" type="primary">进行中</el-tag>
                        <el-tag :style="{ marginRight: '5px' }" v-if="row.status == 2" effect="dark" type="warning">已暂停</el-tag>
                        <el-tag :style="{ marginRight: '5px' }" v-if="row.status == 4" effect="dark" type="info">已关闭</el-tag>
                        <el-tag :style="{ marginRight: '5px' }" v-if="row.status == 5" effect="dark" type="success">已完成</el-tag>
                        <el-tag :style="{ marginRight: '5px' }" v-if="row.status == 6" effect="dark" type="info">已取消</el-tag>
                        <!-- 延期判断 -->
                        <el-tag v-if="row.is_overdue == 1" effect="dark" type="danger">已延期</el-tag>
                        <!-- 项目名称 -->
                        <el-span type="primary" :style="{ color: row.name_color }" @click.native="$refs.taskIndex.Open(row.id)" :title="row.name" />
                    </template>
                </vxe-column>

                <!-- 里程碑/项目阶段 -->
                <vxe-column min-width="150" field="name" title="里程碑/项目阶段">
                    <template #default="{ row }">
                        {{ row.milestone?.name ?? '-' }}
                    </template>
                </vxe-column>

                <!-- 任务类型 -->
                <vxe-column width="120" field="type" title="任务类型" />

                <!-- 指派给 -->
                <vxe-column width="110" title="指派给">
                    <template #default="{ row }">
                        <div style="display: flex; align-items: center; justify-content: flex-start;">
                            <el-avatar style="margin-right: 5px;" :size="26" :src="row.now_assign_users?.avatar"> {{ row.now_assign_users?.name[0] }} </el-avatar>
                            {{  row.now_assign_users?.name }}
                        </div>
                    </template>
                </vxe-column>

                <!-- 参与人员 -->
                <vxe-column width="80" field="assign" title="参与人员" :visible="true">
                    <template #default="{ row }">
                        <el-tooltip placement="top" :content="row.assign_user_name.toString()">
                            <el-link icon="el-icon-user">{{ row.assign.length }}</el-link>
                        </el-tooltip>
                    </template>
                </vxe-column>

                <!-- 概要 -->
                <vxe-column min-width="90" field="consume_days" title="任务消耗/P"></vxe-column>
                <vxe-column min-width="90" field="wo_sum_day_consume" title="工单消耗/P"></vxe-column>
                <vxe-column min-width="70" field="working_days" title="预期/P"></vxe-column>
                <vxe-column min-width="70" title="小计/P">
                    <template #default="{ row }">
                        {{ Number(row.consume_days) + Number(row.wo_sum_day_consume) }}
                    </template>
                </vxe-column>

                <!-- 时间 -->
                <vxe-column width="90" field="start_date[0]" title="开始时间"></vxe-column>
                <vxe-column width="90" field="start_date[1]" title="截止时间"></vxe-column>

            </vxe-table>

            <vxe-pager :total="post.page.total" :page-size.sync="post.page.size" :current-page.sync="post.page.current" @page-change="TableSearch()"></vxe-pager>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: [String, Array, Number],
            default: null
        },

        projectsId: {
            type: Number,
            default: null
        },

        width: {
            type: String,
            default: '100%'
        },

        margin: {
            type: String,
            default: '0px'
        },
    },

    data () {
        return {
            dialogVisible: false,
            dialogLoading: false,

            table: [],

            post: {
                page: {
                    total: 0,
                    size: 50,
                    current: 1,
                },
                search: {
                    number: null,
                    milestone_id: null,
                    project_id: null,
                    now_assign: null,
                    status: null,
                    is_overdue: null,
                    name: null
                },
                order: {
                    method: 'desc',
                    column: 'id'
                },
                append: [ 'now_assign_users', 'milestone', 'wo_sum_day_consume', 'projects', 'type', 'assign_user_name', 'now_assign_user_name' ],
            },

            selectObject: {},
            selectName: null
        }
    },

    watch: {
        value: {
            handler(val) {
                if (val) {
                    this.$http.post(
                        '/9api/projects-task/search-first', { id: val }
                    ).then(rps => {
                        this.selectName =  rps.data.name
                    })
                } else {
                    this.selectObject = {}
                    this.selectName = null
                }
            },
            immediate: true
        },

        projectsId: {
            handler() {
                this.post.search.project_id = this.projectsId
            },
            immediate: true
        }
    },

    methods: {

        /**
        |--------------------------------------------------
        | 窗口 - 打开动画结束时
        |--------------------------------------------------
        |
        */

        DialogOpend() {
            this.dialogVisible = true
            this.TableSearch()
        },

        /**
        |--------------------------------------------------
        | 表格数据 - 查询
        |--------------------------------------------------
        |
        */

        TableSearch() {
            this.dialogLoading = true
            this.$http.post('/9api/pm/task/search', this.post).then((rps) => {
                // 更新数据
                this.post.page.total = rps.data.total
                this.table = rps.data.body

                // 页码溢出
                if (this.table.length == 0 && this.post.page.current != 1) {
                    this.post.page.current = 1
                    this.TableSearch()
                }

                // 切换加载状态
                this.dialogLoading = false
            })
        },

        /**
        |--------------------------------------------------
        | 表格数据 - 行双击事件
        |--------------------------------------------------
        |
        */

        TableCellDblclick({ row }) {
            this.SelectChange(row)
        },

        /**
        |--------------------------------------------------
        | 清空选项
        |--------------------------------------------------
        |
        */

        SelectClear() {
            this.selectName = null
            this.selectObject = {}
            this.$emit('input', null)
            this.$emit('onChange', null)
        },

        /**
        |--------------------------------------------------
        | 选择事件
        |--------------------------------------------------
        |
        */

        SelectChange(item) {
            this.selectName =  item.name
            this.selectObject = item
            this.$emit('input', item.id)
            this.$emit('onChange', item)
            this.dialogVisible = false
        },
    }
}
</script>
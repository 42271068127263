<template>
    <el-dropdown>

        <!-- 当前选项 -->
        <el-button type="primary" size="mini" :loading="loading">
            {{ name }}
            <i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>

        <!-- 日期范围选项 -->
        <el-dropdown-menu slot="dropdown">
            <div :style="{ padding: '0 10px' }">

                <!-- 天 -->
                <div :style="{ display: 'flex' }">
                    <div :style="{ width: '60px', fontSize: '12px', color: '#9e9e9e', display: 'flex', alignItems: 'center', justifyContent: 'center' }">天</div>
                    <div :style="{ width: '240px', display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '2px'  }">
                        <el-button size="mini" :style="{ border: '0px' }" @click="OnSelect('day', 'current')">今天</el-button>
                        <el-button size="mini" :style="{ border: '0px' }" @click="OnSelect('day', 'before')">昨天</el-button>
                        <el-button size="mini" :style="{ border: '0px' }" @click="OnSelect('day', 'future')">明天</el-button>
                    </div>
                </div>

                <!-- 周 -->
                <div :style="{ display: 'flex' }">
                    <div :style="{ width: '60px', fontSize: '12px', color: '#9e9e9e', display: 'flex', alignItems: 'center', justifyContent: 'center' }">周</div>
                    <div :style="{ width: '240px', display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '2px'  }">
                        <el-button size="mini" :style="{ border: '0px' }" @click="OnSelect('week', 'current')">本周</el-button>
                        <el-button size="mini" :style="{ border: '0px' }" @click="OnSelect('week', 'before')">上周</el-button>
                        <el-button size="mini" :style="{ border: '0px' }" @click="OnSelect('week', 'future')">下周</el-button>
                    </div>
                </div>

                <!-- 月 -->
                <div :style="{ display: 'flex' }">
                    <div :style="{ width: '60px', fontSize: '12px', color: '#9e9e9e', display: 'flex', alignItems: 'center', justifyContent: 'center' }">月</div>
                    <div :style="{ width: '240px', display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '2px'  }">
                        <el-button size="mini" :style="{ border: '0px' }" @click="OnSelect('month', 'current')">本月</el-button>
                        <el-button size="mini" :style="{ border: '0px' }" @click="OnSelect('month', 'before')">上月</el-button>
                        <el-button size="mini" :style="{ border: '0px' }" @click="OnSelect('month', 'future')">下月</el-button>
                    </div>
                </div>

                <!-- 季度 -->
                <div :style="{ display: 'flex' }">
                    <div :style="{ width: '60px', fontSize: '12px', color: '#9e9e9e', display: 'flex', alignItems: 'center', justifyContent: 'center' }">季度</div>
                    <div :style="{ width: '240px', display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '2px'  }">
                        <el-button size="mini" :style="{ border: '0px' }" @click="OnSelect('quarter', 'current')">本季度</el-button>
                        <el-button size="mini" :style="{ border: '0px' }" @click="OnSelect('quarter', 'before')">上季度</el-button>
                        <el-button size="mini" :style="{ border: '0px' }" @click="OnSelect('quarter', 'future')">下季度</el-button>
                    </div>
                </div>

                <!-- 年度 -->
                <div :style="{ display: 'flex' }">
                    <div :style="{ width: '60px', fontSize: '12px', color: '#9e9e9e', display: 'flex', alignItems: 'center', justifyContent: 'center' }">年度</div>
                    <div :style="{ width: '240px', display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '2px'  }">
                        <el-button size="mini" :style="{ border: '0px' }" @click="OnSelect('year', 'current')">本年度</el-button>
                        <el-button size="mini" :style="{ border: '0px' }" @click="OnSelect('year', 'before')">上年度</el-button>
                        <el-button size="mini" :style="{ border: '0px' }" @click="OnSelect('year', 'future')">下年度</el-button>
                    </div>
                </div>

            </div>

            <!-- 自定义 -->
            <div v-if="showCustom" :style="{ borderWidth: '1px 0px 1px 0px', borderStyle: 'solid', borderColor: '#eeeeee', margin: '10px 0 10px 0', padding: '10px'  }">
                <div :style="{ width: '60px', fontSize: '12px', color: '#9e9e9e', display: 'flex', alignItems: 'center', justifyContent: 'center' }">自定义</div>
                <el-date-picker
                    :style="{ width: '300px', marginTop: '10px' }"
                    :append-to-body="false"
                    @change="OnSelect('custom')"
                    v-model="datePicker"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    size="mini"
                    value-format="yyyy-MM-dd">
                </el-date-picker>
            </div>

        </el-dropdown-menu>

    </el-dropdown>
</template>

<script>
export default {
    props: {
        showCustom: {
            type: Boolean,
            default: true
        }
    },

    data () {
        return {
            name: '本月',
            dateType: null,
            datePicker: this.$lodash.date_range('month')
        }
    },

    computed: {
        loading () {
            return !!this.$store.state.httpRequestCount
        }
    },

    methods: {
        OnSelect(dateType, dateOn) {
            if (dateType == 'day' && dateOn == 'current')
            {
                this.name = '今天'
                this.dateType = 'day'
                this.datePicker = this.$lodash.date_range('day')
            }
            if (dateType == 'day' && dateOn == 'before')
            {
                this.name = '昨天'
                this.dateType = 'day'
                this.datePicker = this.$lodash.date_range('day', -1)
            }
            if (dateType == 'day' && dateOn == 'future')
            {
                this.name = '明天'
                this.dateType = 'day'
                this.datePicker = this.$lodash.date_range('day', 1)
            }
            if (dateType == 'week' && dateOn == 'current')
            {
                this.name = '本周'
                this.dateType = 'week'
                this.datePicker = this.$lodash.date_range('week')
            }
            if (dateType == 'week' && dateOn == 'before')
            {
                this.name = '上周'
                this.dateType = 'week'
                this.datePicker = this.$lodash.date_range('week', -1)
            }
            if (dateType == 'week' && dateOn == 'future')
            {
                this.name = '下周'
                this.dateType = 'week'
                this.datePicker = this.$lodash.date_range('week', 1)
            }
            if (dateType == 'month' && dateOn == 'current')
            {
                this.name = '本月'
                this.dateType = 'month'
                this.datePicker = this.$lodash.date_range('month')
            }
            if (dateType == 'month' && dateOn == 'before')
            {
                this.name = '上月'
                this.dateType = 'month'
                this.datePicker = this.$lodash.date_range('month', -1)
            }
            if (dateType == 'month' && dateOn == 'future')
            {
                this.name = '下月'
                this.dateType = 'month'
                this.datePicker = this.$lodash.date_range('month', 1)
            }
            if (dateType == 'quarter' && dateOn == 'current')
            {
                this.name = '本季度'
                this.dateType = 'quarter'
                this.datePicker = this.$lodash.date_range('quarter')
            }
            if (dateType == 'quarter' && dateOn == 'before')
            {
                this.name = '上季度'
                this.dateType = 'quarter'
                this.datePicker = this.$lodash.date_range('quarter', -1)
            }
            if (dateType == 'quarter' && dateOn == 'future')
            {
                this.name = '下季度'
                this.dateType = 'quarter'
                this.datePicker = this.$lodash.date_range('quarter', 1)
            }
            if (dateType == 'year' && dateOn == 'current')
            {
                this.name = '本年度'
                this.dateType = 'year'
                this.datePicker = this.$lodash.date_range('year')
            }
            if (dateType == 'year' && dateOn == 'before')
            {
                this.name = '上年度'
                this.dateType = 'year'
                this.datePicker = this.$lodash.date_range('year', -1)
            }
            if (dateType == 'year' && dateOn == 'future')
            {
                this.name = '下年度'
                this.dateType = 'year'
                this.datePicker = this.$lodash.date_range('year', 1)
            }
            if (dateType == 'custom')
            {
                this.name = '自定义'
                this.dateType = 'custom'
            }
            if (this.datePicker)
            {
                this.$emit('onChange', { date_type: this.dateType, date_start: this.datePicker[0], date_end: this.datePicker[1] })
            }
        },
    }
}
</script>
<template>
    <uw-page title="项目概况">
        
        <!-- 基本信息 -->
        <el-descriptions size="small" border :column="1" class="w-margin-t-10">
            <el-descriptions-item label="项目信息">{{ projects.number }} - {{ projects.name }}</el-descriptions-item>

            <!-- 关联合同 -->
            <el-descriptions-item label="关联合同">
                <el-link v-if="projects.contract" type="primary" @click="$refs.contractDetails.Open(projects.contract.id)">
                    {{ projects.contract.number }} - {{ projects.contract.name }}
                </el-link>
                <el-link v-else type="info" :underline="false">
                    未关联
                </el-link>
            </el-descriptions-item>

            <!-- 项目进度 -->
            <el-descriptions-item label="项目进度">
                <el-progress :percentage="Math.floor(projects.statistics_task.ygb/projects.statistics_task.count*100) || 0" :stroke-width="4"></el-progress>
            </el-descriptions-item>

            <!-- 项目状态 -->
            <el-descriptions-item label="项目状态">
                <el-tag v-if="projects.status == 0" size="mini" effect="plain" type="danger">未开始</el-tag>
                <el-tag v-if="projects.status == 1" size="mini" effect="plain">进行中</el-tag>
                <el-tag v-if="projects.status == 2" size="mini" effect="plain" type="warning">已暂停</el-tag>
                <el-tag v-if="projects.status == 4" size="mini" effect="plain" type="info">已关闭</el-tag>
                <el-tag v-if="projects.status == 5" size="mini" effect="plain" type="success">待验收</el-tag>
                <!-- 延期判断 -->
                <el-tag style="margin-left: 5px;" v-if="projects.is_overdue" size="mini" effect="plain" type="danger">已延期</el-tag>
            </el-descriptions-item>

            <!-- 项目类型 -->
            <el-descriptions-item label="项目类型">{{ projects.type_name.toString() }}</el-descriptions-item>

            <!-- 预计周期 -->
            <el-descriptions-item label="项目类型">
                {{ projects.start_date[0] }} - {{ projects.start_date[1] }} ( {{ projects.working_days }} P )
            </el-descriptions-item>

            <!-- 预估工时 -->
            
            <el-descriptions-item label="任务消耗/P">{{ projects.consume_days }}</el-descriptions-item>
            <el-descriptions-item label="工单消耗/P">{{ projects.wo_sum_day_consume }}</el-descriptions-item>
            <el-descriptions-item label="预期/P">{{ projects.working_days }}</el-descriptions-item>
            <el-descriptions-item label="小计/P">{{ Number(projects.consume_days) + Number(projects.wo_sum_day_consume) }}</el-descriptions-item>


            <el-descriptions-item label="访问权限">
                <el-tooltip effect="dark" content="有代视图权限，即可访问" placement="bottom-start">
                    <div slot="content">
                        默认设置: 有代视图权限，即可访问<p></p>
                        私有项目: 只有项目团队成员才能访问<p></p>
                        自定义白名单: 团队成员和白名单的成员可以访问
                    </div>
                    <el-link type="primary">
                        <el-link v-if="projects.jurisdiction == 1"><i class="el-icon-warning"></i> 默认设置</el-link>
                        <el-link v-if="projects.jurisdiction == 2"><i class="el-icon-warning"></i> 私有项目</el-link>
                        <el-link v-if="projects.jurisdiction == 3"><i class="el-icon-warning"></i> 自定义白名单</el-link>
                    </el-link>
                </el-tooltip>
            </el-descriptions-item>
        </el-descriptions>
                
        <!-- 统计 -->
        <el-card shadow="hover" style="margin-bottom: 20px;" class="w-margin-t-20">

            <!-- 标题 -->
            <div slot="header">
                任务统计
                <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>
            </div>
            <div ref="echartsStatistics" style="width: 100%; height: 300px;"></div>
            
        </el-card>
        
        <!-- 组件 -->
        <crm-contract-drawer ref="contractDetails" />

        <!-- 图表 -->
        <el-tabs type="border-card" class="el-tabs-theme" style="margin-bottom: 20px;">
            <el-tab-pane label="成员负荷图">
                <div ref="echartsLoadTable" style="width: 100%; height: 300px;"></div>
            </el-tab-pane>
            <el-tab-pane label="成员速度报表"><el-empty description="暂不开放"></el-empty></el-tab-pane>
            <el-tab-pane label="燃尽图报表"><el-empty description="暂不开放"></el-empty></el-tab-pane>
            <el-tab-pane label="每日新增问题趋势"><el-empty description="暂不开放"></el-empty></el-tab-pane>
        </el-tabs>

        <!-- 任务 -->
        <el-tabs type="border-card" class="el-tabs-theme" style="margin-bottom: 20px;">
            <el-tab-pane label="我的工作项"><el-empty description="暂不开放"></el-empty></el-tab-pane>
            <el-tab-pane label="未完成"><el-empty description="暂不开放"></el-empty></el-tab-pane>
            <el-tab-pane label="⭐星标任务"><el-empty description="暂不开放"></el-empty></el-tab-pane>
            <el-tab-pane label="今日"><el-empty description="暂不开放"></el-empty></el-tab-pane>
            <el-tab-pane label="本周"><el-empty description="暂不开放"></el-empty></el-tab-pane>
            <el-tab-pane label="已逾期"><el-empty description="暂不开放"></el-empty></el-tab-pane>
            <el-tab-pane label="所有"><el-empty description="暂不开放"></el-empty></el-tab-pane>
        </el-tabs>

        <!-- 文档 -->
        <el-tabs type="border-card" class="el-tabs-theme" style="margin-bottom: 20px;">
            <el-tab-pane label="最近更新文档"><el-empty description="暂不开放"></el-empty></el-tab-pane>
            <el-tab-pane label="⭐星标文档"><el-empty description="暂不开放"></el-empty></el-tab-pane>
        </el-tabs>
        
        <!-- 最新动态 -->
        <projectsLog ref='projectsLog' :projects_id="projects.id"></projectsLog>
        
    </uw-page>
</template>

<script>
import * as echarts from 'echarts';

import projectsLog from "@/views/projects/projectsLog"

export default {

    props: ['projects_id'],

    components: {
        projectsLog,
    },

    data() {
        return {
            loading: false,

            tabs: 'base',

            projects: {
                contract: {},
                type_name: [],
                statistics_task: {},
                start_date: []
            },

            echarts: {
                statistics: null,
                loadTable: null
            },
        }
    },

    watch: {
        projects_id: {
            handler (val) {
                if (val) {
                    this.ProjectsGet()
                }
            },
            immediate: true
        }
    },

    mounted() {
        window.addEventListener('resize', () => {
            this.echarts.statistics.resize()
            this.echarts.loadTable.resize()
        })
    },

    methods: {

        /**
         * 项目 - 详情
         */
        ProjectsGet() {
            this.loading = true

            this.$http.post('/9api/projects/search-first', { id: this.projects_id, append: ['wo_sum_day_consume', 'contract', 'type_name','statistics_task', 'statistics_problem', 'statistics_wo', 'echarts', 'working_days', 'consume_days'], hidden: ['describe'] }).then(rps => {
                this.projects = rps.data

                this.EchartsStatistics()
                this.EchartsLoadTable()

                this.loading = false
            })
        },

        

        /**
         * Echarts图表 - 项目统计
         */
        EchartsStatistics() {
            this.echarts.statistics = echarts.init(this.$refs.echartsStatistics)
            this.echarts.statistics.setOption({
                title: [
                    {
                        text: '任务',
                        subtext: String(this.projects.statistics_task.count),
                        textVerticalAlign: 'middle', textAlign: 'center',
                        left: '12%', top: '45%',
                        subtextStyle: { color: '#000', fontSize: 14 }
                    },
                    {
                        text: '工单',
                        subtext: String(this.projects.statistics_wo.count),
                        textVerticalAlign: 'middle', textAlign: 'center',
                        left: '49.5%', top: '45%',
                        subtextStyle: { color: '#000', fontSize: 14 }
                    },
                    {
                        text: '问题',
                        subtext: String(this.projects.statistics_problem.count),
                        textVerticalAlign: 'middle', textAlign: 'center',
                        left: '87%', top: '45%',
                        subtextStyle: { color: '#000', fontSize: 14 }
                    },
                ],
                // color: ['#e0e0e0', '#e0e0e0', '#409EFF', '#ff9800', '#F56C6C', '#67C23A', '#000000', '#e0e0e0', '#e0e0e0', '#e0e0e0'],
                tooltip: {},
                series: [
                    {
                        name: '任务',
                        type: 'pie',
                        center: ['12.5%', '50%'], radius: ['50%', '62.5%'],
                        labelLine: { show: false }, label: { show: false, },
                        data: [
                            { name: '未开始', value: this.projects.statistics_task.wks, itemStyle: { color: this.projects.statistics_task.wks ? '#9e9e9e' : '#e0e0e0' } },
                            { name: '进行中', value: this.projects.statistics_task.jxz, itemStyle: { color: this.projects.statistics_task.jxz ? '#409EFF' : '#e0e0e0' } },
                            { name: '已暂停', value: this.projects.statistics_task.yzt, itemStyle: { color: this.projects.statistics_task.yzt ? '#ff9800' : '#e0e0e0' } },
                            { name: '已延期', value: this.projects.statistics_task.yyq, itemStyle: { color: this.projects.statistics_task.yyq ? '#F56C6C' : '#e0e0e0' } },
                            { name: '已完成', value: this.projects.statistics_task.ywc, itemStyle: { color: this.projects.statistics_task.ywc ? '#67C23A' : '#e0e0e0' } },
                            { name: '已关闭', value: this.projects.statistics_task.ygb, itemStyle: { color: '#e0e0e0' } },
                        ]
                    },
                    {
                        name: '工单',
                        type: 'pie',
                        center: ['50%', '50%'], radius: ['50%', '62.5%'],
                        labelLine: { show: false }, label: { show: false, },
                        data: [
                            { name: '未开始', value: this.projects.statistics_wo.wks, itemStyle: { color: this.projects.statistics_wo.wks ? '#9e9e9e' : '#e0e0e0' } },
                            { name: '进行中', value: this.projects.statistics_wo.jxz, itemStyle: { color: this.projects.statistics_wo.jxz ? '#409EFF' : '#e0e0e0' } },
                            { name: '已暂停', value: this.projects.statistics_wo.yzt, itemStyle: { color: this.projects.statistics_wo.yzt ? '#ff9800' : '#e0e0e0' } },
                            { name: '已延期', value: this.projects.statistics_wo.yyq, itemStyle: { color: this.projects.statistics_wo.yyq ? '#F56C6C' : '#e0e0e0' } },
                            { name: '已完成', value: this.projects.statistics_wo.ywc, itemStyle: { color: this.projects.statistics_wo.ywc ? '#67C23A' : '#e0e0e0' } },
                            { name: '已关闭', value: this.projects.statistics_wo.ygb, itemStyle: { color: '#e0e0e0' } },
                            { name: '已取消', value: this.projects.statistics_wo.yqx, itemStyle: { color: '#e0e0e0' } }
                        ]
                    },
                    {
                        name: '问题',
                        type: 'pie',
                        center: ['87.5%', '50%'], radius: ['50%', '62.5%'],
                        labelLine: { show: false }, label: { show: false, },
                        data: [
                            { name: '待解决', value: this.projects.statistics_problem.djj, itemStyle: { color: this.projects.statistics_problem.djj ? '#9e9e9e' : '#e0e0e0' } },
                            { name: '解决中', value: this.projects.statistics_problem.jjz, itemStyle: { color: this.projects.statistics_problem.jjz ? '#409EFF' : '#e0e0e0' } },
                            { name: '已解决', value: this.projects.statistics_problem.yjj, itemStyle: { color: this.projects.statistics_problem.yjj ? '#67C23A' : '#e0e0e0' } },
                            { name: '已关闭', value: this.projects.statistics_problem.ygb, itemStyle: { color: '#e0e0e0' } }
                        ]
                    }
                ]
            })
        },

        /**
         * Echarts图表 - 成员负荷图
         */
        EchartsLoadTable() {
            this.echarts.loadTable = echarts.init(this.$refs.echartsLoadTable)
            this.echarts.loadTable.setOption({
                tooltip: {},
                xAxis: {
                    data: this.projects.echarts.loadTable.team
                },
                yAxis: {},
                grid: {
                    left: '40',
                    right: '40',
                    top: '40',
                    bottom:'40'
                },
                series: [
                    {
                        name: '负责任务数量',
                        type: 'bar',
                        barWidth: '10',
                        showBackground: true,
                        itemStyle: {normal: {barBorderRadius: [5, 5, 0, 0]}},
                        label: { show: true, position: 'top' },
                        data: this.projects.echarts.loadTable.data
                    }
                ]
            })
        },
        
        /**
         * 其它 - 刷新页面
         */
        RePage() {
            this.ProjectsGet()
            this.$refs.projectsLog.Load()
            this.$emit('reset')
        }
    }
}

</script>


<!---------------------------------------------------------------------------- 
 | 通知模块|日志
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | $refs[ref_name].Load() 手动重新加载数据,
 |----------------------------------------------------------------------------
 | 参数说明：此组件参数有较多的配置项，具体参数值可询问后台开发人员
 | model - 模块
 | model_id - 模块ID
 | model_sub_event - 事件类型
 |----------------------------------------------------------------------------
 | 如果需要完善组件，请仔深入了解前端及后端代码，非全栈工程师不建议修改。
 |
 ---------------------------------------------------------------------------->

<template>
    <uw-page title="操作记录">
        
        <el-timeline class="w-margin-t-20">
            <el-timeline-item v-for="(item, idx) in notification" :timestamp="item.user.name + ' ' + item.updated_at" :key="idx" placement="top">
                <div v-html="item.event_describe"></div>
                <div v-if="item.event_explain" v-html="item.event_explain" style="background-color: #F5F5F5; padding: 5px; margin-top: 5px; border-radius: 3px;"></div>
            </el-timeline-item>
        </el-timeline>

    </uw-page>
</template>

<script>
export default {
    props: {
        model: {
            type: String,
            default: null
        },
        model_id: {
            type: Number,
            default: null
        },
        model_sub_event: {
            type: Array,
            default: null
        },
    },

    data() {
        return {
            notification: [],

            // 窗口
            loading: false,

            // 分页
            page: {
                total: 0,
                size: 10,
                current: 1,
            },

            // 排序
            order: {
                method: 'desc',
                column: 'updated_at'
            },
        }
    },

    mounted () {
        this.Load()
    },

    methods: {

        /**
         * 日志 - 查询
         */
        Load() {
            this.$http.post('/9api/notification/search', {
                page: this.page,
                order: this.order,
                search: {
                    model: this.model,
                    model_id: this.model_id,
                    model_sub_event: this.model_sub_event
                }
            }).then(rps => {
                if (rps.data.state == 'success') {

                    // 更新数据
                    this.page.total = rps.data.total
                    this.notification = rps.data.body

                    // 页码溢出
                    if (this.notification.length == 0 && this.page.current != 1) {
                        this.page.current = 1
                        this.Load()
                    }

                    // 切换加载状态
                    this.loading = false
                }
            })
        },
    }
}
</script>
<template>
    <el-dialog-menu :visible.sync="visible" :loading="$store.state.loading" :title="contract.number" :tip="contract.name" @close="Close" fullscreen>

        <template slot="menu">
            
            <!-- 合同状态 -->
            <div :style="{ marginBottom: '10px' }">
                <el-alert center :closable="false" v-if="contract.draft" title="此合同未发布（草稿）" type="info" />
                <el-alert center :closable="false" v-if="!contract.draft && contract.workflow_state == 0" title="无需审核" type="info" />
                <el-alert center :closable="false" v-if="!contract.draft && contract.workflow_state == 1" title="等待审核中" type="warning" />
                <el-alert center :closable="false" v-if="!contract.draft && contract.workflow_state == 2" title="正在审核中" type="warning" />
                <el-alert center :closable="false" v-if="!contract.draft && contract.workflow_state == 3" title="审核结果：拒绝" type="error" />
                <el-alert center :closable="false" v-if="!contract.draft && contract.workflow_state == 4" title="审核通过" type="success" />
                <el-alert center :closable="false" v-if="!contract.draft && contract.workflow_state == 5" title="等待撤销中" type="warning" />
                <el-alert center :closable="false" v-if="!contract.draft && contract.workflow_state == 6" title="撤销审核中" type="warning" />
                <el-alert center :closable="false" v-if="!contract.draft && contract.workflow_state == 7" title="撤销结果：拒绝" type="error" />
                <el-alert center :closable="false" v-if="!contract.draft && contract.workflow_state == 8" title="撤销结果：同意" type="success" />
            </div>

            <!-- 功能按钮 -->
            <div :style="{ marginBottom: '10px', display: 'grid', gap: '5px', gridTemplateColumns: 'repeat(1, 1fr)' }">
                <el-button :style="{ margin: '0px' }" icon="el-icon-folder-checked" type="primary" @click="$refs.projectsCreate.open({ contract_id: contract.id })">创建关联项目</el-button>
            </div>

            <!-- 合同信息 -->
            <el-dialog-menu-group title="合同信息">

                <!-- 合同概况 -->
                <el-dialog-menu-item
                    title="合同概况"
                    icon="el-icon-data-analysis"
                    :is-active="currentComponent == 'crm-contract-drawer-base'"
                    @onClick="currentComponent = 'crm-contract-drawer-base'" />
                
                <!-- 合同描述 -->
                <el-dialog-menu-item
                    title="合同描述"
                    icon="el-icon-data-analysis"
                    :is-active="currentComponent == 'crm-contract-drawer-describe'"
                    @onClick="currentComponent = 'crm-contract-drawer-describe'" />
                
                <!-- 回访记录 -->
                <el-dialog-menu-item
                    title="回访记录"
                    icon="el-icon-data-analysis"
                    :is-active="currentComponent == 'crm-contract-drawer-visit'"
                    @onClick="currentComponent = 'crm-contract-drawer-visit'" />

                <!-- 附件 -->
                <el-dialog-menu-item
                    title="附件"
                    icon="el-icon-data-analysis"
                    :is-active="currentComponent == 'crm-contract-drawer-files'"
                    @onClick="currentComponent = 'crm-contract-drawer-files'" />

            </el-dialog-menu-group>

            <!-- 采购信息 -->
            <el-dialog-menu-group title="采购信息">

                <!-- 采购产品 -->
                <!-- <el-dialog-menu-item
                    title="采购产品"
                    icon="el-icon-data-analysis"
                    :is-active="currentComponent == 'crm-contract-drawer-product'"
                    @onClick="currentComponent = 'crm-contract-drawer-product'" /> -->
                
                <!-- 采购订单 -->
                <el-dialog-menu-item
                    title="采购单"
                    icon="el-icon-data-analysis"
                    :is-active="currentComponent == 'crm-contract-drawer-ims-order'"
                    @onClick="currentComponent = 'crm-contract-drawer-ims-order'" />
                
            </el-dialog-menu-group>

            <!-- 合同款项 -->
            <el-dialog-menu-group title="合同款项">

                <!-- 回款计划 -->
                <el-dialog-menu-item
                    title="回款计划"
                    icon="el-icon-data-analysis"
                    :is-active="currentComponent == 'crm-contract-drawer-pay-plan'"
                    @onClick="currentComponent = 'crm-contract-drawer-pay-plan'" />

                <!-- 回款记录 -->
                <el-dialog-menu-item
                    title="回款记录"
                    icon="el-icon-data-analysis"
                    :is-active="currentComponent == 'crm-contract-drawer-pay-record-enter'"
                    @onClick="currentComponent = 'crm-contract-drawer-pay-record-enter'" />
                
                <!-- 退款记录 -->
                <el-dialog-menu-item
                    title="退款记录"
                    icon="el-icon-data-analysis"
                    :is-active="currentComponent == 'crm-contract-drawer-pay-record-out'"
                    @onClick="currentComponent = 'crm-contract-drawer-pay-record-out'" />
                
                <!-- 开票信息 -->
                <el-dialog-menu-item
                    title="开票信息"
                    icon="el-icon-data-analysis"
                    :is-active="currentComponent == 'crm-contract-drawer-pay-invoice'"
                    @onClick="currentComponent = 'crm-contract-drawer-pay-invoice'" />

            </el-dialog-menu-group>

            <!-- 合同日志 -->
            <el-dialog-menu-group title="合同日志">

                <!-- 操作记录 -->
                <el-dialog-menu-item
                    title="操作记录"
                    icon="el-icon-data-analysis"
                    :is-active="currentComponent == 'crm-contract-drawer-log'"
                    @onClick="currentComponent = 'crm-contract-drawer-log'" />

            </el-dialog-menu-group>

        </template>

        <template slot="footer">
            <el-dialog-menu-item icon="el-icon-refresh" title=" 刷新" @onClick="RePage()" />
            <el-dialog-menu-item icon="el-icon-edit" title=" 编辑" @onClick="$refs.contractUpdate.open(contract.id)" />
            <el-dialog-menu-item icon="el-icon-delete" title=" 删除" @onClick="ContractDelete()" />
        </template>

        <!-- 动态组件 -->
        <component :is="currentComponent" :contract_id="contract.id" :customer_id="contract.customer_id" />

        <!-- 组件 -->
        <contract-update ref='contractUpdate' @onSave="RePage" />
        <pm-projects-create ref="projectsCreate" @success="RePage" />

    </el-dialog-menu>
</template>

<script>
export default {
    data() {
        return {
            visible: false,
            currentComponent: 'crm-contract-drawer-base',

            contractFile: [],
            contract: {
                id: null,
                source: [],
                customer: {},
                project: {},
                time_limit: [],
                user_admin: {},
                user_affair: {},
                user_presale: {},
                user_sale: {},
                files_id: []
            }
        }
    },

    methods: {

        /**
        |--------------------------------------------------
        | 打开窗口
        |--------------------------------------------------
        |
        */

        Open(id) {
            this.visible = true
            this.ContractGet(id)
        },

        /**
        |--------------------------------------------------
        | 关闭窗口
        |--------------------------------------------------
        |
        */

        Close () {
            this.$emit('onChange')
            this.visible = false
        },

        /**
        |--------------------------------------------------
        | 合同 - 详情
        |--------------------------------------------------
        |
        */

        ContractGet(id = this.contract.id) {
            this.$http.post(
                '/9api/crm/contract/search-first',
                {
                    id: id,
                    append: ['customer', 'user_sale', 'user_admin', 'user_affair', 'user_presale', 'source', 'team_read', 'project', 'sale_documentary'],
                    hidden: ['describe']
                }
            ).then(rps => {
                this.contract = rps.data
            })
        },

        /**
        |--------------------------------------------------
        | 其它 - 刷新页面
        |--------------------------------------------------
        |
        */

        RePage() {
            this.ContractGet()
            this.$refs.dialogApprove.Update()
            this.$refs.notification.Load()
        },

        /**
        |--------------------------------------------------
        | 合同 - 删除
        |--------------------------------------------------
        |
        */

        ContractDelete() {
            this.$http.post('/9api/contract/delete', {id: this.contract.id}).then(rps => {
                if (rps.data.state == 'success' ) {
                    this.visible = false
                }
            })
        },
    }
}

</script>6

<!---------------------------------------------------------------------------- 
 | 项目管理 - 批量创建任务
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | $refs[ref_name].Open(*project_id, parent) 手动打开窗口,
 | $refs[ref_name].Close() 手动关闭窗口
 |----------------------------------------------------------------------------
 | 回调
 | @onSave = fn (Object) => {} 成功后返任务信息
 |
 ---------------------------------------------------------------------------->

<template>
    <el-drawer class="w-el-drawer" :visible.sync="visible" size="100%" :with-header="false" direction="rtl" :before-close="Close" append-to-body>
        
        <!-- header -->
        <div class="w-el-drawer-header">
            <i class="el-icon-tickets"></i> 批量创建
        </div>

        <!-- body -->
        <div class="w-el-drawer-body" v-loading.lock="loading" :element-loading-text="loadingText">

            <vxe-table
                ref="xTable"
                :data="task"
                border
                row-key
                show-overflow
                :cell-style="CellFormValidator"
                :scroll-y="{enabled: false}">

                <vxe-column type="seq" width="60" fixed="left" title="序号"></vxe-column>

                <!-- 任务名称 -->
                <vxe-column min-width="200" title="里程碑 / 项目阶段"> 
                    <template #default="{ row }">
                        <el-input v-model="row.milestone" clearable></el-input>
                    </template>
                </vxe-column>

                <!-- 任务名称 -->
                <vxe-column min-width="200" field="name"> 
                    <template #header>
                        <span style="color: red;">* </span>任务名称
                    </template>
                    <template #default="{ row }">
                        <el-input v-model="row.name" clearable >
                            <el-color-picker slot="prepend" v-model="row.name_color" show-alpha :predefine="color"></el-color-picker>
                        </el-input>
                    </template>
                </vxe-column>

                <!-- 任务类w型 -->
                <vxe-column min-width="150">
                    <template #header>
                        <span style="color: red;">* </span>任务类型
                    </template>
                    <template #default="{ row }">
                        <projects-task-type-select v-model="row.type_id" />
                    </template>
                </vxe-column>sd

                <!-- 重要程度 / 优先级 -->
                <vxe-column min-width="165">
                    <template #header>
                        <span style="color: red;">* </span>重要程度 / 优先级
                    </template>
                    <template #default="{ row }">
                        <projects-task-select-p v-model="row.p" clearable filterable />
                    </template>
                </vxe-column>

                <!-- 指派给 -->
                <vxe-column min-width="185">
                    <template #header>
                        <span style="color: red;">* </span>指派给
                    </template>
                    <template #default="{ row }">
                        <el-select v-model="row.now_assign" placeholder="请选择任务类型" clearable filterable >
                            <el-option v-for="(item, idx) in team" :key="idx" :label="item.user.name" :value="item.user.id">
                                <span style="float: left;">{{ item.user.name }}</span>
                                <span style="float: right; color: #8492a6; font-size: 12px" v-if="item.role == 'admin'">管理员</span>
                                <span style="float: right; color: #8492a6; font-size: 12px" v-if="item.role == 'sale'">销售</span>
                                <span style="float: right; color: #8492a6; font-size: 12px" v-if="item.role == 'leader'">项目经理</span>
                                <span style="float: right; color: #8492a6; font-size: 12px" v-if="item.role == 'helper'">普通组员</span>
                            </el-option>
                        </el-select>
                    </template>
                </vxe-column>

                <!-- 参与成员 / 协助成员 -->
                <vxe-column min-width="185" title="参与成员 / 协助成员">
                    <template #default="{ row }">
                        <el-select v-model="row.assign" placeholder="请选择任务类型" clearable filterable multiple collapse-tags>
                            <el-option v-for="(item, idx) in team" :key="idx" :label="item.user.name" :value="item.user.id">
                                <span style="float: left;">{{ item.user.name }}</span>
                                <span style="float: right; color: #8492a6; font-size: 12px" v-if="item.role == 'admin'">管理员</span>
                                <span style="float: right; color: #8492a6; font-size: 12px" v-if="item.role == 'sale'">销售</span>
                                <span style="float: right; color: #8492a6; font-size: 12px" v-if="item.role == 'leader'">项目经理</span>
                                <span style="float: right; color: #8492a6; font-size: 12px" v-if="item.role == 'helper'">普通组员</span>
                            </el-option>
                        </el-select>
                    </template>
                </vxe-column>

                <!-- 起止日期 -->
                <vxe-column min-width="250">
                    <template #header>
                        <span style="color: red;">* </span>起止日期
                    </template>
                    <template #default="{ row }">
                        <el-date-picker v-model="row.start_date" :disabled="row.disabled" style="width: 100%;" type="daterange" value-format="yyyy-MM-dd" unlink-panels :clearable="false" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                    </template>
                </vxe-column>

                <!-- 预估工时 -->
                <vxe-column min-width="180">
                    <template #header>
                        <span style="color: red;">* </span>预估工时/P
                    </template>
                    <template #default="{ row }">
                        <el-input-number v-model="row.working_days" :min="1" :disabled="row.disabled"></el-input-number>
                    </template>
                </vxe-column>

                <!-- 操作 -->
                <vxe-table-column align="center" fixed="right" width="100" title="操作">
                    <template #default="{ row, rowIndex }">
                        <el-button-group>
                            <el-link type="danger"  @click="TaskDel(row)">移除</el-link>
                            <el-link type="primary" :style="{ marginLeft: '10px' }" @click="TaskInsert(row, rowIndex)">复制</el-link>
                        </el-button-group>
                    </template>
                </vxe-table-column>
            </vxe-table>
        </div>

        <!-- footer -->
        <div class="w-el-drawer-footer">

            <el-upload v-if="!parent" :on-change="ImportData" :auto-upload="false" action="#" :show-file-list="false">
                <el-button-group>
                    <el-button v-if="!parent" size="medium" type="success" plain @click.stop="TaskTemplateDown()">文件导入模板.xls</el-button>
                    <el-button v-if="!parent" size="medium" type="success" plain @click.stop="$refs.projectsTemplate.Open()" disabled>我的模板</el-button>
                    <el-button size="medium"  type="success">导入数据</el-button>
                </el-button-group>
            </el-upload>
            <el-divider v-if="!parent" direction="vertical"></el-divider>

            <el-button size="medium" type="danger" @click="TaskDel()">移除任务</el-button>
            <el-divider direction="vertical"></el-divider>

            <el-button-group>
                <el-button size="medium" type="primary" plain @click="TaskInsert()">添加任务</el-button>
                <el-button size="medium" type="primary"  @click="TaskCreate()" :loading="loading">上传任务</el-button>
            </el-button-group>
            <el-divider direction="vertical"></el-divider>

            
            <el-button size="medium" @click="Close()">关闭窗口</el-button>
        </div>

        <!-- 组件 - 项目模板 -->
        <projectsTemplate ref="projectsTemplate" @onSelect="TaskLoad"></projectsTemplate>

    </el-drawer>
</template>

<script>
import projectsTemplate from '@/views/projects/drawerProjectsConfigTemplate'

import * as XLSX from 'xlsx/xlsx.mjs';

export default {
    components: {
        projectsTemplate
    },

    data() {
        return {
            // 颜色选择器
            color: [
                '#f44336', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5', 
                '#2196f3', '#03a9f4', '#00bcd4', '#009688', '#4caf50', 
                '#8bc34a', '#cddc39', '#ffeb3b', '#ffc107', '#ff9800', 
                '#ff5722', '#795548', '#9e9e9e', '#607d8b', '#000000'
            ],

            // 加载状态
            visible: false,
            loading: false,
            loadingText: null,

            // 基础参数
            project_id: null,
            parent: 0,

            task: [],
            taskError: [],
            team: [],
            projectsType: []
        }
    },

    mounted() {

    },

    methods: {

        // 窗口 - 打开
        Open (project_id, parent) {
            this.visible = true
            this.project_id = project_id
            this.parent = parent
            this.$http.post('/9api/projects-team/search', { project_id: this.project_id, append: 'user' }).then(rps => { this.team = rps.data })
            this.$http.post('/9api/projects-task-type/search-all').then(rps => { this.projectsType = rps.data })
        },

        // 窗口 - 关闭
        Close() {
            this.$refs.xTable.setAllCheckboxRow(true)
            this.$refs.xTable.removeCheckboxRow()
            this.loading = false
            this.visible = false
        },

        /**
        |--------------------------------------------------
        | 任务 - 外部加载
        |--------------------------------------------------
        |
        */

        TaskLoad(task) {
            let data = this.$refs.xTable.getTableData().tableData
            
            task.forEach(element => {
                const exi = data.findIndex(item => item.rowId == element.rowId)

                if (exi == -1) {
                    this.$refs.xTable.insertAt({
                        name: element.name,
                        name_color: element.name_color,
                        p: element.p,
                        type_id: element.type_id,
                        assign: element.assign,
                        now_assign: element.now_assign,
                        start_date: element.start_date,
                        working_days: element.working_days,
                        describe: element.describe,
                        rowId: element.rowId,
                        parentField: element.parentField,
                        disabled: element.disabled
                    }, -1)
                }
            })
        },


        /**
        |--------------------------------------------------
        | 任务 - 插入任务
        |--------------------------------------------------
        |
        */

        TaskInsert(row, rowIndex) {
            if (row) {
                this.$refs.xTable.insertAt({
                    milestone: row.milestone,
                    name: row.name,
                    name_color: row.name_color,
                    p: row.p,
                    type_id: row.type_id,
                    assign: row.assign,
                    now_assign: row.now_assign,
                    start_date: row.start_date,
                    working_days: row.working_days,
                    describe: row.describe,
                    disabled: false
                }, rowIndex)
            } else {
                this.$refs.xTable.insertAt({
                    milestone: null,
                    name: null,
                    name_color: '#5489EA',
                    p: 3,
                    type_id: null,
                    assign: [],
                    now_assign: null,
                    start_date: [new Date(), new Date()],
                    working_days: 1,
                    describe: null,
                    disabled: false
                }, -1)
            }
        },

        /**
        |--------------------------------------------------
        | 任务 - 导入数据
        |--------------------------------------------------
        |
        */

        ImportData(file, fileList) {
            
            // 读取文件流
            let dataBinary = new Promise((resolve) => {
                let reader = new FileReader();
                reader.readAsBinaryString(file.raw)
                reader.onload = (ev) => {

                    // 解读文件信息
                    let workBook = XLSX.read(ev.target.result, { type: "binary", cellDates: true });

                    // 解读第一个 Sheet
                    let firstWorkSheet = workBook.Sheets[workBook.SheetNames[0]]

                    // 解析 Sheet 内容
                    let data = XLSX.utils.sheet_to_json(firstWorkSheet);
                    data.splice(0, 3)

                    // 插入数据
                    data.forEach(item => {
                        const { row: newRow } = this.$refs.xTable.insertAt({
                            milestone: item.milestone,
                            name: item.name,
                            name_color: item.name_color ? item.name_color : '#5489EA',
                            p: item.p ? parseInt(item.p[0]) : 3,
                            type_id: this.projectsType.find(element => item.type == element.name)?.id,
                            assign: item.assign ? this.TeamNameToID(item.assign, true) : [],
                            now_assign: item.now_assign ? this.TeamNameToID(item.now_assign) : null,
                            start_date: (item.start_date && item.end_date) ? [item.start_date, item.end_date] : [new Date(), new Date()],
                            working_days: (!item.working_days || item.working_days < 0) ? 0 : item.working_days,
                            describe: item.describe,
                            disabled: false,
                        }, -1)
                    })
                }
            })
        },

        /**
        |--------------------------------------------------
        | 项目成员 - ID检索
        |--------------------------------------------------
        |
        */
        
        TeamNameToID(str, multiple = false) {
            let a = str.split(/[:,、，-：]/)
            let b = this.team.filter(item => a.indexOf(item.user.name) != -1)
            let c = b.map(item => item.user_id)
            let d = Array.from(new Set(c))
            let ids = multiple ? d : (d.length > 0 ? d[0] : null)
            return ids
        },

        /**
        |--------------------------------------------------
        | 任务 - 创建
        |--------------------------------------------------
        |
        */

        TaskCreate() {
            this.$refs.xTable.setAllTreeExpand(true)
            this.loading = true

            // 获取数据
            let data = this.$refs.xTable.getTableData().tableData.filter(item => !item.parentField)

            // 提交表单
            this.$http.post('/9api/pm/task/create-multiple', { task: data, project_id: this.project_id, parent: this.parent }).then(rps => {
                // 提交成功
                this.loading = false

                if (rps.data.state == 'success') {
                    this.$notify.success({ title: '成功', message: rps.data.body });
                    this.$emit('onSave')
                    this.Close()
                }
            })
        },


        /**
        |--------------------------------------------------
        | 任务 - 移除
        |--------------------------------------------------
        |
        */

        TaskDel(row, rowIndex) {
            row ? this.$refs.xTable.remove(row) : this.$refs.xTable.removeCheckboxRow()
        },

        /**
        |--------------------------------------------------
        | 任务 - 模板下载
        |--------------------------------------------------
        |
        */

        TaskTemplateDown() {
            window.open('/9api/xlsTemplate/任务导入模板.xls')
        },


        /**
        |--------------------------------------------------
        | 任务 - 表单校验
        |--------------------------------------------------
        |
        */

        CellFormValidator({ row, column }) {
            if (!row.name || !row.type_id || !row.p || !row.now_assign || !row.start_date || !row.working_days) {
                return {
                    backgroundColor: '#ffebee'
                }
            }
        }
    }
}
</script>
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({

    /**
    |--------------------------------------------------
    | 数据源
    |--------------------------------------------------
    |
    */

    state: {
        user: null,
        routerViews: [],
        httpRequestCount: 0,
        loading: false
    },

    mutations: {
        setUser(state, newVal) {
            state.user = newVal
        },
        setRouterViews(state, newVal) {
            state.routerViews = newVal
        },
        setHttpRequestCount(state, val) {
            state.httpRequestCount += val
            state.loading = !!state.httpRequestCount
        }
    },

    getters: {
    },

    actions: {
    },

    modules: {
    },

});



export default store;

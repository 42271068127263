<template>
    <uw-page title="开票记录">
        
        <!-- 功能 -->
        <template slot="header-suffix">
            <el-button type="primary" @click="$refs.drawerCreate.Open({ contract_id: contract_id })">新建开票记录</el-button>
            <el-button type="primary" @click="$refs.xTable.openExport()">导出</el-button>
            <el-button type="primary" @click="$refs.xTable.openPrint()">打印</el-button>
        </template>
            
        <!-- 数据表 -->
        <vxe-table
            ref="xTable"
            show-footer
            :data="table"
            :export-config="{}"
            :print-config="{}"
            :footer-method="TableFooterMethod"
            height="100%">

            <!-- 序号 -->
            <vxe-column width="60" type="seq" title="序号"></vxe-column>

            <!-- 是否回款 -->
            <vxe-column min-width="90" field="pay_state" title="是否回款" sortable>
                <template #default="{ row }">
                    <el-link v-if="row.pay_state == 0" type="warning">未回款</el-link>
                    <el-link v-if="row.pay_state == 1" type="success">已回款</el-link>
                    <el-link v-if="row.pay_state == 2" type="danger">未退款</el-link>
                    <el-link v-if="row.pay_state == 3" type="primary">已退款</el-link>
                </template>
            </vxe-column>

            <!-- 审批判断 -->
            <vxe-column width="90" field="invoice_content" title="审批状态" align="center">
                <template #default="{ row }">
                    <el-tag v-if="row.workflow_state == 0" size="mini" effect="dark" type="info">未审核</el-tag>
                    <el-tag v-if="row.workflow_state == 1" size="mini" effect="dark" type="warning">待审核</el-tag>
                    <el-tag v-if="row.workflow_state == 2" size="mini" effect="dark" >审核中</el-tag>
                    <el-tag v-if="row.workflow_state == 3" size="mini" effect="dark" type="danger">已拒绝</el-tag>
                    <el-tag v-if="row.workflow_state == 4" size="mini" effect="dark" type="success">已通过</el-tag>
                    <el-tag v-if="row.workflow_state == 5" size="mini" effect="dark" type="warning">待撤销</el-tag>
                    <el-tag v-if="row.workflow_state == 6" size="mini" effect="dark" type="warning">撤销中</el-tag>
                    <el-tag v-if="row.workflow_state == 7" size="mini" effect="dark" type="danger">撤销失败</el-tag>
                    <el-tag v-if="row.workflow_state == 8" size="mini" effect="dark" type="danger">已撤销</el-tag>
                </template>
            </vxe-column>

            <!-- 开票内容 -->
            <vxe-column width="120" field="invoice_content" title="开票内容" sortable>
                <template #default="{ row }">
                    <el-link type="primary" @click="$refs.drawerDetails.Open(row.id)">{{ row.invoice_content }}</el-link>
                </template>
            </vxe-column>

            <!-- 票据类型 -->
            <vxe-column width="120" field="invoice_type_id" title="票据类型" sortable>
                <template #default="{ row }">
                    {{ row.invoice_type.name }}
                </template>
            </vxe-column>

            <!-- 票据金额 -->
            <vxe-column width="90" field="invoice_money" title="票据金额" sortable>
                <template #default="{ row }">
                    ￥{{ row.invoice_money.toLocaleString() }}
                </template>
            </vxe-column>

            <!-- 是否含税 -->
            <vxe-column min-width="90" field="taxation_state" title="是否含税" sortable>
                <template #default="{ row }">
                    <el-link v-if="row.taxation_state == 0">不含税</el-link>
                    <el-link v-if="row.taxation_state == 1">含税</el-link>
                </template>
            </vxe-column>

            <!-- 税率 -->
            <vxe-column width="90" field="taxation_rate" title="税率" sortable>
                <template #default="{ row }">
                    {{ row.taxation_rate }}%
                </template>
            </vxe-column>

            <!-- 税金 -->
            <vxe-column width="90" field="taxation_money" title="税金" sortable>
                <template #default="{ row }">
                    ￥{{ row.taxation_money.toLocaleString() }}
                </template>
            </vxe-column>

            <!-- 开票日期 -->
            <vxe-column width="100" field="invoice_date" title="开票日期" sortable></vxe-column>

            <!-- 经办人 -->
            <vxe-column width="80" field="user.name" title="经办人"></vxe-column>

            <!-- 对应合同 -->
            <vxe-column min-width="250" field="contract.name" title="对应合同">
                <template #default="{ row }">
                    <el-link type="primary">
                        {{ row.contract.number }} - {{ row.contract.name }}
                    </el-link>
                </template>
            </vxe-column>

            <!-- 对应合同 -->
            <vxe-column min-width="250" field="customer.name" title="对应客户">
                <template #default="{ row }">
                    <el-link type="primary">
                        {{ row.customer.name }}
                    </el-link>
                </template>
            </vxe-column>

            <!-- 创建者 -->
            <vxe-column width="80" field="created_user.name" title="创建者"></vxe-column>

            <!-- 更新者 -->
            <vxe-column width="80" field="updated_user.name" title="更新者"></vxe-column>

            <vxe-column width="140" title="操作" fixed="right" align="center">
                <template #default="{ row }">
                    <el-link @click="$refs.drawerUpdate.Open(row.id)" icon="el-icon-edit-outline" type="primary" :underline="false">编辑</el-link>
                    <el-link @click="TableDelete(row.id)" icon="el-icon-delete" type="danger" :underline="false" class="w-margin-l-10">删除</el-link>
                </template>
            </vxe-column>
        </vxe-table>

        <!-- 组件 -->
        <contract-pay-invoice-create ref="drawerCreate" @success="TableSearch" />
        <contract-pay-invoice-update ref="drawerUpdate" @success="TableSearch" />
        <contract-pay-invoice-details ref="drawerDetails" @onChange="TableSearch" />

    </uw-page>
</template>

<script>
export default {
    props: [ 'contract_id' ],

    data () {
        return {
            loading: false,
            
            table: [],
            statistics: {
                invoice_money: 0,
                taxation_money: 0
            },
        }
    },

    watch: {
        contract_id: {
            handler(val) {
                if(val) {
                    this.TableSearch()
                }
            },
            immediate: true
        }
    },

    methods: {

        /**
        |--------------------------------------------------
        | 表格数据 - 查询
        |--------------------------------------------------
        |
        */

        TableSearch() {
            this.loading = true
            this.$http.post(
                '/9api/contract-pay-invoice/search-all',
                {
                    search: { contract_id: this.contract_id },
                    statistics: true,
                    append: ['invoice_type', 'user', 'customer', 'contract', 'created_user', 'updated_user']
                }
            ).then((rps) => {
                // 更新数据
                this.table = rps.data.body
                this.statistics = rps.data.statistics

                // 切换加载状态
                this.loading = false
            })
        },

        /**
        |--------------------------------------------------
        | 表尾数据
        |--------------------------------------------------
        |
        */

        TableFooterMethod() {
            return [
                ['合计',,,,, '￥' + this.statistics.invoice_money.toLocaleString(),,, '￥' + this.statistics.taxation_money.toLocaleString()]
            ]
        },

        /**
        |--------------------------------------------------
        | 表格数据 - 移除
        |--------------------------------------------------
        |
        */

        TableDelete(id) {
            this.loading = true
            this.$http.post('/9api/contract-pay-invoice/delete', { id: id }).then((rps) => {
                this.TableSearch()
            })
        },
    }
}
</script>
<template>
    <div>
        <el-card v-loading="loading">
            <div slot="header">
                <span><i class="el-icon-data-board" :style="{ marginRight: '5px', color: '#0027ff' }"></i>合同款项</span>
            </div>
            <el-descriptions :column="2">
                <el-descriptions-item label="预估成本">￥{{ contract.estimate_money?.toLocaleString() }}</el-descriptions-item>
                <el-descriptions-item label="预估工时">{{ contract.estimate_work_day }} / 天</el-descriptions-item>
                <el-descriptions-item label="合同总额">￥{{ contract.pay_sum_a?.toLocaleString() }}</el-descriptions-item>
                <el-descriptions-item label="已回款金额">￥{{ contract.pay_sum_b?.toLocaleString() }}</el-descriptions-item>
                <el-descriptions-item label="未回款金额">￥{{ contract.pay_sum_c?.toLocaleString() }}</el-descriptions-item>
                <el-descriptions-item label="退款金额">￥{{ contract.pay_sum_d?.toLocaleString() }}</el-descriptions-item>
                <el-descriptions-item label="回款冲销金额">￥{{ contract.pay_sum_e?.toLocaleString() }}</el-descriptions-item>
                <el-descriptions-item label="已开票金额">￥{{ contract.pay_sum_f?.toLocaleString() }}</el-descriptions-item>
                <el-descriptions-item label="未开票金额">￥{{ contract.pay_sum_g?.toLocaleString() }}</el-descriptions-item>
                <el-descriptions-item label="已开票金额【已回款】">￥{{ contract.pay_sum_h?.toLocaleString() }}</el-descriptions-item>
                <el-descriptions-item label="已开票金额【未回款】">￥{{ contract.pay_sum_i?.toLocaleString() }}</el-descriptions-item>
                <el-descriptions-item label="无需开票金额">￥{{ contract.pay_sum_l?.toLocaleString() }}</el-descriptions-item>
            </el-descriptions>
        </el-card>

    </div>
</template>

<script>
export default {

    props: ['id'],

    data() {
        return {
            loading: false,

            contract: {
                id: null,
                source: [],
                customer: {},
                project: {},
                time_limit: [],
                user_admin: {},
                user_affair: {},
                user_presale: {},
                user_sale: {},
                files_id: []
            }
        }
    },

    watch: {
        id: {
            handler (val) {
                if (val) {
                    this.Search()
                }
            },
            immediate: true
        }
    },

    methods: {

        // 合同 - 查询
        Search() {
            this.loading = true
            this.$http.post(
                '/9api/crm/contract/search-first',
                {
                    id: this.id,
                    hidden: ['describe']
                }
            ).then(rps => {
                this.contract = rps.data
                this.loading = false
            })
        }
    }
}

</script>


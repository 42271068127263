<template>
    <div>
        <el-upload :on-change="ImportData" :auto-upload="false" action="#" :show-file-list="false">
            <el-button type="primary">点击上传</el-button>
        </el-upload>

        <el-dialog :visible.sync="visible" class="w-el-dialog" fullscreen title="批量导入" append-to-body :before-close="Close" :close-on-click-modal="false" :close-on-press-escape="false">
           
            <!-- 表格 -->
            <uw-table :show-footer="false" :show-header="false">
                <!-- 表数据 -->
                <template slot="data">
                    <!-- 表内容 -->
                    <vxe-table ref="xTable" :data="table" :loading="loading" :export-config="{}" height="100%">

                        <!-- 序号 -->
                        <vxe-column width="60" type="seq" title="序号"></vxe-column>
                        <vxe-column min-width="150" field="customer_name" title="客户名称" required></vxe-column>
                        <vxe-column min-width="150" field="contract_number" title="合同编号"></vxe-column>
                        <vxe-column min-width="150" field="pay_date" title="款项日期"></vxe-column>
                        <vxe-column min-width="150" field="pay_money" title="款项金额"></vxe-column>
                        <vxe-column min-width="150" field="pay_money_offset" title="冲销金额"></vxe-column>
                        <vxe-column min-width="150" field="pay_money_performance" title="记业绩金额"></vxe-column>
                        <vxe-column min-width="150" field="user_name" title="款项负责人"></vxe-column>
                        <vxe-column min-width="150" field="invoice_state" title="开具发票"></vxe-column>
                        <vxe-column min-width="150" field="describe" title="回款备注"></vxe-column>

                        <!-- 操作 -->
                        <vxe-column title="错误提示" width="30%" align="center" fixed="right" v-if="tableError">
                            <template #default="{ row }">
                                <el-link type="danger">{{ row.error }}</el-link>
                            </template>
                        </vxe-column>

                    </vxe-table>
                </template>
            </uw-table>

            <template slot="footer">
                <el-button size="mini" :loading="loading" @click="Close()">取 消</el-button>
                <el-button size="mini" type="primary"  @click="$refs.xTable.openExport()" >导出当前数据 / 导入模板</el-button>
                <el-button size="mini" type="success"  :loading="loading"  @click="SubmitForm()">开始导入</el-button>
            </template>
            
        </el-dialog>
    </div>
</template>

<script>
import * as XLSX from 'xlsx/xlsx.mjs';

export default {
    data () {
        return {
            visible: false,
            loading: false,

            table: [],
            tableError: false
        }
    },

    methods: {
        ImportData(file) {
            
            this.visible = true

            // 读取文件流
            let dataBinary = new Promise((resolve) => {
                let reader = new FileReader();
                reader.readAsBinaryString(file.raw)
                reader.onload = (ev) => {

                    // 解读文件信息
                    let workBook = XLSX.read(ev.target.result, { type: "binary", cellDates: true });

                    // 解读第一个 Sheet
                    let firstWorkSheet = workBook.Sheets[workBook.SheetNames[0]]

                    // 解析 Sheet 内容
                    let data = XLSX.utils.sheet_to_json(firstWorkSheet);

                    // 读取数据
                    data.forEach(async (item) => {

                        // 插入数据
                        const { row: newRow } = this.$refs.xTable.insertAt({
                            'contract_id': await this.$http.post('/9api/crm/contract/search-first', { number: item['合同编号'], hidden: ['describe'] }).then((rps) => rps.data.id),
                            'contract_number': item['合同编号'],
                            'customer_id': await this.$http.post('/9api/crm/customer/first', { name: item['客户名称'] }).then((rps) => rps.data.id),
                            'customer_name': item['客户名称'],
                            'pay_type': 1,
                            'pay_date': this.$lodash.CSTToDate(item['款项日期']),
                            'pay_money': item['款项金额'],
                            'pay_money_offset': item['冲销金额'],
                            'pay_money_performance': item['记业绩金额'],
                            'user_id': await this.$http.post('/9api/hrm/employee/search-first', { search: { name: item['款项负责人'] }, hidden: ['wecom'] }).then((rps) => rps.data.id),
                            'user_name': item['款项负责人'],
                            'invoice_state': item['开具发票'],
                            'describe': item['回款备注']
                        }, -1)
                    })
                }
            })
        },

        /**
        |--------------------------------------------------
        | 提交表单
        |--------------------------------------------------
        |
        */

        SubmitForm() {
            // 开启加载状态
            this.loading = true

            // 获取数据
            let data = this.$refs.xTable.getTableData().tableData.filter(item => !item.parentField)

            data.forEach(async (element) => {

                // 提交表单
                await this.$http.post('/9api/crm/contract-pay-record/createA', element).then(rps => {
                    // 关闭加载状态
                    this.loading = false

                    // 提交失败
                    if (rps.data.state == 'error') {
                        this.tableError = true
                        element.error = rps.data.body
                    }
                    
                    // 提交成功
                    if (rps.data.state == 'success') {
                        this.$refs.xTable.remove(element)
                    }
                })
            });
        },

        /**
        |--------------------------------------------------
        | 关闭窗口
        |--------------------------------------------------
        |
        */

        Close() {
            this.table = []
            this.tableError = false
            this.visible = false
            this.loading = false
        }
    }
}
</script>
import Vue from 'vue'

const requireComponent  = require.context('@/components/', true, /\.vue$/)

requireComponent.keys().forEach(filePath => {

    const componentConfig = requireComponent(filePath)
    const componentName = filePath.replace(/\.\//g, '').replace(/\//g, '-').replace(/([A-Z])/g, (match) => `-${match.toLowerCase()}`).replace('.vue', '')
    Vue.component(componentName, componentConfig.default || componentConfig)

})
<template>
    <el-select
        @change="SelectChange"
        v-model="selectValue"
        :style="{ width: width, margin: margin }"
        placeholder="请选择选择里程碑或项目阶段"
        clearable
        filterable>
        <el-option v-for="(item, idx) in select" :key="idx" :label="(idx + 1) + '. ' + item.name" :value="item.id"></el-option>
    </el-select>
</template>

<script>
export default {
    props: {
        value: {
            type: [Number, String],
            default: null
        },

        projects_id: {
            type: [Number, String],
            default: null
        },

        width: {
            type: String,
            default: '100%'
        },

        margin: {
            type: String,
            default: '0px'
        }
    },

    data () {
        return {
            select: [],
            selectValue: null,
        }
    },

    watch: {
        value: {
            handler (val) {
                if (val) {
                    this.selectValue = this.value
                }
            },
            immediate: true
        },

        projects_id: {
            handler (val) {
                if (val) {
                    this.Search()
                } else {
                    this.select = []
                }
            },
            immediate: true
        }
    },

    methods: {
        Search () {
            this.$http.post('/9api/pm/milestone/search-all', { search: { projects_id: this.projects_id } }).then(rps => {
                this.select = rps.data
                this.selectValue = this.value
            })
        },

        SelectChange(data) {
            this.$emit('input', data)
            this.$emit('onChange', data)
        }
    }
}
</script>
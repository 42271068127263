<template>
    <el-dialog-menu :visible.sync="visible" :loading="$store.state.loading" :title="order.number" tip="采购订单" fullscreen :before-close="Close">
        
        <template slot="menu">
            
            <!-- 订单状态 -->
            <div :style="{ marginBottom: '10px' }">
                <el-alert center :closable="false" v-if="order.workflow_state == 0" title="无需审核" type="info" />
                <el-alert center :closable="false" v-if="order.workflow_state == 1" title="等待审核中" type="warning" />
                <el-alert center :closable="false" v-if="order.workflow_state == 2" title="正在审核中" type="warning" />
                <el-alert center :closable="false" v-if="order.workflow_state == 3" title="审核结果：拒绝" type="error" />
                <el-alert center :closable="false" v-if="order.workflow_state == 4" title="审核通过" type="success" />
                <el-alert center :closable="false" v-if="order.workflow_state == 5" title="等待撤销中" type="warning" />
                <el-alert center :closable="false" v-if="order.workflow_state == 6" title="撤销审核中" type="warning" />
                <el-alert center :closable="false" v-if="order.workflow_state == 7" title="撤销结果：拒绝" type="error" />
                <el-alert center :closable="false" v-if="order.workflow_state == 8" title="撤销结果：同意" type="success" />
            </div>

            <!-- 功能菜单 -->
            <el-dialog-menu-group title="功能菜单">

                <!-- 基础信息 -->
                <el-dialog-menu-item
                    title="基础信息"
                    icon="el-icon-data-analysis"
                    :is-active="currentComponent == 'ims-order-drawer-base'"
                    @onClick="currentComponent = 'ims-order-drawer-base'" />

                <!-- 入库记录 -->
                <el-dialog-menu-item
                    title="入库记录"
                    icon="el-icon-data-analysis"
                    :is-active="currentComponent == 'ims-order-drawer-warehouse-document-enter'"
                    @onClick="currentComponent = 'ims-order-drawer-warehouse-document-enter'" />

                <!-- 付款 -->
                <el-dialog-menu-item
                    title="付款"
                    icon="el-icon-data-analysis"
                    :is-active="currentComponent == 'ims-order-drawer-pay-out'"
                    @onClick="currentComponent = 'ims-order-drawer-pay-out'" />

                <!-- 附件 -->
                <el-dialog-menu-item
                    title="附件"
                    icon="el-icon-data-analysis"
                    :is-active="currentComponent == 'ims-order-drawer-files'"
                    @onClick="currentComponent = 'ims-order-drawer-files'" />
            
            </el-dialog-menu-group>

            <!-- 订单 -->
            <el-dialog-menu-group title="订单">

                <!-- 申请单 -->
                <el-dialog-menu-item
                    title="申请单"
                    icon="el-icon-data-analysis"
                    :is-active="currentComponent == 'ims-order-drawer-apply'"
                    @onClick="currentComponent = 'ims-order-drawer-apply'" />

                <!-- 订货单 -->
                <el-dialog-menu-item
                    title="订货单"
                    icon="el-icon-data-analysis"
                    :is-active="currentComponent == 'ims-order-drawer-purchase'"
                    @onClick="currentComponent = 'ims-order-drawer-purchase'" />

                <!-- 退货单 -->
                <el-dialog-menu-item
                    title="退货单"
                    icon="el-icon-data-analysis"
                    :is-active="currentComponent == 'ims-order-drawer-back'"
                    @onClick="currentComponent = 'ims-order-drawer-back'" />
                    
            </el-dialog-menu-group>

        </template>

        <template slot="footer">

            <!-- 刷新 -->
            <el-dialog-menu-item
                icon="el-icon-refresh"
                title="刷新"
                @onClick="RePage()"  />

            <!-- 编辑 -->
            <el-dialog-menu-item
                icon="el-icon-edit"
                title="编辑"
                :is-active="currentComponent == 'ims-order-drawer-update'"
                @onClick="currentComponent = 'ims-order-drawer-update'" />

            <!-- 移除 -->
            <el-dialog-menu-item
                icon="el-icon-delete"
                title="删除"
                @onClick="DataDelete()" />
        </template>

        <!-- 动态组件 -->
        <component :is="currentComponent" :id="order.id" model="ims_product_order" @onChange="RePage" />

    </el-dialog-menu>
</template>

<script>
export default {
    data() {
        return {
            visible: false,
            currentComponent: 'ims-order-drawer-base',

            order: {
                id: null,
                files_id: [],
                created_user: {},
                updated_user: {},
                product_order_log: []
            }
        }
    },

    methods: {

        /**
        |--------------------------------------------------
        | 打开窗口
        |--------------------------------------------------
        |
        */

        Open(id) {
            this.visible = true
            this.DataGet(id)
        },

        /**
        |--------------------------------------------------
        | 关闭窗口
        |--------------------------------------------------
        |
        */

        Close(done) {
            this.$emit('onChange')
            done()
        },

        /**
        |--------------------------------------------------
        | 刷新
        |--------------------------------------------------
        |
        */

        RePage() {
            this.DataGet()
        },

        /**
        |--------------------------------------------------
        | 查询
        |--------------------------------------------------
        |
        */

        DataGet(id = this.order.id)
        {
            this.$http.post(
                '/9api/ims/product-order/search-first',
                {
                    search: { id: id }
                }
            ).then(rps => {
                this.order = rps.data
            }) 
        },

        /**
        |--------------------------------------------------
        | 删除
        |--------------------------------------------------
        |
        */

        DataDelete() {
            this.$confirm('一旦删除将无法恢复, 是否要删除!: ', '警告', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'error'
            }).then(() => {
                this.$http.post('/9api/ims/product-order/delete', { id: this.order.id }).then((rps) => {
                    this.$emit('onChange')
                    this.visible = false
                })
            })
        },
        
    }
}

</script>6

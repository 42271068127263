<template>
    <el-drawer :visible.sync="visible" size="80%" direction="rtl" append-to-body destroy-on-close :show-close="false" :before-close="Close">

        <div slot="title">

            <!-- 合同状态 -->
            <div class="w-margin-b-10">
                <span>采购订单 - </span>
                <span v-if="order.workflow_state == 0" >不需要审批</span>
                <span v-if="order.workflow_state == 1" >等待审批</span>
                <span v-if="order.workflow_state == 2" >正在审核中</span>
                <span v-if="order.workflow_state == 3" >审批结果:已被拒绝</span>
                <span v-if="order.workflow_state == 4" >审批结果:已通过</span>
                <span v-if="order.workflow_state == 5" >等待撤销</span>
                <span v-if="order.workflow_state == 6" >正在撤销中</span>
                <span v-if="order.workflow_state == 7" >撤销结果:已被拒绝</span>
                <span v-if="order.workflow_state == 8" >撤销结果:已同意撤销</span>
            </div>
            
            <div class="w-margin-b-10" style="font-size: 18px; color: #000; font-weight: bold;">{{ order.number }}</div>

            <!-- 功能按钮 -->
            <div style="display: flex;">
                <!-- 审批组件 -->
                <workflow-approve-button class="w-margin-r-10" ref="dialogApprove" v-model="order.workflow_id" @onChange="RePage()" />
                <el-button size="mini" icon="el-icon-edit" @click="RePage()">更新信息</el-button>
                <el-button style="margin-left: 5px;" size="mini" @click="DataDelete()" type="danger">取消订单</el-button>
            </div>

            <!-- 金额概览 -->
            <div class="w-margin-t-10" style="background-color: #f4f5f7; border-radius: 3px; padding: 15px; display: flex;">
                <el-statistic title="采购金额"><template slot="formatter"><span style="font-size: 16px;"><b>￥{{ order.money }}</b></span></template></el-statistic>
                <el-statistic title="整单折扣"><template slot="formatter"><span style="font-size: 14px;">{{ order.discount }}%</span></template></el-statistic>
                <el-statistic title="采购日期"><template slot="formatter"><span style="font-size: 14px;">{{ order.date }}</span></template></el-statistic>
                <el-statistic title="创建人"><template slot="formatter"><span style="font-size: 14px;">{{ order.created_user.name }}</span></template></el-statistic>
                <el-statistic title="创建时间"><template slot="formatter"><span style="font-size: 14px;">{{ order.created_at }}</span></template></el-statistic>
            </div>

        </div>

        <div class="w-padding-l-20 w-padding-r-20 w-padding-b-20">
            <el-tabs v-model="tabs">

                <!-- 采购记录 -->
                <el-tab-pane label="采购记录" name="orderLog">
                    <el-link :underline="false" type="danger" v-if="order.describe" :style="{ marginTop: '10px' }">备注：{{ order.describe }}</el-link>
                    <vxe-table border ref="xTable" :data="order.product_order_log" :loading="loading" :style="{ marginTop: '10px' }">

                        <!-- 产品信息 -->
                        <vxe-column min-width="120" title="产品名称">
                            <template #default="{ row }">
                                <span :style="{ color: '#0027ff', cursor: 'pointer' }" @click="$refs.productDetails.Open(row.product.id)">{{ row.product.name }}</span>
                            </template>
                        </vxe-column>
                        <vxe-column min-width="120" field="supplier.name" title="供应商">
                            <template #default="{ row }">
                                <span :style="{ color: '#0027ff', cursor: 'pointer' }" @click="$refs.supplierDetails.Open(row.product.supplier.id)">{{ row.product.supplier.name }}</span>
                            </template>
                        </vxe-column>
                        
                        <vxe-column min-width="100" field="product.type.name" title="产品类别"></vxe-column>
                        <vxe-column min-width="80" field="product.unit" title="计量单位"></vxe-column>
                        <vxe-column min-width="100" title="规格参数">
                            <template #default="{ row }">
                                {{ row.product.parameter?.toString() }}
                            </template>
                        </vxe-column>

                        <!-- 采购信息 -->
                        <vxe-column min-width="80" field="num" title="采购数量"></vxe-column>
                        <vxe-column min-width="90" field="money" title="采购单价/￥"></vxe-column>
                        <vxe-column min-width="70" field="discount" title="折扣/%"></vxe-column>
                        <vxe-column min-width="70" field="money_total" title="合计/￥"></vxe-column>
                        <vxe-column min-width="100" field="money_discount" title="整单折后价/￥"></vxe-column>
                        <vxe-column width="220" field="describe" title="备注说明"></vxe-column>

                    </vxe-table>
                </el-tab-pane>

                <!-- 附件 -->
                <el-tab-pane label="入库记录" name="enter">
                    <ims-order-drawer-enter v-if="tabs == 'enter'" :id="order.id" @onChange="RePage" />
                </el-tab-pane>

                <!-- 附件 -->
                <el-tab-pane label="采购退货" name="back">
                    <ims-order-drawer-back v-if="tabs == 'back'" :id="order.id" @onChange="RePage" />
                </el-tab-pane>

                <!-- 附件 -->
                <el-tab-pane label="附件" name="files">
                    <ims-order-upload-files :id="order.id" @onChange="RePage" />
                    <files-table v-model="order.files_id" />
                </el-tab-pane>

            </el-tabs>
        </div>

        <!-- 组件 -->
        <ims-product-drawer ref="productDetails" @onChange="RePage" />
        <ims-supplier-drawer ref="supplierDetails" @onChange="RePage" />

    </el-drawer>
</template>

<script>
export default {
    data() {
        return {
            visible: false,
            loading: false,
            tabs: 'orderLog',

            order: {
                id: null,
                files_id: [],
                created_user: {},
                updated_user: {},
                product_order_log: []
            }
        }
    },

    methods: {

        /**
        |--------------------------------------------------
        | 打开窗口
        |--------------------------------------------------
        |
        */

        Open(id) {
            this.visible = true
            this.DataGet(id)
        },

        /**
        |--------------------------------------------------
        | 关闭窗口
        |--------------------------------------------------
        |
        */

        Close(done) {
            this.$emit('onChange')
            done()
        },

        /**
        |--------------------------------------------------
        | 刷新
        |--------------------------------------------------
        |
        */

        RePage() {
            this.DataGet()
            this.$refs.dialogApprove.Update()
        },

        /**
        |--------------------------------------------------
        | 查询
        |--------------------------------------------------
        |
        */

        DataGet(id = this.order.id) {
            this.loading = true

            this.$http.post(
                '/9api/ims/product-order/search-first',
                {
                    search: { id: id },
                    append: ['created_user', 'updated_user', 'product_order_log']
                }
            ).then(rps => {
                this.order = rps.data
                this.loading = false
            }) 
        },

        /**
        |--------------------------------------------------
        | 删除
        |--------------------------------------------------
        |
        */

        DataDelete() {
            this.$confirm('一旦删除将无法恢复, 是否要删除?', '警告', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'error'
            }).then(() => {
                this.$http.post('/9api/ims/product-order/delete', { id: organize.id }).then((rps) => {
                    this.$emit('onChange')
                    this.visible = false
                })
            })
        },
        
    }
}

</script>6

<template>
    <div>
        <el-button type="primary" icon="el-icon-box" @click="Export()">导出</el-button>
        <iframe :src="downUrl" width="0" height="0" v-show="false"></iframe>
    </div>
</template>

<script>
export default {
    props:{
        model: {
            type: String,
            default: null
        },
        search: {
            type: Object,
            default: null
        }
    },

    data () {
        return {
            downUrl: null
        }
    },

    methods: {

        // 指令回调
        Export ()
        {
            let notify = this.$notify({
                title: '数据导出',
                dangerouslyUseHTMLString: true,
                duration: 0,
                message: '<i class="el-icon-refresh el-animation-rotate"></i> 正在导出当中！'
            })
            
            this.$http.post('/9api/components-files/export-xls', { model: this.model, search: this.search }).then(rps => {
                this.downUrl = '/9api/components-files/down?id=' + rps.data.id + '&path=' +  rps.data.path
                notify.close()
            })
        }
    }
}
</script>
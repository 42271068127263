<template>
    <el-dropdown @command="handleCommand">

        <!-- 当前选项 -->
        <el-button type="primary" size="mini" :loading="loading">
            {{ name }}
            <i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>

        <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="a">仅本人</el-dropdown-item>
            <el-dropdown-item command="b">仅本部门</el-dropdown-item>
            <el-dropdown-item command="c">仅本部门及下属部门</el-dropdown-item>
            <el-dropdown-item command="d" divided :disabled="![12, 159, 8, 243, 247, 245, 101, 130].includes($store.state.user.id)">所有部门(需要权限的联系林桂林)</el-dropdown-item>
        </el-dropdown-menu>

    </el-dropdown>
</template>

<script>
export default {
    data () {
        return {
            name: '仅本人'
        }
    },

    computed: {
        loading () {
            return !!this.$store.state.httpRequestCount
        }
    },

    methods: {
        handleCommand(command) {
            if (command == 'a') {
                this.name = '仅本人'
                this.$emit('onChange', [this.$store.state.user.id])
            }

            if (command == 'b') {
                this.name = '仅本部门'
                this.$http.post(
                    '/9api/hrm/employee/search-all',
                    {
                        hidden: ['wecom', 'password'],
                        search: { organize_id: this.$store.state.user.organize_id, status: 0, children: false }
                    }
                ).then(rps => {
                    this.$emit('onChange', rps.data.map(item => item.id))
                })
            }

            if (command == 'c') {
                this.name = '仅本部门及下属部门'
                this.$http.post(
                    '/9api/hrm/employee/search-all',
                    {
                        hidden: ['wecom', 'password'],
                        search: { organize_id: this.$store.state.user.organize_id, status: 0, children: true }
                    }
                ).then(rps => {
                    this.$emit('onChange', rps.data.map(item => item.id))
                })
            }

            if (command == 'd') {
                this.name = '所有部门'
                this.$emit('onChange', null)
            }
        }
    }
}
</script>
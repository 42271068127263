<!---------------------------------------------------------------------------- 
 | 客户管理 - 修改客户
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | $refs[ref_name].Open(*id) 手动打开窗口,
 | $refs[ref_name].Close() 手动关闭窗口
 |----------------------------------------------------------------------------
 | 回调
 | @onChange = fn (Object) => {} 成功后返信息
 |
 ---------------------------------------------------------------------------->

 <template>
    <el-dialog :visible.sync="visible" width="925px" append-to-body title="编辑描述" destroy-on-close :close-on-click-modal="false" :close-on-press-escape="false" :before-close="Close">
        
        <froala-editor v-model="form.describe" :heightMax="heightMax" v-if="visible" />

        <span slot="footer">
            <el-button size="mini" @click="Close()">取 消</el-button>
            <el-button size="mini" type="primary" @click="SubmitForm()" :loading="$store.state.loading">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>
export default {
    data () {
        return {
            visible: false,

            heightMax: 100,

            formError: {},
            form: {
                id: null,
                describe: null
            }
        }
    },

    watch: {
        '$el.offsetHeight': {
            handler() {
                this.heightMax = document.documentElement.offsetHeight * 0.6
            },
            immediate: true
        }
    },

    methods: {

        // 窗口 - 打开
        Open (form)
        {
            if (form.id)
            {
                this.form.id = form.id
                this.form.describe = form.describe
                this.visible = true
            }
        },

        // 窗口 - 关闭
        Close() {
            this.visible = false
        },

        // 提交表单
        SubmitForm() {
            this.formError = {}

            // 提交表单
            this.$http.post('/9api/crm/contract/update-describe', this.form).then(rps => {
                // 提交失败
                if (rps.data.state == 'error') {
                    this.formError = rps.data.body
                }
                
                // 提交成功
                if (rps.data.state == 'success') {
                    this.$emit('onChange', rps.data.body)
                    this.Close()
                }
            })
        }
    }  
}
</script>
<!---------------------------------------------------------------------------- 
 | 项目管理 - 任务详情
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | $refs[ref_name].Open(id) 手动打开窗口,
 | $refs[ref_name].Close() 手动关闭窗口
 |----------------------------------------------------------------------------
 | 回调
 | @onChange = fn (Object) => {} 成功后返任务信息
 |
 ---------------------------------------------------------------------------->

<template>
    <el-drawer ref="elDrawer" :visible.sync="visible" size="80%" direction="rtl" append-to-body destroy-on-close :show-close="false" :before-close="Close">

        <!-- header -->
        <div slot="title">

            <div class="w-margin-b-10">
                <span>任务</span>
                <el-tag  style="margin-left: 5px;" size="small" effect="plain" type="primary">ID: {{ task.id }}</el-tag>
                <el-tag  style="margin-left: 5px;" size="small" effect="plain" type="primary">类型: {{ task.type }}</el-tag>
                <el-tag  style="margin-left: 5px;" size="small" effect="plain" v-if="task.p == 1" type="danger">P1 非常重要</el-tag>
                <el-tag  style="margin-left: 5px;" size="small" effect="plain" v-if="task.p == 2" type="warning">P2 重要</el-tag>
                <el-tag  style="margin-left: 5px;" size="small" effect="plain" v-if="task.p == 3" type="primary">P3 次要</el-tag>
                <el-tag  style="margin-left: 5px;" size="small" effect="plain" v-if="task.p == 4" type="success">P4 一般</el-tag>
                <el-tag  style="margin-left: 5px;" size="small" effect="plain" v-if="task.status == 0" type="primary">未开始</el-tag>
                <el-tag  style="margin-left: 5px;" size="small" effect="plain" v-if="task.status == 1" type="primary">进行中</el-tag>
                <el-tag  style="margin-left: 5px;" size="small" effect="plain" v-if="task.status == 2" type="warning">已暂停</el-tag>
                <el-tag  style="margin-left: 5px;" size="small" effect="plain" v-if="task.status == 4" type="info">已关闭</el-tag>
                <el-tag  style="margin-left: 5px;" size="small" effect="plain" v-if="task.status == 5" type="success">已完成</el-tag>
                <el-tag  style="margin-left: 5px;" size="small" effect="plain" v-if="task.status == 6" type="info">已取消</el-tag>
                <el-tag  style="margin-left: 5px;" size="small" effect="plain" v-if="task.is_overdue == 1" type="danger">已延期</el-tag>
            </div>
            <span class="el-span-primary" :style="{ fontSize: '18px', marginBottom: '10px',fontWeight: 'bold', display: 'block' }" @click="$refs.projectsDrawer.Open(task.project.id)">{{ task.project.number }}-{{ task.project.name }}</span>
            <div class="w-margin-b-10" :style="{ fontSize: '18px', color: task.name_color, fontWeight: 'bold' }">{{ task.name }}</div>

            <!-- 功能按钮 -->
            <div style="display: flex;">
                <el-button size="mini" type="primary" @click="$refs.woCreate.Open({ projects_id: task.project_id, projects_task_id: task.id })">生成工单</el-button>

                <!-- 任务流程 -->
                <el-button-group class="w-margin-l-10" v-if="taskChildren.length == 0">
                    <el-button size="mini" type="primary" :disabled="[1, 4].includes(task.status)" @click="$refs.taskStart.Open(task)">开 始</el-button>
                    <el-button size="mini" type="primary" :disabled="[4].includes(task.status)" @click="$refs.taskChange.Open(task)">转 交</el-button>
                    <el-button size="mini" type="primary" :disabled="[0, 2, 4, 5, 6].includes(task.status)" @click="$refs.taskComplete.Open(task)">完 成</el-button>
                    <el-button size="mini" type="primary" :disabled="[0, 1, 2, 4, 6].includes(task.status)" @click="$refs.taskClose.Open(task)">关 闭</el-button>
                </el-button-group>

                <!-- 任务流程 -->
                <el-button-group class="w-margin-l-10" v-if="taskChildren.length == 0">
                    <el-button size="mini" type="warning" :disabled="[2, 4].includes(task.status)" @click="$refs.taskSuspend.Open(task)">暂 停</el-button>
                    <el-button size="mini" type="warning" :disabled="[4, 6].includes(task.status)" @click="$refs.taskCancel.Open(task)">取 消</el-button>
                </el-button-group>

                <el-button-group class="w-margin-l-10">
                    <el-button size="mini" type="warning" :disabled="task.status == 4"  @click="$refs.taskEdit.Open(task.id)">编 辑</el-button>
                    <el-button size="mini" type="danger"  @click="TaskDelete([task.id])">移 除</el-button>
                </el-button-group>
            </div>

            <!-- 基本信息 -->
            <div class="w-margin-t-10" style="background-color: #f4f5f7; border-radius: 3px; padding: 15px; display: flex;">
                <el-statistic title="指派给"><template slot="formatter"><span style="font-size: 16px;"><b>{{ task.now_assign_user_name }}</b></span></template></el-statistic>
                <el-statistic title="任务消耗/P"><template slot="formatter"><span style="font-size: 14px;">{{ task.consume_days }}</span></template></el-statistic>
                <el-statistic title="工单消耗/P"><template slot="formatter"><span style="font-size: 14px;">{{ task.wo_sum_day_consume }}</span></template></el-statistic>
                <el-statistic title="预期/P"><template slot="formatter"><span style="font-size: 14px;">{{ task.working_days }}</span></template></el-statistic>
                <el-statistic title="小计/P"><template slot="formatter"><span style="font-size: 14px;">{{ Number(task.consume_days) + Number(task.wo_sum_day_consume) }}</span></template></el-statistic>
            </div>
        </div>

        <div class="w-padding-l-20 w-padding-r-20 w-padding-b-20">
            <el-tabs v-model="tabs">

                <!-- 任务概况 -->
                <el-tab-pane label="任务概况" name="base">

                    <template>
                        <h4>任务成员</h4>
                        <div style="background: #f5f5f5; padding: 5px; display: flex;">
                            <div v-for="(item, idx) in task.assign_user_object" :key="idx" style="margin-right: 10px; display: flex; flex-direction: column; align-items: center;">
                                <el-avatar size="medium" :src="item.avatar">
                                    {{ item.name[0] }}
                                </el-avatar>
                                <el-link :type="task.now_assign == item.id ? 'primary' : 'warning'" style="margin-top: 5px;">{{ item.name }}</el-link>
                            </div>
                        </div>
                    </template>

                    <template>
                        <h4>任务的一生</h4>
                        <el-descriptions size="mini" :column="2" border>
                            <el-descriptions-item label="预计开始日期">{{ task.start_date[0] }}</el-descriptions-item>
                            <el-descriptions-item label="预计结束日期">{{ task.start_date[1] }}</el-descriptions-item>
                            <el-descriptions-item label="任务创建人">{{ task.create_user_name }} {{ task.created_at }}</el-descriptions-item>
                            <el-descriptions-item label="实际完成人">{{ task.complete_user_name }} {{ task.actual_complete_date }}</el-descriptions-item>
                            <el-descriptions-item label="时间关闭人">{{ task.close_user_name }} {{ task.close_date }}</el-descriptions-item>
                            <el-descriptions-item label="任务取消人">{{ task.cancel_user_name }} {{ task.cancel_date }}</el-descriptions-item>
                            <el-descriptions-item label="更新信息">{{ task.update_user_name }} {{ task.updated_at }}</el-descriptions-item>
                            <el-descriptions-item label="开始时间" :span="3">{{ task.actual_start_date }}</el-descriptions-item>
                            <el-descriptions-item label="分配时间" :span="3">{{ task.assign_date }}</el-descriptions-item>
                        </el-descriptions>
                    </template>

                    <template v-if="taskFlow.length > 0">
                        <h4>任务流程记录 / 工时信息</h4>
                        <vxe-table :data="taskFlow" :row-config="{isCurrent: true, isHover: true}" :filter-config="{showIcon: false}" border="inner">
                            <vxe-column width="45"  type="expand">
                                <template #content="{ row }">
                                    <div v-if="row.describe" style="background-color: #F5F5F5; border-radius: 5px; margin: 5px; padding: 5px;">
                                        <div class="quill-html" v-html="row.describe"></div>
                                    </div>
                                    <el-empty v-else description="用户没有进行描述！"></el-empty>
                                </template>
                            </vxe-column>
                            <vxe-column min-width="120" field="stage" title="流程阶段"></vxe-column>
                            <vxe-column width="120" field="consume_days" title="消耗工时">
                                <template #default="{ row }">
                                    <el-link v-if="row.consume_days !== null" icon="el-icon-edit" type="primary" @click="$refs.editTaskDays.Open(row.id, row.consume_days)">{{ row.consume_days }}</el-link>
                                </template>
                            </vxe-column>
                            <vxe-column width="80" field="user.name" title="流程对象"></vxe-column>
                            <vxe-column width="120" field="create_user.name" title="执行人"></vxe-column>
                            <vxe-column width="150" field="created_at" title="执行时间"></vxe-column>
                            <vxe-column width="80" field="update_user.name" title="更新人"></vxe-column>
                            <vxe-column width="150" field="updated_at" title="更新时间"></vxe-column>
                        </vxe-table>
                    </template>
        
                </el-tab-pane>

                <!-- 任务描述 -->
                <el-tab-pane label="任务描述" name="describe">
                    <froala-views v-model="task.describe" style="max-width: 960px; margin: auto;" />
                </el-tab-pane>

                <!-- 任务附件 -->
                <el-tab-pane label="任务附件" name="files">
                    <el-empty v-if="task.files_id.length == 0" description="暂无附件"></el-empty>
                    <files-table v-model="task.files_id" />
                </el-tab-pane>

                <!-- 关联工单 -->
                <el-tab-pane label="关联工单" name="wo">
                    <pm-projects-task-drawer-wo :projects_id="task.project_id" :projects_task_id="task.id" :height="tabsBodyHeight" v-if="tabs == 'wo' && !loading" />
                </el-tab-pane>

            </el-tabs>
        </div>

        <!-- 关联项目 -->
        <pm-projects-drawer ref="projectsDrawer" @onChange="OnChange" />
        <pm-task-update ref="taskEdit" @onSave="OnChange" />

        <!-- 任务 - 开始/转交/完成/关闭 -->
        <taskStart ref="taskStart" @onSave='OnChange'></taskStart>
        <taskChange ref="taskChange" @onSave='OnChange'></taskChange>
        <taskComplete ref="taskComplete" @onSave='OnChange'></taskComplete>
        <taskClose ref="taskClose" @onSave='OnChange'></taskClose>

        <!-- 任务 - 取消/暂停/移除 -->
        <taskCancel ref="taskCancel" @onSave='OnChange'></taskCancel>
        <taskSuspend ref="taskSuspend" @onSave='OnChange'></taskSuspend>

        <!-- 工单创建 -->
        <pm-wo-create ref="woCreate" @onChange="OnChange" />
        
        <!-- 工时组件 -->
        <editTaskDays ref="editTaskDays" @onSave="OnChange"></editTaskDays>
        
    </el-drawer>
</template>

<script>
import taskStart from '@/views/projects/drawerProjectsTaskFlowStart'
import taskChange from '@/views/projects/drawerProjectsTaskFlowChange'
import taskComplete from '@/views/projects/drawerProjectsTaskFlowComplete'
import taskClose from '@/views/projects/drawerProjectsTaskFlowClose'
import taskCancel from '@/views/projects/drawerProjectsTaskFlowCancel'
import taskSuspend from '@/views/projects/drawerProjectsTaskFlowSuspend'

import editTaskDays from '@/views/projects/DialogProjectsFlowEditTaskDays'

export default {

    // 组件
    components: {
        taskStart, taskChange, taskComplete, taskClose, taskCancel, taskSuspend,
        editTaskDays
    },

    // 计算属性
    computed: {
        tabsBodyHeight() {
            return this.$refs.elDrawer.$el.querySelector('.el-drawer__body').offsetHeight - 75
        },
    },

    // 存储数据
    data () {
        return {
            // 窗口
            visible: false,
            loading: false,
            tabs: 'base',

            // 任务信息
            id: null,
            task: {
                project: {},
                start_date: [],
                files_id: []
            },
            taskChildren: [],
            taskParent: [],
            taskFlow: [],
            taskFile: [],

            // 附件信息
            filesDown: null
            
        }
    },

    // 方法定义
    methods: {
        
        // 窗口 - 打开
        async Open(id) {
            this.id = id
            this.visible = true

            this.TaskGet()
        },


        /**
        |--------------------------------------------------
        | 窗口 - 关闭
        |--------------------------------------------------
        |
        */

        Close(done) {
            this.$emit('onChange')
            done()
        },

        /**
        |--------------------------------------------------
        | 窗口 - 更新
        |--------------------------------------------------
        |
        */
        
        OnChange() {
            this.TaskGet()
            this.$emit('onChange')
        },


        /**
        |--------------------------------------------------
        | 任务 - 切换
        |--------------------------------------------------
        |
        */

        TaskChange(id) {
            this.id = id
            this.TaskGet()
        },

        
        /**
        |--------------------------------------------------
        | 任务 - 移除
        |--------------------------------------------------
        |
        */

        TaskDelete(ids) {
            this.$confirm('此操作将永久删除数据，以及关联的数据，包括且不仅限以下内容: 任务工时、任务附件、子任务、统计数据等', '请谨慎操作', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'error',
                center: true
            }).then(() => {
                // 切换加载状态
                this.loading = true

                this.$http.post('/9api/projects-task/delete', { ids: ids }).then(rps => {
                    this.$emit('onChange')
                    this.visible = false
                })
            })
        },


        /**
        |--------------------------------------------------
        | 任务 - 详情
        |--------------------------------------------------
        |
        */

        async TaskGet () {
            this.loading = true

            const append = ['project', 'wo_sum_day_consume', 'now_assign_user_name', 'type', 'assign_user_object', 'create_user_name', 'update_user_name', 'complete_user_name', 'close_user_name', 'cancel_user_name']

            await this.$http.post('/9api/projects-task/search-id', { id: this.id, append: append }).then(rps => {
                this.task = rps.data
            })

            await this.$http.post('/9api/projects-task/search-children', { id: this.id, append: ['wo_sum_day_consume', 'now_assign_user_name']}).then(rps => {
                this.taskChildren = rps.data
            })

            await this.$http.post('/9api/projects-task/search-parent', { id: this.task.parent, append: ['wo_sum_day_consume', 'now_assign_user_name']}).then(rps => {
                this.taskParent = rps.data
            })

            await this.$http.post('/9api/projects-flow/search-task', { id: this.id, append: ['user', 'create_user', 'update_user']}).then(rps => {
                this.taskFlow = rps.data
            })

            this.loading = false
        },
    }
}
</script>
<template>
    <el-dialog :visible.sync="visible" width="800px" title="修改供应商" append-to-body class="w-el-dialog">

        <!-- 表单 -->
        <el-form :model="form" label-width="120px" size="mini" label-position="top">

            <!-- 产品名称 / 供应商 -->
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="产品名称" :rules="[{ required: true, message: '必填项' }]" :error="formError.name?.toString()" prop="name">
                        <el-input v-model="form.name"  clearable />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="供应商" :rules="[{ required: true, message: '必选项' }]" :error="formError.supplier_id?.toString()" prop="supplier_id">
                        <ims-supplier-select v-model="form.supplier_id" />
                    </el-form-item>
                </el-col>
            </el-row>

            <!-- 类别父级 / 计量单位 -->
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="计量单位" :rules="[{ required: true, message: '必选项' }]" :error="formError.unit?.toString()" prop="unit">
                        <ims-product-unit-select v-model="form.unit" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="产品类别" :rules="[{ required: true, message: '必选项' }]" :error="formError.type_id?.toString()" prop="type_id">
                        <ims-product-type-select v-model="form.type_id" />
                    </el-form-item>
                </el-col>
            </el-row>

            <!-- 产品品牌 / 规格参数 -->
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="产品品牌">
                        <el-input v-model="form.brand" clearable />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="规格参数">
                        <el-select :style="{ width: '100%' }" v-model="form.parameter" multiple filterable allow-create default-first-option placeholder="输入描述后请按'ENTER'键确认'"></el-select>
                    </el-form-item>
                </el-col>
            </el-row>

            <!-- 维保信息 / 备注说明 -->
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="维保信息">
                        <el-input v-model="form.maintenance" clearable />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="备注说明">
                        <el-input type="textarea" placeholder="请输入内容" :autosize="{ minRows: 4, maxRows: 6 }" v-model="form.describe" maxlength="254" show-word-limit></el-input>
                    </el-form-item>
                </el-col>
            </el-row>

        </el-form>

        <div slot="footer">
            <el-button size="small" @click="visible = false">取 消</el-button>
            <el-button size="small" type="primary" @click="FormSubmit()" :loading="loading">确 定</el-button>
        </div>

    </el-dialog>
</template>

<script>
export default {
    data () {
        return {
            visible: false,
            loading: false,

            formError: {},
            form: {
                id: null,
                type_id: null,
                supplier_id: null,
                name: null,
                unit: null,
                brand: null,
                parameter: [],
                maintenance: null,
                describe: null
            }
        }
    },

    methods: {

        /**
        |--------------------------------------------------
        | 打开窗口
        |--------------------------------------------------
        |
        */

        Open(id) {
            this.visible = true
            this.loading = true

            this.$http.post('/9api/ims/product/search-first', { search: { id: id } }).then(rps => {
                this.form = rps.data
                this.loading = false
            })
        },

        /**
        |--------------------------------------------------
        | 提交表单
        |--------------------------------------------------
        |
        */

        FormSubmit() {

            this.loading = true

            this.$http.post('/9api/ims/product/update', this.form).then((rps) => {

                // 提交失败
                if (rps.data.state == 'error') {
                    this.formError = rps.data.body
                }

                // 提交成功
                if (rps.data.state == 'success') {
                    this.$emit('onChange', rps.data.body)
                    this.visible = false
                }

                this.loading = false
            })
        },
    }
}
</script>
<template>
    <el-dialog :visible.sync="visible" title="采购申请" width="900px" append-to-body destroy-on-close @close="close()" :close-on-click-modal="false" :close-on-press-escape="false">

        <el-steps :active="active" simple>
            <el-step title="1. 订单信息" icon="el-icon-tickets"></el-step>
            <el-step title="2. 添加产品" icon="el-icon-shopping-cart-full"></el-step>
            <el-step title="3. 配送信息" icon="el-icon-truck"></el-step>
        </el-steps>
        
        <!-- 1. 订单信息 -->
        <div :style="{ margin: '20px 0', height: '400px', display: 'flex', alignItems: 'center', justifyContent: 'space-around' }" v-show="active == 0">
            
            <!-- 插画 -->
            <el-image src="/img/illustration/1.png" :style="{ width: '40%' }"></el-image>
            
            <!-- 表单 -->
            <el-form :model="form" label-position="top" :style="{ width: '40%' }">
                <el-form-item label="关联合同" v-if="form.type != 'company'" :rules="[{ required: true, message: '必选项' }]" :error="formError.contract_id?.toString()" prop="contract_id">
                    <crm-contract-select v-model="form.contract_id" />
                </el-form-item>
                <el-form-item label="采购日期">
                    <el-date-picker v-model="form.date" type="date" value-format="yyyy-MM-dd" :style="{ width: '100%' }"></el-date-picker>
                </el-form-item>
                <el-form-item label="采购备注">
                    <el-input type="textarea" :rows="3" v-model="form.describe"></el-input>
                </el-form-item>
            </el-form>
        </div>

        <!-- 2. 添加产品 -->
        <div :style="{ margin: '20px 0', height: '400px' }" v-show="active == 1">

            <div :style="{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }">
                <div>
                    <el-span type="primary" :title="'合计金额：' + $lodash.toMoney(form.money_estimate)"></el-span>
                </div>
                <div>
                    <el-button type="primary" @click="$refs.productSearch.open()">产品库</el-button>
                </div>
            </div>

            <!-- 产品列表 -->
            <vxe-table border ref="xTable" :data="form.product" :loading="loading" min-height="350px" :style="{ marginTop: '10px' }">

                <!-- 产品名称 -->
                <vxe-column min-width="100" field="name" title="产品名称" fixed="left"></vxe-column>
                
                <!-- 计量单位 -->
                <vxe-column min-width="80" field="unit" title="计量单位"></vxe-column>

                <!-- 采购数量 -->
                <vxe-column min-width="120" title="采购数量">
                    <template #default="{ row }">
                        <el-input-number v-model="row.num" @change="productUpdate" :min="1" :precision="2" :controls="false" :style="{ width: '100%' }"></el-input-number>
                    </template>
                </vxe-column>

                <!-- 采购单价 -->
                <vxe-column min-width="120" title="采购单价">
                    <template #default="{ row }">
                        <el-input-number v-model="row.money" @change="productUpdate" :min="0" :precision="2" :controls="false" :style="{ width: '100%' }"></el-input-number>
                    </template>
                </vxe-column>

                <!-- 折扣/% -->
                <vxe-column min-width="140" title="折扣/%">
                    <template>
                        <el-input-number :controls="false" v-model="form.discount" @change="productUpdate" :precision="2" :style="{ width: '100%' }"></el-input-number>
                    </template>
                </vxe-column>

                <!-- 合计 -->
                <vxe-column min-width="100" field="money_total" title="合计"></vxe-column>

                <!-- 折扣/% -->
                <vxe-column min-width="140" title="整单折扣/%">
                    <template #default="{ row }">
                        <el-input-number :controls="false" v-model="row.discount_document" @change="ProductUpdate" :precision="2" :style="{ width: '100%' }" />
                    </template>
                </vxe-column>
                
                <!-- 整单折后价 -->
                <vxe-column min-width="100" field="money_discount" title="整单折后价"></vxe-column>

                <!-- 备注说明 -->
                <vxe-column width="220" field="describe" title="备注说明">
                    <template #default="{ row }">
                        <el-input v-model="row.describe" placeholder="请输入内容"></el-input>
                    </template>
                </vxe-column>

                <!-- 移除 -->
                <vxe-column width="60" align="center" fixed="right">
                    <template #default="{ row }">
                        <el-link type="danger" :underline="false" @click="productDelete(row.id)">移除</el-link>
                    </template>
                </vxe-column>

            </vxe-table>

        </div>
        
        <!-- 3. 配送信息 -->
        <div :style="{ margin: '20px 0', height: '400px', display: 'flex', alignItems: 'center', justifyContent: 'space-around' }" v-show="active == 2">
            
            <!-- 插画 -->
            <el-image src="/img/illustration/2.png" :style="{ width: '40%' }"></el-image>

            <!-- 表单 -->
            <el-form :model="form" label-position="top" :style="{ width: '40%' }">
                <el-form-item label="交货时间">
                    <el-date-picker v-model="form.delivery_date" type="date" value-format="yyyy-MM-dd" :style="{ width: '100%' }"></el-date-picker>
                </el-form-item>
                <el-form-item label="交货地址">
                    <el-input v-model="form.delivery_address" clearable></el-input>
                </el-form-item>
                <el-form-item label="联系人">
                    <el-input v-model="form.delivery_name" clearable></el-input>
                </el-form-item>
                <el-form-item label="电话">
                    <el-input v-model="form.delivery_phone" clearable></el-input>
                </el-form-item>
            </el-form>

        </div>

        <div slot="footer">
            <el-button type="primary" @click="active -= 1" :disabled="!active">上一步</el-button>
            <el-button type="primary" @click="active += 1" :disabled="active == 2">下一步</el-button>
            <el-button type="primary" @click="formSubmit()" :disabled="active < 1">确 定</el-button>
        </div>

        <!-- 组件 -->
        <ims-product-search ref="productSearch" @onChange="productAdd" />

    </el-dialog>
</template>

<script>
export default {
    data () {
        return {
            visible: false,
            loading: false,
            active: 0,

            formError: {},
            form: {
                type: 'company',
                contract_id: null,
                date: null,
                money_estimate: 0,
                discount: 0,
                describe: null,
                product: [],
                delivery_date: null,
                delivery_address: null,
                delivery_name: null,
                delivery_phone: null
            }
        }
    },
    
    methods: {

        // 打开窗口
        open(config) {
            if (config) {
                this.form.type = config.type
                this.form.contract_id = config.contract_id
            }
            this.visible = true
            this.loading = false
        },

        // 关闭窗口
        close() {
            this.form.contract_id = null
            this.form.date = null
            this.form.money_estimate = 0
            this.form.describe = null
            this.form.product = []
            this.form.delivery_date = null
            this.form.delivery_address = null
            this.form.delivery_name = null
            this.form.delivery_phone = null
        },

        // 产品列表 - 添加
        productAdd(data) {
            data.forEach(element => {
                if (!this.form.product.find(rps => rps.id == element.id)) {
                    this.$set(element, 'num', 1)
                    this.$set(element, 'discount', 0)
                    this.$set(element, 'discount_document', 0)
                    this.$set(element, 'money', 0)
                    this.$set(element, 'money_total', 0)
                    this.$set(element, 'money_discount', 0)
                    this.$set(element, 'describe', element.describe)
                    this.form.product.push(element)
                }
            })
        },

        // 产品列表 - 移除
        productDelete(id) {
            const index = this.form.product.findIndex(rps => rps.id == id)
            this.form.product.splice(index, 1)
            this.productUpdate()
        },

        // 产品列表 - 更新数据
        productUpdate() {
            this.form.product.forEach(element => {
                element.money_total = (element.money * element.num * (1 - element.discount * 0.01)).toFixed(2)
                element.money_discount = (element.money_total * (1 - this.form.discount * 0.01)).toFixed(2)
            })
            this.form.money_estimate = this.$lodash.array_sum(this.form.product, 2, 'money_discount')
        },

        // 提交表单
        formSubmit() {
            // this.loading = true
            this.$http.post('/9api/ims/product-order/create', this.form).then((rps) => {

                // 提交失败
                if (rps.data.state == 'error') {
                    this.formError = rps.data.body
                }

                // 提交成功
                if (rps.data.state == 'success') {
                    this.$emit('onChange', rps.data.body)
                    this.visible = false
                }

                this.loading = false
            })
        },
    }
}
</script>
<template>
    <div id="froalaEditor" :class="[clean ? 'is-clean' : '']"></div>
</template>

<script>
import FroalaEditor from 'froala-editor'
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/js/languages/zh_cn.js';
import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/js/third_party/embedly.min.js';
import 'froala-editor/js/third_party/font_awesome.min.js';
import 'froala-editor/js/third_party/spell_checker.min.js';
import 'froala-editor/js/third_party/image_tui.min.js';
import 'froala-editor/js/third_party/showdown.min.js';

export default {
    props: {
        value: {
            type: String,
            default: null
        },
        documentReady: {
            type: Boolean,
            default: false
        },
        clean: {
            type: Boolean,
            default: false
        },
        height: {
            type: Number,
            default: 200
        },
        heightMax: {
            type: Number,
            default: 500
        },
    },

    data () {
        return {
            editor: null,
        }
    },

    watch: {
        value: {
            handler(newVla, oldVal) {
                if (newVla != (this.editor?.html.get() ?? null)) {
                    this.editor?.html?.set(this.value)
                }
            },
            immediate: true
        },
    },

    mounted () {
        this.editor = new FroalaEditor('#froalaEditor', {
            zIndex: 25001,

            // 模式 - 已记录完整

            // 模式 - 是否使用文档模式
            documentReady: this.documentReady,
            // 模式 - 是否在弹出窗口中编辑
            editInPopup: false,
            // 模式 - 是否嵌套在单独html中
            fullPage: true,
            // 模式 - 是否嵌套在单独iframe中
            iframe: true,
            // 模式 - 内联编辑器模式
            toolbarInline: false,

            // 工具栏 - 已记录完整

            // 工具栏 - 是否始终显示在上方
            toolbarSticky: false,
            // 工具栏 - 是否显示在底部
            toolbarBottom: false,
            // 工具栏 - 光标闪烁时是否显示浮动工具栏
            toolbarVisibleWithoutSelection: true,
            // 工具栏 - 偏移位置
            toolbarStickyOffset: 0,
            // 工具栏 - 功能配置
            toolbarButtons: {
                moreRich: {
                    buttons: ['insertImage', 'insertVideo', 'insertLink', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertHR', 'markdown', 'trackChanges']
                },
                moreText: {
                    buttons: ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting']
                },
                moreParagraph: {
                    buttons: ['align', 'alignLeft', 'alignCenter', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote']
                },
                moreMisc: {
                    buttons: ['undo', 'redo', 'fullscreen', 'print', 'getPDF', 'spellChecker', 'selectAll', 'html', 'help'],
                    align: 'right',
                    buttonsVisible: 2
                }
            },
            
            // 打字配置 - 已记录完整

            // 打字配置 - Enter按键换行时使用div\p\br三种当中那一种标签
            // enter: FroalaEditor.ENTER_DIV,
            // enter: FroalaEditor.ENTER_BR,
            // 打字配置 - 快捷方式
            // shortcutsEnabled: ['bold', 'italic'],
            // 打字配置 - TAB按键缩进大小
            tabSpaces: 4,

            // 杂项 - 已记录完整

            // 杂项 - 是否显示字数统计结果
            charCounterCount: true,
            // 杂项 - 最大字数限制
            // charCounterMax: 140,
            // 杂项 - 没用内容时的提示词
            placeholderText: '请输入内容!',
            // 杂项 - 粘贴时去除所有样式
            pastePlain: false,
            // 杂项 - 快速插入按钮
            // pluginsEnabled: [],
            // 杂项 - 响应式视频
            videoResponsive: true,


            // 快速插入
            quickInsertEnabled: false,
            quickInsertButtons: ['embedly', 'table', 'ul', 'ol', 'hr'],
            quickInsertTags: ['p', 'div', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'pre', 'blockquote'],

            // 编辑器大小
            heigth: this.height,
            heightMin: this.height,
            heightMax: this.heightMax,

            // 代码美化器
            codeBeautifierOptions: {
                end_with_newline: true,
                indent_inner_html: true,
                extra_liners: "['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'blockquote', 'pre', 'ul', 'ol', 'table', 'dl']",
                brace_style: 'expand',
                indent_char: ' ',
                indent_size: 4,
                wrap_line_length: 0
            },
            codeMirror: true,
            codeMirrorOptions: {
                indentWithTabs: true,
                lineNumbers: true,
                lineWrapping: true,
                mode: 'text/html',
                tabMode: 'indent',
                tabSize: 2
            },
            codeViewKeepActiveButtons: ["fullscreen"],

            // 颜色配置
            colorsBackground: [
                '#f44336', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5', '#2196f3', '#03a9f4', '#00bcd4',
                '#009688', '#4caf50', '#8bc34a', '#cddc39', '#ffeb3b', '#ffc107', '#ff9800', '#ff5722',
                '#795548', '#9e9e9e', '#607d8b', 'REMOVE'
            ],
            colorsButtons: ["colorsBack", "|", "-"],
            colorsHEXInput: true,
            colorsStep: 8,
            colorsText: [
                '#f44336', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5', '#2196f3', '#03a9f4', '#00bcd4',
                '#009688', '#4caf50', '#8bc34a', '#cddc39', '#ffeb3b', '#ffc107', '#ff9800', '#ff5722',
                '#795548', '#9e9e9e', '#607d8b', 'REMOVE'
            ],

            // 拖拽功能
            dragInline: false,

            // 表情符号
            emoticonsButtons: ["emoticonsBack", "|"],
            emoticonsUseImage: true,
            emoticonsStep: 10,

            // 字体
            fontFamily: {
                'Arial,Helvetica,sans-serif': 'Arial',
                'Georgia,serif': 'Georgia',
                'Impact,Charcoal,sans-serif': 'Impact',
                'Tahoma,Geneva,sans-serif': 'Tahoma',
                "'Times New Roman',Times,serif": 'Times New Roman',
                'Verdana,Geneva,sans-serif': 'Verdana'
            },
            fontFamilyDefaultSelection: '字体',
            fontFamilySelection: true,

            // 字体大小
            fontSizeSelection: true,
            fontSize: ['8', '9', '10', '11', '12', '14', '18', '24', '30', '36', '48', '60', '72', '96'],
            fontSizeDefaultSelection: '14',
            fontSizeUnit: 'px',

            // 图像配置
            imageAddNewLine: true,
            imageResizeWithPercent: true,
            imageDefaultWidth: 100,
            imageUploadRemoteUrls: true,
            imageUpload: true,
            imageUploadMethod: 'POST',
            imageUploadURL: '/9api/components-files/upload-link',

            // 请求配置
            requestHeaders: {
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            },

            // 其它配置
            attribution: false,
            autofocus: false,
            htmlAllowComments: true,
            htmlExecuteScripts: false,
            htmlUntouched: true,
            language: 'zh_cn',
            
            
            events: {
                'contentChanged': () => {
                    this.$emit('input', this.editor.html.get())
                },
                'initialized': () => {
                    this.editor.html.set(this.value)
                }
            }
        })
    }
}
</script>
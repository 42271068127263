<template>
    <uw-page title="任务列表">

        <!-- 功能 -->
        <template slot="header-suffix">
            <el-dropdown trigger="click">
                <el-button type="primary" >批量管理<i class="el-icon-arrow-down el-icon--right"></i></el-button>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native.stop="$refs.taskCreateMultiple.Open(projects_id)">批量创建任务</el-dropdown-item>
                    <el-dropdown-item @click.native.stop="TaskEditAssign()">批量修改任务成员</el-dropdown-item>
                    <el-dropdown-item @click.native.stop="TaskDeleteMultiple()" divided>批量删除</el-dropdown-item>
                    <el-dropdown-item @click.native.stop="TaskFlowStartMultiple()" divided>批量开始</el-dropdown-item>
                    <el-dropdown-item @click.native.stop="TaskFlowSuspendMultiple()">批量暂停</el-dropdown-item>
                    <el-dropdown-item @click.native.stop="TaskFlowCancelMultiple()">批量取消</el-dropdown-item>
                    <el-dropdown-item @click.native.stop="TaskFlowCloseMultiple()">批量关闭</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
            <el-button style="margin-left: 5px;" @click="$refs.taskCreate.Open(projects_id)" type="primary" >新建任务</el-button>
            <files-export-xls-dropdown model="pm_task" :search="post.search" :style="{ marginLeft: '5px', float: 'right' }" />
        </template>

        <!-- 检索 -->
        <template slot="search">
            <el-form-item label="任务名称">
                <el-input v-model="post.search.name" @input="$lodash.debounce(Search, 500)" clearable placeholder="任务名称" />
            </el-form-item>
            <el-form-item label="指派给">
                <el-select v-model="post.search.now_assign" @change="Search()" clearable filterable :style="{ width: '100%' }">
                    <el-option v-for="(item, idx) in team" :key="idx" :label="item.user?.name" :value="item.user?.id" />
                </el-select>
            </el-form-item>
            <el-form-item label="里程碑 / 项目阶段">
                <pm-milestone-select @onChange="Search()" v-model="post.search.milestone_id" :projects_id="post.search.project_id" />
            </el-form-item>
            <el-form-item label="任务状态">
                <el-select v-model="post.search.status" @change="Search()" clearable filterable :style="{ width: '100%' }">
                    <el-option :value="0" label="未开始" />
                    <el-option :value="1" label="进行中" />
                    <el-option :value="2" label="已暂停" />
                    <el-option :value="4" label="已关闭" />
                    <el-option :value="5" label="已完成" />
                    <el-option :value="6" label="已取消" />
                </el-select>
            </el-form-item>
            <el-form-item label="是否延期">
                <el-select v-model="post.search.is_overdue" @change="Search()" clearable filterable :style="{ width: '100%' }">
                    <el-option :value="0" label="未延期" />
                    <el-option :value="1" label="已延期" />
                </el-select>
            </el-form-item>
        </template>

        <!-- 任务列表 -->
        <vxe-table ref="xTable" :data="table" height="100%">

            <!-- 复选框 -->
            <vxe-column type="checkbox" width="45" fixed="left"></vxe-column>

            <!-- 任务名称 -->
            <vxe-column min-width="300" title="任务名称">
                <template #default="{ row }">
                    <!-- 重要程度 -->
                    <el-tag :style="{ marginRight: '5px' }" v-if="row.p == 1" effect="plain" type="danger">P1</el-tag>
                    <el-tag :style="{ marginRight: '5px' }" v-if="row.p == 2" effect="plain" type="warning">P2</el-tag>
                    <el-tag :style="{ marginRight: '5px' }" v-if="row.p == 3" effect="plain" type="primary">P3</el-tag>
                    <el-tag :style="{ marginRight: '5px' }" v-if="row.p == 4" effect="plain" type="success">P4</el-tag>
                    <!-- 状态判断 -->
                    <el-tag :style="{ marginRight: '5px' }" v-if="row.status == 0" effect="dark" type="primary">未开始</el-tag>
                    <el-tag :style="{ marginRight: '5px' }" v-if="row.status == 1" effect="dark" type="primary">进行中</el-tag>
                    <el-tag :style="{ marginRight: '5px' }" v-if="row.status == 2" effect="dark" type="warning">已暂停</el-tag>
                    <el-tag :style="{ marginRight: '5px' }" v-if="row.status == 4" effect="dark" type="info">已关闭</el-tag>
                    <el-tag :style="{ marginRight: '5px' }" v-if="row.status == 5" effect="dark" type="success">已完成</el-tag>
                    <el-tag :style="{ marginRight: '5px' }" v-if="row.status == 6" effect="dark" type="info">已取消</el-tag>
                    <!-- 延期判断 -->
                    <el-tag v-if="row.is_overdue == 1" effect="dark" type="danger">已延期</el-tag>
                    <!-- 项目名称 -->
                    <el-span type="primary" :style="{ color: row.name_color }" @click.native="$refs.taskIndex.Open(row.id)" :title="row.name" />
                </template>
            </vxe-column>

            <!-- 里程碑/项目阶段 -->
            <vxe-column min-width="150" field="name" title="里程碑/项目阶段">
                <template #default="{ row }">
                    {{ row.milestone?.name ?? '-' }}
                </template>
            </vxe-column>

            <!-- 任务类型 -->
            <vxe-column width="120" field="type" title="任务类型" />

            <!-- 指派给 -->
            <vxe-column width="110" title="指派给">
                <template #default="{ row }">
                    <div style="display: flex; align-items: center; justify-content: flex-start;">
                        <el-avatar style="margin-right: 5px;" :size="26" :src="row.now_assign_user?.avatar"> {{ row.now_assign_user?.name[0] }} </el-avatar>
                        {{  row.now_assign_user?.name }}
                    </div>
                </template>
            </vxe-column>

            <!-- 参与人员 -->
            <vxe-column width="80" field="assign" title="参与人员" :visible="true">
                <template #default="{ row }">
                    <el-tooltip placement="top" :content="row.assign_user_name.toString()">
                        <el-link icon="el-icon-user">{{ row.assign.length }}</el-link>
                    </el-tooltip>
                </template>
            </vxe-column>

            <!-- 概要 -->
            <vxe-column min-width="90" field="consume_days" title="任务消耗/P"></vxe-column>
            <vxe-column min-width="90" field="wo_sum_day_consume" title="工单消耗/P"></vxe-column>
            <vxe-column min-width="70" field="working_days" title="预期/P"></vxe-column>
            <vxe-column min-width="70" title="小计/P">
                <template #default="{ row }">
                    {{ Number(row.consume_days) + Number(row.wo_sum_day_consume) }}
                </template>
            </vxe-column>

            <!-- 时间 -->
            <vxe-column width="90" field="start_date[0]" title="开始时间"></vxe-column>
            <vxe-column width="90" field="start_date[1]" title="截止时间"></vxe-column>
            
            <vxe-column width="45" title="操作" fixed="right" align="center">
                <template #default="{ row }">
                    <el-dropdown trigger="click">
                        <el-link icon="el-icon-more" type="primary" :underline="false"></el-link>
                        <el-dropdown-menu slot="dropdown">

                            <el-dropdown-item @click.native.stop="$refs.taskStart.Open(row)" :disabled="[1, 4].includes(row.status)">
                                <el-link :underline="false" :disabled="[1, 4].includes(row.status)">开始</el-link>
                            </el-dropdown-item>
                            <el-dropdown-item @click.native.stop="$refs.taskChange.Open(row)" :disabled="[4].includes(row.status)">
                                <el-link :underline="false" :disabled="[4].includes(row.status)">转交</el-link>
                            </el-dropdown-item>
                            <el-dropdown-item @click.native.stop="$refs.taskComplete.Open(row)" :disabled="[0, 2, 4, 5, 6].includes(row.status)">
                                <el-link :underline="false" :disabled="[0, 2, 4, 5, 6].includes(row.status)">完成</el-link>
                            </el-dropdown-item>
                            <el-dropdown-item @click.native.stop="$refs.taskClose.Open(row)" :disabled="[0, 1, 2, 4, 6].includes(row.status)">
                                <el-link :underline="false" :disabled="[0, 1, 2, 4, 6].includes(row.status)">关闭</el-link>
                            </el-dropdown-item>

                            <el-dropdown-item @click.native.stop="$refs.taskEdit.Open(row.id)" divided>
                                <el-link type="primary" :underline="false">修改</el-link>
                            </el-dropdown-item>
                            <el-dropdown-item @click.native.stop="$refs.taskSuspend.Open(row)" :disabled="[2, 4].includes(row.status)">
                                <el-link type="warning" :underline="false" :disabled="[2, 4].includes(row.status)">暂停</el-link>
                            </el-dropdown-item>
                            <el-dropdown-item @click.native.stop="$refs.taskCancel.Open(row)" :disabled="[4, 6].includes(row.status)">
                                <el-link type="warning" :underline="false" :disabled="[4, 6].includes(row.status)">取消</el-link>
                            </el-dropdown-item>
                            <el-dropdown-item @click.native.stop="TaskDelete([row.id])">
                                <el-link type="danger" :underline="false">移除</el-link>
                            </el-dropdown-item>

                        </el-dropdown-menu>
                    </el-dropdown>
                </template>
            </vxe-column>
        </vxe-table>

        <!-- 分页 -->
        <template slot="footer">
            <vxe-pager :total="post.page.total" :page-size.sync="post.page.size" :current-page.sync="post.page.current" @page-change="Search()" />
        </template>

        <!-- 组件：任务创建 -->
        <pm-task-create ref="taskCreate" @onSave="Search()" />
        <pm-task-update ref="taskEdit" @onSave="Search()" />
        
        <taskCreateMultiple ref="taskCreateMultiple" @onSave="Search()" />
        <taskEditAssign ref="taskEditAssign" @onSave="Search()"></taskEditAssign>

        <!-- 组件：任务 - 开始/转交/完成/关闭 -->
        <taskStart ref="taskStart" @onSave='Search()'></taskStart>
        <taskChange ref="taskChange" @onSave='Search()'></taskChange>
        <taskComplete ref="taskComplete" @onSave='Search()'></taskComplete>
        <taskClose ref="taskClose" @onSave='Search()'></taskClose>

        <!-- 组件：任务 - 取消/暂停/移除 -->
        <taskCancel ref="taskCancel" @onSave='Search()'></taskCancel>
        <taskSuspend ref="taskSuspend" @onSave='Search()'></taskSuspend>

        <!-- 组件：任务 - 详情 -->
        <pm-projects-task-drawer ref="taskIndex" @onChange="Search()" />

    </uw-page>
</template>

<script>
import taskCreateMultiple from '@/views/projects/drawerProjectsTaskCreateMultiple'

import taskEditAssign from '@/views/projects/drawerProjectsTaskEditAssign'

import taskStart from '@/views/projects/drawerProjectsTaskFlowStart'
import taskChange from '@/views/projects/drawerProjectsTaskFlowChange'
import taskComplete from '@/views/projects/drawerProjectsTaskFlowComplete'
import taskClose from '@/views/projects/drawerProjectsTaskFlowClose'
import taskCancel from '@/views/projects/drawerProjectsTaskFlowCancel'
import taskSuspend from '@/views/projects/drawerProjectsTaskFlowSuspend'

export default {
    props: ['projects_id', 'link_query'],

    components: {
        taskCreateMultiple,
        taskEditAssign,
        taskStart,
        taskChange,
        taskComplete,
        taskClose,
        taskCancel,
        taskSuspend,
    },

    data () {
        return {
            loading: true,

            table: [],
            team: [],

            post: {
                search: {
                    milestone_id: null,
                    project_id: null,
                    now_assign: null,
                    status: null,
                    is_overdue: null,
                    name: null
                },
                order: {
                    method: 'desc',
                    column: 'id'
                },
                page: {
                    total: 0,
                    size: 20,
                    current: 1,
                },
                append: [ 'milestone', 'now_assign_user', 'wo_sum_day_consume', 'type', 'assign_user_object', 'assign_user_name' ],
                hidden: ['describe']
            }
        }
    },

    watch: {
        projects_id: {
            handler (val) {
                if (val) {
                    this.post.search.project_id = this.projects_id
                    this.post.search = { ...this.post.search, ...this.link_query }
                    this.Search()
                    this.TeamSearch()
                }
            },
            immediate: true
        },
        link_query: {
            handler() {
                this.post.search.project_id = this.projects_id
                this.post.search = { ...this.post.search, ...this.link_query }
                this.Search()
            },
            immediate: true,
            deep: true
        }
    },

    methods: {

        /**
        |--------------------------------------------------
        | 项目任务 - 检索
        |--------------------------------------------------
        |
        */

        Search() {
            // 检索请求
            this.$http.post('/9api/pm/task/search', this.post).then(rps => {
                // 更新数据
                this.post.page.total = rps.data.total
                this.table = rps.data.body

                // 页码溢出
                if (this.table.length == 0 && this.post.page.current != 1) {
                    this.post.page.current = 1
                    this.Search()
                }
            })
        },


        /**
        |--------------------------------------------------
        | 项目任务 - 移除
        |--------------------------------------------------
        |
        */

        TaskDelete(ids) {

            this.$confirm('此操作将永久删除数据，以及关联的数据，包括且不仅限以下内容: 任务工时、任务附件、子任务、统计数据等', '请谨慎操作', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'error',
                center: true
            }).then(() => {
                // 切换加载状态
                this.loading = true

                this.$http.post('/9api/projects-task/delete', { ids: ids }).then(rps => {
                    this.Search()
                })
            })
        },

        TaskDeleteMultiple() {
            this.TaskDelete(this.$refs.xTable.getCheckboxRecords().map(item => item.id))
        },


        /**
        |--------------------------------------------------
        | 项目任务 - 批量修改参任务成员
        |--------------------------------------------------
        |
        */

        TaskEditAssign() {
            const ids = this.$refs.xTable.getCheckboxRecords().map(item => item.id)
            this.$refs.taskEditAssign.Open(this.projects_id, ids)
        },


        /**
        |--------------------------------------------------
        | 项目成员 - 检索
        |--------------------------------------------------
        |
        */

        TeamSearch() {
            this.$http.post('/9api/projects-team/search', { project_id: this.projects_id, append: 'user' }).then(rps => {
                this.team = rps.data
            })
        },

        /**
        |--------------------------------------------------
        | 批量操作
        |--------------------------------------------------
        |
        */

        TaskFlowStartMultiple() {
            this.loading = true
            this.$http.post('/9api/projects-task/flow-start-multiple', { ids: this.$refs.xTable.getCheckboxRecords().map(item => item.id)}).then(rps => {
                this.Search()
            })
        },

        TaskFlowSuspendMultiple() {
            this.loading = true
            this.$http.post('/9api/projects-task/flow-suspend-multiple', { ids: this.$refs.xTable.getCheckboxRecords().map(item => item.id)}).then(rps => {
                this.Search()
            })
        },

        TaskFlowCancelMultiple() {
            this.loading = true
            this.$http.post('/9api/projects-task/flow-cancel-multiple', { ids: this.$refs.xTable.getCheckboxRecords().map(item => item.id)}).then(rps => {
                this.Search()
            })
        },

        TaskFlowCloseMultiple() {
            this.loading = true
            this.$http.post('/9api/projects-task/flow-close-multiple', { ids: this.$refs.xTable.getCheckboxRecords().map(item => item.id)}).then(rps => {
                this.Search()
            })
        }
    }
}
</script>

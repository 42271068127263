<!---------------------------------------------------------------------------- 
 | statistic 统计数值
 |----------------------------------------------------------------------------
 | 参数说明
 | column => Number => 列数
 | gutter => String => 间隔大小
 ---------------------------------------------------------------------------->

 <template>
    <div>

        <div :style="{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }">

            <!-- search -->
            <div :style="{ display: 'flex' }">
                <slot name="full"></slot>
                <el-button v-if="$slots.full" type="primary" size="mini" icon="el-icon-search" plain :style="{ marginLeft: '10px' }" @click="expand = !expand">高级检索</el-button>
            </div>

            <!-- tools -->
            <div>
                <slot name="tools">
                </slot>
            </div>
        </div>

        <!-- 高级检索 -->
        <div class="uw-grid-search-form" v-show="expand">
            <!-- 表单 -->
            <el-form size="mini" label-position="top">
                <div :style="{ display: 'grid', gridTemplateColumns: 'repeat(' + column + ', 1fr)', gap: gutter }">
                    <slot></slot>
                </div>
            </el-form>
        </div>

    </div>
</template>

<script>
export default {
    props: {
        column: {
            type: Number,
            default: 2,
        },

        gutter: {
            type: String,
            default: '10px 10px',
        },

        expand: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="less" scoped>
    .uw-grid-search-form {
        background: #f5f5f5;
        border-radius: 4px;
        padding: 5px 20px;
        margin-top: 10px;
    }
</style>
<template>
    <el-dialog :visible.sync="visible" width="450px" append-to-body title="修改" :close-on-click-modal="false" :close-on-press-escape="false" :before-close="Close">
        
        <!-- 表单 -->
        <el-form :model="form" label-position="top">
            
            <!-- 名称 -->
            <el-form-item label="名称" :rules="[{ required: true, message: '必填项' }]" :error="formError.name?.toString()" prop="name">
                <el-input v-model="form.name" clearable />
            </el-form-item>

            <!-- 计划日期 -->
            <el-form-item label="计划日期" :rules="[{ required: true, message: '必填项' }]" :error="formError.date?.toString()" prop="date">
                <el-date-picker
                    :style="{ width: '100%' }"
                    v-model="form.date"
                    type="daterange"
                    value-format="yyyy-MM-dd"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期" />
            </el-form-item>

            <!-- 优先级 -->
            <el-form-item label="优先级">
                <el-input-number v-model="form.sort" :precision="0" :controls="false" :style="{ width: '100%' }"></el-input-number>
            </el-form-item>

        </el-form>
        

        <span slot="footer">
            <el-button @click="Close()">取 消</el-button>
            <el-button type="primary" @click="SubmitForm()" :loading="loading">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>
import { watch } from 'less';

export default {
    data () {
        return {
            // 加载状态
            visible: false,
            loading: false,

            formError: {},
            form: {
                id: null,
                projects_id: null,
                name: null,
                sort: null,
                date: null,
            }
        }
    },

    methods: {

        // 窗口 - 打开
        Open (id) {
            this.$http.post('/9api/pm/milestone/search-first', { search: { id: id } }).then(rps => {
                this.form = rps.data
                this.visible = true
            })
        },

        // 窗口 - 关闭
        Close() {
            this.visible = false
        },

        // 提交表单
        SubmitForm() {
            this.formError = {}

            // 提交表单
            this.$http.post('/9api/pm/milestone/update', this.form).then(rps => {
                if (rps.data.state == 'error') {
                    this.formError = rps.data.body
                }
                if (rps.data.state == 'success') {
                    this.$emit('onChange', rps.data.body)
                    this.Close()
                }
            })
        }
    }  
}
</script>
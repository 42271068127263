<template>
    <uw-page title="附件">
        
        <!-- 功能 -->
        <template slot="header-suffix">
            <el-button type="primary" @click="dialogVisible = true">上传附件</el-button>
        </template>

        <!-- 数据表 -->
        <files-table v-model="order.files_id" />

        <!-- 附件上传 -->
        <el-dialog
            width="700px"
            append-to-body
            destroy-on-close
            title="附件上传"
            :visible.sync="dialogVisible"
            :close-on-click-modal="false"
            :close-on-press-escape="false">

            <files-upload v-model="upload_files" />

            <template slot="footer">
                <el-button :loading="$store.state.loading" @click="dialogVisible = false">关 闭</el-button>
                <el-button :loading="$store.state.loading" type="primary" @click="Submit()">提交</el-button>
            </template>

        </el-dialog>

    </uw-page>
</template>

<script>
export default {
    props: [ 'id' ],

    data () {
        return {
            dialogVisible: false,
            upload_files: [],

            order: {
                id: null,
                files_id: [],
                created_user: {},
                updated_user: {},
                product_order_log: []
            }
        }
    },
    
    watch: {
        id: {
            handler(val) {
                if(val) {
                    this.Search()
                }
            },
            immediate: true
        }
    },

    methods: {

        // 合同 - 查询
        Search()
        {
            this.$http.post('/9api/ims/product-order/search-first', { search: { id: this.id } }).then(rps => {
                this.order = rps.data
            })
        },

        Submit() {
            this.$http.post('/9api/ims/product-order/upload-files', {id: this.id, files_id: this.upload_files}).then(rps => {
                this.upload_files = []
                this.dialogVisible = false
                this.Search()
            })
        },
    }
}
</script>
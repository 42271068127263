<template>
    <uw-page title="回款计划">
        
        <!-- 功能 -->
        <template slot="header-suffix">
            <el-button type="primary" @click="$refs.payPlanCreate.Open({ contract_id: contract_id })">新建回款计划</el-button>
            <el-button type="primary" @click="$refs.xTable.openExport()">导出</el-button>
            <el-button type="primary" @click="$refs.xTable.openPrint()">打印</el-button>
        </template>

        <!-- 数据表 -->
        <vxe-table
            ref="xTable"
            show-footer
            :data="table"
            :export-config="{}"
            :print-config="{}"
            :footer-method="tableFooterMethod"
            height="100%">

            <!-- 序号 -->
            <vxe-column width="60" type="seq" title="序号"></vxe-column>

            <!-- 回款金额 -->
            <vxe-column width="120" field="pay_money" title="回款金额">
                <template #default="{ row }">
                    <el-link type="primary">
                        ￥{{ row.pay_money.toLocaleString() }}
                    </el-link>
                </template>
            </vxe-column>

            <!-- 冲销金额 -->
            <vxe-column width="120" field="pay_money_offset" title="冲销金额">
                <template #default="{ row }">
                    <el-link type="primary">
                        ￥{{ row.pay_money_offset.toLocaleString() }}
                    </el-link>
                </template>
            </vxe-column>

            <!-- 回款负责人 -->
            <vxe-column width="80" field="user.name" title="回款负责人"></vxe-column>

            <!-- 回款状态 -->
            <vxe-column width="80" field="pay_state" title="回款状态">
                <template #default="{ row }">
                    <el-link v-if="row.pay_state == 0" type="warning">未回款</el-link>
                    <el-link v-if="row.pay_state == 1" type="success">已回款</el-link>
                </template>
            </vxe-column>

            <!-- 开具发票 -->
            <vxe-column width="100" field="invoice_state" title="开具发票">
                <template #default="{ row }">
                    <el-link v-if="row.invoice_state == 0" type="warning">未开具发票</el-link>
                    <el-link v-if="row.invoice_state == 1" type="success">已开具发票</el-link>
                    <el-link v-if="row.invoice_state == 2" type="info">无需开具发票</el-link>
                </template>
            </vxe-column>

            <!-- 计划回款日期 -->
            <vxe-column width="120" field="pay_date_plan" title="计划回款日期"></vxe-column>

            <!-- 实际回款日期 -->
            <vxe-column width="120" field="pay_date_fact" title="实际回款日期">
                <template #default="{ row }">
                    <el-link v-if="row.pay_state == 0 && row.pay_date_fact < 0" icon="el-icon-warning-outline" type="danger" :underline="false">超期 {{ -row.pay_date_fact }} 天</el-link>
                    <el-link v-if="row.pay_state == 0 && row.pay_date_fact > -1" icon="el-icon-bell" type="primary" :underline="false">剩余 {{ row.pay_date_fact }} 天</el-link>
                    <el-link v-if="row.pay_state == 1" icon="el-icon-date" type="info" :underline="false">{{ row.pay_date_fact }}</el-link>
                </template>
            </vxe-column>

            <!-- 计划回款描述 -->
            <vxe-column min-width="200" field="describe" title="计划回款描述"></vxe-column>

            <vxe-column width="140" title="操作" fixed="right" align="center">
                <template #default="{ row }">
                    <el-link @click="$refs.payPlanUpdate.Open(row.id)" icon="el-icon-edit-outline" type="primary" :underline="false">编辑</el-link>
                    <el-link @click="TableDelete(row.id)" icon="el-icon-delete" type="danger" :underline="false" class="w-margin-l-10">删除</el-link>
                </template>
            </vxe-column>
        </vxe-table>

        <!-- 组件 -->
        <crm-contract-pay-plan-create ref="payPlanCreate" @success="TableSearch" />
        <crm-contract-pay-plan-update ref="payPlanUpdate" @success="TableSearch" />

    </uw-page>
</template>

<script>
export default {
    props: [ 'contract_id' ],

    data () {
        return {
            loading: false,
            
            table: [],
            statistics: {
                pay_money: 0,
                pay_money_offset: 0
            },
        }
    },
    
    watch: {
        contract_id: {
            handler(val) {
                if(val) {
                    this.TableSearch()
                }
            },
            immediate: true
        }
    },

    methods: {

        /**
        |--------------------------------------------------
        | 数据查询
        |--------------------------------------------------
        |
        */

        TableSearch() {
            this.loading = true
            this.$http.post(
                '/9api/contract-pay-plan/search-all',
                {
                    search: { contract_id: this.contract_id },
                    statistics: true,
                    append: ['user']
                }
            ).then((rps) => {
                // 更新数据
                this.table = rps.data.body
                this.statistics = rps.data.statistics

                // 切换加载状态
                this.loading = false
            })
        },

        /**
        |--------------------------------------------------
        | 表尾数据
        |--------------------------------------------------
        |
        */

        tableFooterMethod() {
            return [
                ['合计', '￥' + this.statistics.pay_money.toLocaleString(), '￥' + this.statistics.pay_money_offset.toLocaleString()]
            ]
        },

        /**
        |--------------------------------------------------
        | 数据移除
        |--------------------------------------------------
        |
        */

        TableDelete(id) {
            this.loading = true
            this.$http.post('/9api/contract-pay-plan/delete', { id: id  }).then((rps) => {
                this.TableSearch()
            })
        },
    }
}
</script>
<template>
    <uw-page title="入库记录">

        <!-- 待入库产品 -->
        <el-divider content-position="left"><b>待入库产品</b></el-divider>
        <el-button type="primary" size="mini" @click="$refs.warehouseDocumentCreateEnter.Open({ model: model, model_id: id })" >入库</el-button>
        <vxe-table border ref="xTable" :data="productOrderLog" :loading="loading" :style="{ marginTop: '10px' }">
            <vxe-column min-width="120" field="product.name" title="产品名称"></vxe-column>
            <vxe-column min-width="80" field="product.unit" title="计量单位"></vxe-column>
            <vxe-column min-width="100" title="规格参数">
                <template #default="{ row }">
                    {{ row.product.parameter?.toString() }}
                </template>
            </vxe-column>
            <vxe-column min-width="80" field="num" title="采购数量"></vxe-column>
            <vxe-column min-width="90" field="storage_num" title="已入库"></vxe-column>
            <vxe-column min-width="70" title="待入库">
                <template #default="{ row }">
                    {{ row.num - row.storage_num  }}
                </template>
            </vxe-column>
        </vxe-table>

        <!-- 入库记录 -->
        <el-divider content-position="left"><b>入库记录</b></el-divider>
        <el-empty v-if="warehouseEnter.filter(rps => rps.state == 1) == 0" description="暂无记录"></el-empty>
        <div v-for="(item, idx) in warehouseEnter.filter(rps => rps.state == 1)" :key="'enter-' + idx" :style="{ marginBottom: '20px' }">
            <div :style="{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '14px' }">
                <div>
                    <span :style="{ marginRight: '10px' }">入库单号：<span class="el-span-primary">{{ item.number }}</span></span>
                    <span :style="{ marginRight: '10px' }">入库时间：{{ item.created_at }}</span>
                    <span :style="{ marginRight: '10px' }">负责人：{{ item.created_user.name }}</span>
                </div>
                <el-button type="danger" size="mini" @click="DeleteEnter(item.id)" >作废</el-button>
            </div>
            <vxe-table border ref="xTable" :data="item.warehouse_log" :loading="loading" :style="{ marginTop: '10px' }">
                <vxe-column min-width="120" field="product.name" title="产品名称"></vxe-column>
                <vxe-column min-width="80" field="product.unit" title="计量单位"></vxe-column>
                <vxe-column min-width="100" title="规格参数">
                    <template #default="{ row }">
                        {{ row.product.parameter?.toString() }}
                    </template>
                </vxe-column>
                <vxe-column min-width="80" field="num" title="本次入库"></vxe-column>
                <vxe-column min-width="80" field="warehouse.name" title="入库仓库"></vxe-column>
                <vxe-column min-width="90" field="describe" title="备注"></vxe-column>
            </vxe-table>
            <div :style="{ fontSize: '14px', marginTop: '5px' }" v-if="item.describe">备注：{{ item.describe }}</div>
        </div>

        <!-- 作废记录 -->
        <el-divider content-position="left"><b>作废记录</b></el-divider>
        <el-empty v-if="warehouseEnter.filter(rps => rps.state == 0).length == 0" description="暂无记录"></el-empty>
        <div v-for="(item, idx) in warehouseEnter.filter(rps => rps.state == 0)" :key="'delete-' + idx" :style="{ marginBottom: '20px' }">
            <div :style="{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '14px' }">
                <div>
                    <span :style="{ marginRight: '10px' }">入库单号：<span class="el-span-primary">{{ item.number }}</span></span>
                    <span :style="{ marginRight: '10px' }">入库时间：{{ item.created_at }}</span>
                    <span :style="{ marginRight: '10px' }">负责人：{{ item.created_user.name }}</span>
                </div>
            </div>
            <vxe-table border ref="xTable" :data="item.warehouse_log" :loading="loading" :style="{ marginTop: '10px' }">
                <vxe-column min-width="120" field="product.name" title="产品名称"></vxe-column>
                <vxe-column min-width="80" field="product.unit" title="计量单位"></vxe-column>
                <vxe-column min-width="100" title="规格参数">
                    <template #default="{ row }">
                        {{ row.product.parameter?.toString() }}
                    </template>
                </vxe-column>
                <vxe-column min-width="80" field="num" title="本次入库"></vxe-column>
                <vxe-column min-width="80" field="warehouse.name" title="入库仓库"></vxe-column>
                <vxe-column min-width="90" field="describe" title="备注"></vxe-column>
            </vxe-table>
            <div :style="{ fontSize: '14px', marginTop: '5px' }" v-if="item.describe">备注：{{ item.describe }}</div>
        </div>

        <!-- 组件 -->
        <ims-warehouse-document-create-enter ref="warehouseDocumentCreateEnter" @onChange="TableSearch" />

    </uw-page>
</template>

<script>
export default {
    props: {
        id: {
            type: Number,
            default: null
        },
        model: {
            type: String,
            default: 'ims_product_order'
        }
    },

    data() {
        return {
            loading: false,

            productOrderLog: [],
            warehouseEnter: []
        }
    },

    watch: {
        id: {
            handler(val) {
                this.TableSearch()
            },
            immediate: true
        }
    },
    
    methods: {

        // 订单记录
        TableSearch() {
            this.$http.post('/9api/ims/product-order-log/search-all', { search: { product_order_id: this.id, state: 1 }, append: ['product'] }).then(rps => {
                this.productOrderLog = rps.data
                this.loading = false
            })
            this.$http.post('/9api/ims/warehouse-document/search-all', { search: { model: 'ims_product_order', model_id: this.id, action: 1 }, append: ['warehouse_log', 'created_user'] }).then(rps => {
                this.warehouseEnter = rps.data
                this.loading = false
            })
        },

        // 作废入库单
        DeleteEnter(id) {
            this.$confirm('确认需要作废此入库单吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'error'
            }).then(() => {
                this.$http.post('/9api/ims/warehouse-document/update-delete', { id: id }).then(rps => {
                    this.TableSearch()
                })
            })

            
        }
    }
}
</script>
<template>
    <uw-page title="里程碑 / 项目阶段">

        <!-- 功能 -->
        <template slot="header-suffix">
            <el-button type="primary" @click="$refs.drawerCreate.Open({ projects_id: projects_id })">新的里程碑 / 新的项目阶段</el-button>
        </template>

        <!-- 列表 -->
        <vxe-table :data="table" height="100%">

            <!-- 阶段 -->
            <vxe-column width="60" type="seq" title="阶段"></vxe-column>
            <vxe-column min-width="200" field="name" title="名称">
                <template #default="{ row }">
                    <el-span type="primary" :title="row.name" @click.native="$emit('onPage', { link_page: 'pm-projects-drawer-task', link_query: { milestone_id: row.id } })" />
                </template>
            </vxe-column>

            <!-- 进度 -->
            <vxe-column width="100" title="进度">
                <template #default="{ row }">
                    <div :style="{ display: 'flex', alignItems: 'center' }">
                        <el-progress :color="progressColors" :show-text="false" type="circle" :width="20" :percentage="Math.floor(row.statistics.task.ygb/row.statistics.task.count*100) || 0" :stroke-width="2"></el-progress>
                        <el-link :style="{ marginLeft: '5px', justifyContent: 'flex-start' }" :underline="false">
                            {{ Math.floor(row.statistics.task.ygb/row.statistics.task.count*100) || 0 }} %
                        </el-link>
                    </div>
                </template>
            </vxe-column>

            <!-- 已关闭任务 -->
            <vxe-column width="90" title="已关闭任务">
                <template #default="{ row }">
                    <el-link :underline="false" icon="el-icon-tickets">
                        <b>{{ row.statistics.task.ygb }} / </b>
                        <span :style="{ color: '#9e9e9e' }">{{ row.statistics.task.count }}</span>
                    </el-link>
                </template>
            </vxe-column>

            <!-- 已关闭工单 -->
            <vxe-column width="90" title="已关闭工单">
                <template #default="{ row }">
                    <el-link :underline="false" icon="el-icon-tickets">
                        <b>{{ row.statistics.wo.ygb }} / </b>
                        <span :style="{ color: '#9e9e9e' }">{{ row.statistics.wo.count }}</span>
                    </el-link>
                </template>
            </vxe-column>

            <!-- P值统计 -->
            <vxe-column width="100" field="statistics.p.task" title="任务消耗/P"></vxe-column>
            <vxe-column width="100" field="statistics.p.wo" title="工单消耗/P"></vxe-column>
            <vxe-column width="100" title="小计/P">
                <template #default="{ row }">
                    {{ Number(row.statistics.p.task) + Number(row.statistics.p.wo) }}
                </template>
            </vxe-column>

            <!-- 计划时间 -->
            <vxe-column width="180" title="计划日期">
                <template #default="{ row }">
                    {{ row.date_start ?? '-' }} 至 {{ row.date_end ?? '-' }}
                </template>
            </vxe-column>

            <!-- 创建人 -->
            <vxe-column width="120" title="创建人">
                <template #default="{ row }">
                    <div style="display: flex; align-items: center; justify-content: flex-start;">
                        <el-avatar style="margin-right: 5px;" :size="26" :src="row.created_users?.avatar"> {{ row.created_users?.name[0] }} </el-avatar>
                        {{  row.created_users?.name }}
                    </div>
                </template>
            </vxe-column>

            <!-- 创建时间 -->
            <vxe-column width="140" field="created_at" title="创建时间" sortable></vxe-column>

            <vxe-column width="140" title="操作" fixed="right" align="center">
                <template #default="{ row }">
                    <el-link @click="$refs.drawerUpdate.Open(row.id)" icon="el-icon-edit-outline" type="primary" :underline="false">编辑</el-link>
                    <el-link @click="Delete(row.id)" icon="el-icon-delete" type="danger" :underline="false" class="w-margin-l-10">删除</el-link>
                </template>
            </vxe-column>
        </vxe-table>

        <!-- 组件 -->
        <pm-milestone-create ref="drawerCreate" @onChange="SearchAll" />
        <pm-milestone-update ref="drawerUpdate" @onChange="SearchAll" />

    </uw-page>
</template>

<script>
export default {
    props: {
        projects_id: {
            type: Number,
            default: null
        }
    },

    data() {
        return {
            table: [],

            progressColors: [
                {color: '#ff1100', percentage: 20},
                {color: '#ff3d00', percentage: 40},
                {color: '#ff9800', percentage: 60},
                {color: '#ffbf00', percentage: 80},
                {color: '#89ff00', percentage: 90},
                {color: '#00b0ff', percentage: 99},
                {color: '#0027ff', percentage: 100}
            ],
        }
    },

    watch: {
        projects_id: {
            handler (val) {
                if (val) {
                    this.SearchAll()
                }
            },
            immediate: true
        }
    },

    methods: {

        SearchAll() {
            this.$http.post(
                '/9api/pm/milestone/search-all',
                {
                    search: { projects_id: this.projects_id },
                    append: ['created_users', 'statistics']
                }
            ).then(rps => {
                this.table = rps.data
            })
        },

        Delete(id) {
            this.$http.post('/9api/pm/milestone/delete', { id: id }).then(rps => {
                this.SearchAll()
            })
        }
    }
}
</script>
<!---------------------------------------------------------
-----------------------------------------------------------
    - ！组件说明
    - 组件为抽屉形态，从又向左滑动，
    - 请通过 "refs" 调用 "事件" 打开窗口
    - 请注意大小写
    
    - ！事件说明
    - function Open 打开窗口
    ----------------------
    (必填):id = 项目ID
-----------------------------------------------------------
---------------------------------------------------------->

<template>
    <uw-page title="合同描述">
        
        <!-- 功能 -->
        <template slot="header-suffix">
            <el-button type="primary" @click="$refs.drawerUpdate.Open({ id: contract.id, describe: contract.describe })">修改描述</el-button>
        </template>
            
        <!-- 数据表 -->
        <froala-views v-model="contract.describe" />

        <!-- 组件 -->
        <crm-contract-update-describe ref="drawerUpdate" @onChange="TableSearch" />
        
    </uw-page>
</template>
    
<script>
export default {
    props: [ 'contract_id' ],

    data() {
        return {
            contract: {}
        }
    },

    watch: {
        contract_id: {
            handler(val) {
                if(val) {
                    this.TableSearch()
                }
            },
            immediate: true
        }
    },
    
    methods: {
        TableSearch() {
            this.$http.post('/9api/crm/contract/search-first', { id: this.contract_id }).then(async rps => {
                this.contract = rps.data
            })
        },
    }
}
</script>

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// iconfont 新
import './assets/icon/icon.less'
import './assets/icon/food.js'

// 公共方法(定制方法)
import lodash from '@/assets/js/lodash.js'
Vue.prototype.$lodash = lodash

// 全局组件(定制组件)
import '@/assets/js/componentRegister.js'
import '@/assets/js/http.js'

// 前端组件 - ElementUI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI, { size: 'mini' })

/**
 * 组件 - VXETable
 * 表格
 */
import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
VXETable.setup({
    size: 'mini',
    zIndex: 10000,
    table: {
        stripe: true,
        border: true,
        autoResize: true,
        showOverflow: true,
        rowConfig: {isHover: true},
        columnConfig: {isHover: true, resizable: true},
        printConfig: {},
        exportConfig: {},
        filterConfig: {showIcon: false},
        treeConfig: { reserve: true },
        sortConfig: { remote: true }
    },
    pager: {
        layouts: ['Total', 'Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump'],
        pageSizes: [10, 15, 20, 50, 100, 200, 500, 1000],
    },
    toolbar: {
        export: {
            types: ['csv', 'html', 'xml', 'txt']
        },
    }
})
Vue.use(VXETable)

/**
 * 全局css
 */
import '@/assets/css/element.less';
import '@/assets/css/element-w-dialog.less';
import '@/assets/css/froala.less';
import '@/assets/css/css.less';
import '@/assets/css/uw.less';

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')

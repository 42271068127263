<template>
    <span @click="$emit('onClick')" :class="['el-span', type]">
        {{ title }}
    </span>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            default: 'info'
        },
        title: {
            type: String,
            default: null
        }
    }
}
</script>
<template>
    <uw-page split split-width="280px" title="文档库">
        
        <!-- 分类 -->
        <template slot="header-prefix">
            <el-tag
                v-if="search.ids"
                size="medium"
                closable
                type="danger"
                @close="search.ids = null" >
                筛选文件数量: {{ search.ids?.length }}
            </el-tag>
        </template>

        <!-- 功能 -->
        <template slot="header-suffix">
            <el-button-group>
                <el-button @click="grid = true" :type="grid ? 'primary' : ''" icon="el-icon-s-grid">宫格</el-button>
                <el-button @click="grid = false" :type="grid ? '' : 'primary'" icon="el-icon-tickets">列表</el-button>
            </el-button-group>
        </template>

        <!-- 卡片列表 -->
        <div class="c-files-grid" v-show="grid && !previewVisible" ref="c-files-grid">
            <div class="c-files-grid-item" v-for="(v, k) in table" :key="k">
                
                <img class="c-files-grid-img"
                    v-if="['png', 'jpeg'].includes(v.format)"
                    :src="'/9api/components-files/preview?id=' + v.id + '&path=' +  v.path" />

                <img class="c-files-grid-format"
                    v-else
                    :src="'/img/files/' + v.format + '.png'" />
                
                <div class="c-files-grid-title">{{ v.name }}</div>
                <div class="c-files-grid-buttons">
                    <a @click="filesPreview(v)"><i class="el-icon-search"></i></a>
                    <a @click="filesDown(v)"><i class="el-icon-download"></i></a>
                </div>
                <div class="c-files-grid-footer">
                    <img class="c-files-grid-avatar" :src="v.users?.avatar" />
                    <div>
                        <div>{{ v.created_at }}</div>
                        <div>{{ v.users?.name }}</div>
                    </div>
                </div>

            </div>
        </div>

        <!-- 表格列表 -->
        <vxe-table :data="table" height="100%" v-show="!grid && !previewVisible">

            <!-- 序号 -->
            <vxe-column width="60" type="seq" title="序号" />

            <!-- 附件名称 -->
            <vxe-column min-width="300px" title="附件名称">
                <template #default="{ row }">
                    <div :style="{ display: 'flex' }">
                        <img :style="{ width: '20px', height: '20px', marginRight: '5px' }" :src="'/img/files/' + row.format + '.png'" />
                        <div :style="{ flex: '1 0 0', width: '0', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }">{{ row.name }}</div>
                    </div>
                </template>
            </vxe-column>

            <!-- 上传者 -->
            <vxe-column width="120" title="上传者">
                <template #default="{ row }">
                    <div style="display: flex; align-items: center; justify-content: flex-start;">
                        <el-avatar style="margin-right: 5px;" :size="26" :src="row.users?.avatar">{{ row.users?.name[0] }}</el-avatar>
                        {{  row.users?.name }}
                    </div>
                </template>
            </vxe-column>

            <!-- 上传日期 -->
            <vxe-column width="140" field="created_at" title="上传日期" />

            <vxe-column width="90" title="操作" fixed="right" align="center">
                <template #default="{ row }">
                    <el-link :underline="false" type="primary" @click="filesPreview(row)" :style="{ marginRight: '10px' }">预览</el-link>
                    <el-link :underline="false" type="primary" @click="filesDown(row)">下载</el-link>
                </template>
            </vxe-column>

        </vxe-table>

        <!-- 预览 -->
        <div class="c-iframe" v-if="previewVisible">
            <div class="c-iframe-header">
                <div :style="{ display: 'flex', flex: '1 0 0', width: '0', alignItems: 'center' }">
                    <img :style="{ width: '20px', height: '20px', marginRight: '5px' }" :src="'/img/files/' + previewFiles.format + '.png'" />
                    <div :style="{ flex: '1 0 0', width: '0', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }">{{ previewFiles.name }}</div>
                </div>
                <div class="c-iframe-buttons">
                    <a @click="previewVisible = false"><i class="el-icon-close"></i></a>
                </div>
            </div>
            <div class="c-iframe-body">
                <iframe v-if="['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'csv' ].includes(previewFiles.format)" :src="previewUrl" width="100%" height="100%" frameborder="no"></iframe>
                <img v-else :src="previewUrl">
            </div>
        </div>

        <!-- 下载 -->
        <iframe :src="downUrl" width="0" height="0" v-show="false"></iframe>

        <template slot="split">
            <div class="c-list">
                <div class="c-list-item" v-for="(v, k) in list" :key="k" @click="search.ids = v.files_id">
                    <div :style="{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }">
                        <el-tag effect="dark" :type="v.number ? 'warning' : ''">
                            {{ v.number ? '工单' : '任务' }}
                            {{ v.number }}
                        </el-tag>
                        <div :style="{ display: 'flex', alignItems: 'center' }">
                            <strong :style="{ fontSize: '12px' }">{{ v.files_id.length }}</strong>
                            <img :style="{ width: '20px', height: '20px', marginLeft: '5px' }" src="/img/files/diropen.png">
                        </div>
                    </div>
                    <div :style="{ marginBottom: '5px', fontSize: '12px' }">{{ v.name }}</div>
                    <div v-if="!v.number">
                        <el-tag :style="{ marginRight: '5px' }" v-if="v.p == 1" effect="plain" type="danger">P1</el-tag>
                        <el-tag :style="{ marginRight: '5px' }" v-if="v.p == 2" effect="plain" type="warning">P2</el-tag>
                        <el-tag :style="{ marginRight: '5px' }" v-if="v.p == 3" effect="plain" type="primary">P3</el-tag>
                        <el-tag :style="{ marginRight: '5px' }" v-if="v.p == 4" effect="plain" type="success">P4</el-tag>
                        <el-tag :style="{ marginRight: '5px' }" v-if="v.status == 0" effect="plain" type="primary">未开始</el-tag>
                        <el-tag :style="{ marginRight: '5px' }" v-if="v.status == 1" effect="plain" type="primary">进行中</el-tag>
                        <el-tag :style="{ marginRight: '5px' }" v-if="v.status == 2" effect="plain" type="warning">已暂停</el-tag>
                        <el-tag :style="{ marginRight: '5px' }" v-if="v.status == 4" effect="plain" type="info">已关闭</el-tag>
                        <el-tag :style="{ marginRight: '5px' }" v-if="v.status == 5" effect="plain" type="success">已完成</el-tag>
                        <el-tag :style="{ marginRight: '5px' }" v-if="v.status == 6" effect="plain" type="info">已取消</el-tag>
                        <el-tag v-if="v.is_overdue == 1" effect="dark" type="danger">已延期</el-tag>
                    </div>
                    <div v-if="v.number">
                        <el-tag class="w-margin-r-5" size="mini" effect="plain" type="warning" v-if="v.state == 0">待确认</el-tag>
                        <el-tag class="w-margin-r-5" size="mini" effect="plain" v-if="v.state == 1">进行中</el-tag>
                        <el-tag class="w-margin-r-5" size="mini" effect="plain" type="success" v-if="v.state == 2">已完成</el-tag>
                        <el-tag class="w-margin-r-5" size="mini" effect="plain" type="info" v-if="v.state == 3">已关闭</el-tag>
                        <el-tag class="w-margin-r-5" size="mini" effect="plain" type="warning" v-if="v.state == 4">已暂停</el-tag>
                        <el-tag class="w-margin-r-5" size="mini" effect="plain" type="warning" v-if="v.state == 5">已取消</el-tag>
                        <el-tag class="w-margin-r-5" size="mini" effect="plain" type="danger" v-if="v.is_overdue">已逾期</el-tag>
                        <el-tag class="w-margin-r-5" size="mini" effect="plain" v-if="v.projects_id">项</el-tag>
                        <el-tag class="w-margin-r-5" size="mini" effect="plain" v-if="v.projects_task_id" type="warning">任</el-tag>
                    </div>
                </div>
            </div>
        </template>

    </uw-page>
</template>

<script>
export default {
    props: {
        projects_id: {
            type: [String, Number],
            default: null
        }
    },

    data() {
        return {
            loading: false,

            table: [],
            list: [],

            search: {
                ids: null,
            },

            grid: true,
            gridWidth: 0,
            
            previewVisible: false,
            previewUrl: null,
            previewFiles: {},

            downUrl: null
        }
    },

    watch: {
        projects_id: {
            handler(val) {
                val ? this.tableSearch() : this.table = []
            },
            immediate: true
        },
        'search.ids': {
            handler() {
                this.tableSearch()
            }
        }
    },

    mounted() {
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                entry.target.style.gridTemplateColumns = 'repeat(' + Math.floor(entry.contentRect.width / 200) + ', 1fr)'
            }
        })
        resizeObserver.observe(this.$refs['c-files-grid'])
    },

    methods: {
        tableSearch() {
            this.loading = true
            this.$http.post('/9api/pm/projects-files/search-all', { search: { projects_id: this.projects_id, ...this.search }, append: ['users'] }).then((rps) => {
                this.table = rps.data.files
                this.list = rps.data.list
                this.loading = false
            })
        },

        filesPreview(files) {
            // Office文件预览
            if (['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'csv' ].includes(files.format)) {
                this.previewVisible = true
                this.previewUrl = 'https://view.officeapps.live.com/op/view.aspx?src=' + encodeURIComponent('https://aqyy.waner.cn/9api/components-files/preview?id=' + files.id + '&path=' +  files.path)
            } else {
                this.previewVisible = true
                this.previewUrl = '/9api/components-files/preview?id=' + files.id + '&path=' +  files.path
            }
            this.previewFiles = files
        },

        filesDown(files) {
            this.downUrl = '/9api/components-files/down?id=' + files.id + '&path=' +  files.path
        }
    }
}
</script>

<style lang="less" scoped>
    .c-files-grid { display: grid; grid-template-columns: repeat(4, 1fr); gap: 10px; }
    .c-files-grid-item { cursor: pointer; width: 100%; padding-bottom: 100%; position: relative; overflow: hidden; }
    .c-files-grid-item { transition: 300ms; border: 1px solid #eeeeee; background: #444444; border-radius: 5px; }
    .c-files-grid-img { transition: 300ms; position: absolute; object-fit: contain; width: 100%; height: 100%; }
    .c-files-grid-format { transition: 300ms; position: absolute; object-fit: contain; width: 100%; height: 100%; padding: 30%; box-sizing: border-box; }
    .c-files-grid-title { position: absolute; top: 0; left: 0; width: 100%; box-sizing: border-box; padding: 10px 10px 20px 10px; }
    .c-files-grid-title { color: #FFFFFF; font-size: 12px; }
    .c-files-grid-title { background: linear-gradient(to bottom, #000000, #FFFFFF00); }
    .c-files-grid-title { text-shadow: 0px 0px 24px #000000;}
    .c-files-grid-footer { position: absolute; bottom: 0; left: 0; width: 100%; box-sizing: border-box; padding: 10px; }
    .c-files-grid-footer { display: flex; align-items: center; }
    .c-files-grid-footer { background: #000000bf; }
    .c-files-grid-footer { color: #FFFFFF; font-size: 12px;}
    .c-files-grid-avatar { margin-right: 10px; height: 32px; width: 32px; border-radius: 16px; border: 1px solid #FFFFFF; }
    .c-files-grid-buttons { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
    .c-files-grid-buttons { transition: 300ms; display: flex; align-items: center; justify-content: center; opacity: 0; }
    .c-files-grid-buttons a { transition: 300ms; margin: 0 5px; color: #FFFFFF; background: #00000080; border-radius: 20px; }
    .c-files-grid-buttons a { display: block; width: 40px; height: 40px; line-height: 40px; text-align: center; font-size: 22px; }
    .c-files-grid-item:hover { box-shadow: 0px 0px 10px 0px #0027ff; }
    .c-files-grid-item:hover .c-files-grid-img { width: 110%; }
    .c-files-grid-item:hover .c-files-grid-buttons { opacity: 1; }
    .c-files-grid-buttons a:hover { background: #2196f3cc; }
    .c-iframe { width: 100%; height: 100%; }
    .c-iframe-header { height: 40px; background-color: #444444; display: flex; align-items: center; padding: 0 10px; color: #FFFFFF; font-size: 12px; border-bottom: 1px solid #616161; }
    .c-iframe-buttons a { transition: 300ms; margin-left: 10px; display: block; cursor: pointer; padding: 10px; font-size: 18px; }
    .c-iframe-buttons a:hover { font-weight: bold; }
    .c-iframe-body { height: calc(100% - 40px); width: 100%; background: #444444; }
    .c-iframe-body iframe { background: #444444; }
    .c-iframe-body img { width: 100%; height: 100%; object-fit: contain; }
    .c-list { width: 100%; height: 100%; padding: 10px; overflow: auto; box-sizing: border-box; }
    .c-list-item { transition: 300ms; border: 1px solid #e0e0e0; margin-bottom: 10px; padding: 10px; border-radius: 5px; cursor: pointer; }
    .c-list-item:hover { box-shadow: 0px 0px 6px -2px #0027ff; border-color: #0027ff; }
    .c-list-item.is-active { box-shadow: 0px 0px 6px -2px #0027ff; border-color: #0027ff; }
</style>
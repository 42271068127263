<template>
    <uw-page title="工单报障">

        <!-- 功能 -->
        <template slot="header-suffix">
            <el-button type="primary" @click="$refs.drawerCreate.Open({ projects_id: projects_id })">新建工单</el-button>
            <el-button type="primary" @click="UpdateUserReceive()">批量转交</el-button>
            <el-button type="primary" @click="$refs.xTable.print()" >打印</el-button>
            <el-button type="primary" @click="$refs.xTable.openExport()" >导出</el-button>
        </template>

        <!-- 检索 -->
        <template slot="search">
            <el-form-item label="工单状态">
                <el-select size="mini" v-model="search.state" clearable @change="TableSearch" :style="{ width: '100%' }"> 
                    <el-option :value="0" label="待确认"></el-option>
                    <el-option :value="1" label="进行中"></el-option>
                    <el-option :value="2" label="已完成"></el-option>
                    <el-option :value="3" label="已关闭"></el-option>
                    <el-option :value="4" label="已暂停"></el-option>
                    <el-option :value="5" label="已取消"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="工单单号">
                <el-input @input="TableSearch" v-model="search.number" clearable placeholder="工单单号"></el-input>
            </el-form-item>
            <el-form-item label="工单名称">
                <el-input @input="TableSearch" v-model="search.name" clearable placeholder="工单名称"></el-input>
            </el-form-item>
            <el-form-item label="收单人">
                <users-select v-model="search.user_receive_id" @onChange="TableSearch" placeholder="收单人" />
            </el-form-item>
            <el-form-item label="派单人">
                <users-select v-model="search.user_sending_id" @onChange="TableSearch" placeholder="派单人" />
            </el-form-item>
            <el-form-item label="时间范围">
                <el-date-picker
                    unlink-panels
                    v-model="search.range_at"
                    @change="TableSearch"
                    type="daterange"
                    value-format="yyyy-MM-dd"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :style="{ width: '100%' }" />
            </el-form-item>
        </template>

        <!-- 表格 -->
        <vxe-table
            ref="xTable"
            :data="table"
            :loading="loading"
            :export-config="{}"
            :print-config="{}"
            @sort-change="TableSort"
            height="100%">
            
            <!-- 复选框 -->
            <vxe-column type="checkbox" width="40" fixed="left"></vxe-column>

            <!-- 序号 -->
            <vxe-column width="60" type="seq" title="序号"></vxe-column>

            <!-- 工单类型/模板 -->
            <vxe-column width="140" field="template.name" title="工单类型/模板" sortable></vxe-column>

            <!-- 紧急程度 -->
            <vxe-column width="120" field="p" title="紧急程度">
                <template #default="{ row }">
                    <el-tag v-if="row.p == 1" size="small" effect="plain" type="danger">P1 非常重要</el-tag>
                    <el-tag v-if="row.p == 2" size="small" effect="plain" type="warning">P2 重要</el-tag>
                    <el-tag v-if="row.p == 3" size="small" effect="plain" type="primary">P3 次要</el-tag>
                    <el-tag v-if="row.p == 4" size="small" effect="plain" type="success">P4 一般</el-tag>
                </template>
            </vxe-column>

            <!-- 工单编号 -->
            <vxe-column width="160" field="number" title="工单编号">
                <template #default="{ row }">
                    <el-link type="primary" @click="$refs.drawerDetails.Open(row.id)"><i class="el-icon-paperclip w-margin-r-5" v-if="row.files_id.length > 0"></i>{{ row.number }}</el-link><br>
                </template>
            </vxe-column>

            <!-- 工单名称 -->
            <vxe-column min-width="350" field="name" title="工单名称">
                <template #default="{ row }">
                    <el-tag class="w-margin-r-5" size="mini" effect="dark" type="warning" v-if="row.state == 0">待确认</el-tag>
                    <el-tag class="w-margin-r-5" size="mini" effect="dark" v-if="row.state == 1">进行中</el-tag>
                    <el-tag class="w-margin-r-5" size="mini" effect="dark" type="success" v-if="row.state == 2">已完成</el-tag>
                    <el-tag class="w-margin-r-5" size="mini" effect="dark" type="info" v-if="row.state == 3">已关闭</el-tag>
                    <el-tag class="w-margin-r-5" size="mini" effect="dark" type="warning" v-if="row.state == 4">已暂停</el-tag>
                    <el-tag class="w-margin-r-5" size="mini" effect="dark" type="warning" v-if="row.state == 5">已取消</el-tag>
                    <el-tag class="w-margin-r-5" size="mini" effect="dark" type="danger" v-if="row.is_overdue">已逾期</el-tag>
                    <el-tag class="w-margin-r-5" size="mini" effect="plain" v-if="row.projects_task_id" type="warning">任</el-tag>
                    {{ row.name }}
                </template>
            </vxe-column>

            <!-- 开始日期 -->
            <vxe-column width="100" field="range_at_start" title="开始日期" sortable>
                <template #default="{ row }">
                    <el-link type="primary">{{ row.range_at_start }}</el-link><br>
                </template>
            </vxe-column>

            <!-- 截止时间 -->
            <vxe-column width="100" field="range_at_end" title="截止时间" sortable>
                <template #default="{ row }">
                    <el-link type="primary">{{ row.range_at_end }}</el-link><br>
                </template>
            </vxe-column>

            <!-- 预计工时 -->
            <vxe-column width="100" field="day_predict" title="预计工时/P" sortable>
                <template #default="{ row }">
                    <el-link type="primary" :underline="false">{{ row.day_predict }}</el-link>
                </template>
            </vxe-column>

            <!-- 消耗工时 -->
            <vxe-column width="100" field="day_consume" title="消耗工时/P" sortable>
                <template #default="{ row }">
                    <el-link type="warning" :underline="false">{{ row.day_consume }}</el-link>
                </template>
            </vxe-column>

            <!-- 工单类型/模板 -->
            <vxe-column width="100" field="day_surplus" title="剩余工时/P" sortable>
                <template #default="{ row }">
                    <el-link type="success" :underline="false">{{ row.day_surplus }}</el-link>
                </template>
            </vxe-column>

            <!-- 收单人 -->
            <vxe-column width="110" field="user_receive_id" title="收单人">
                <template #default="{ row }">
                    <div style="display: flex; align-items: center; justify-content: flex-start;">
                        <el-avatar style="margin-right: 5px;" :size="26" :src="row.user_receive.avatar"> {{ row.user_receive.name[0] }} </el-avatar>
                        {{  row.user_receive.name }}
                    </div>
                </template>
            </vxe-column>

            <!-- 派单人 -->
            <vxe-column width="110" field="user_sending_id" title="派单人">
                <template #default="{ row }">
                    <div style="display: flex; align-items: center; justify-content: flex-start;">
                        <el-avatar style="margin-right: 5px;" :size="26" :src="row.user_sending.avatar"> {{ row.user_sending.name[0] }} </el-avatar>
                        {{  row.user_sending.name }}
                    </div>
                </template>
            </vxe-column>

            <!-- 操作 -->
            <vxe-column width="100"  title="操作" align="center" fixed="right">
                <template #default="{ row }">
                    <el-link @click.native="$refs.drawerUpdate.Open(row.id)" type="primary"  class="w-margin-r-10">编辑</el-link>
                    <el-link @click.native="TableDelete(row.id)" type="danger">移除</el-link>
                </template>
            </vxe-column>
        </vxe-table>

        <!-- 组件 -->
        <pm-wo-create ref="drawerCreate" @onChange="TableSearch" />
        <pm-wo-update ref="drawerUpdate" @onChange="TableSearch" />
        <pm-wo-drawer ref="drawerDetails" @onChange="TableSearch" />
        <pm-wo-update-relay-multiple ref="drawerUpdateUserReceive" @onChange="TableSearch" />

    </uw-page>
</template>

<script>
export default {
    props: ['projects_id'],

    data() {
        return {
            // 窗口
            loading: false,

            table: [],

            // 检索
            search: {
                template_id: null,
                number: null,
                name: null,
                state: null,
                customer_id: null,
                user_sending_id: null,
                user_receive_id: null,
                range_at: null
            },

            // 排序
            order: {
                method: 'desc',
                column: 'id'
            },
        }
    },

    watch: {
        projects_id: {
            handler (val) {
                if (val) {
                    this.TableSearch()
                }
            },
            immediate: true
        }
    },

    methods: {

        /**
        |--------------------------------------------------
        | 数据检索
        |--------------------------------------------------
        |
        */

        TableSearch() {
            // 切换加载状态
            this.loading = true

            this.$http.post(
                '/9api/pm/wo/search-all',
                {
                    search: { ...this.search, projects_id: this.projects_id },
                    order: this.order,
                    append: ['user_admin', 'user_receive', 'user_sending', 'user_assist', 'template'],
                    hidden: ['describe']
                }
            ).then(rps => {
                this.table = rps.data
                this.loading = false
            })
        },

        /**
        |--------------------------------------------------
        | 数据排序
        |--------------------------------------------------
        |
        */

        TableSort(column) {
            this.order.method = column.order ? column.order : this.order.method
            this.order.column = column.field
            this.TableSearch()
        },

        /**
        |--------------------------------------------------
        | 移除数据
        |--------------------------------------------------
        |
        */

        TableDelete(id) {
            this.$confirm('此操作将永久删除数据，以及关联的数据，包括且不仅限以下内容: 工时、附件、统计数据等', '请谨慎操作', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'error',
                center: true
            }).then(() => {
                // 切换加载状态
                this.loading = true

                this.$http.post('/9api/wo/delete', { id: id }).then(rps => {
                    this.TableSearch()
                })
            })
        },

        /**
        |--------------------------------------------------
        | 转交工单
        |--------------------------------------------------
        |
        */

        UpdateUserReceive() {
            const ids = this.$refs.xTable.getCheckboxRecords().map(item => item.id)
            this.$refs.drawerUpdateUserReceive.Open(ids)
        }
    }
}
</script>
<template>
    <uw-page title="退货单">

        <!-- 功能 -->
        <template slot="header-suffix">
            <el-button size="mini" type="primary" @click="$refs.backCreate.Open({ product_order_id: id })" >新建退货单</el-button>
        </template>

        <!-- 退货订单 -->
        <vxe-table ref="xTable" :data="table" :loading="loading">
            <!-- 审批状态 -->
            <vxe-column width="90" title="审核状态" align="center">
                <template #default="{ row }">
                    <el-tag v-if="row.confied_state == 0" size="mini" effect="dark" type="info">待审核</el-tag>
                    <el-tag v-if="row.confied_state == 1" size="mini" effect="dark">已同意</el-tag>
                    <el-tag v-if="row.confied_state == 2" size="mini" effect="dark" type="danger">已拒绝</el-tag>
                </template>
            </vxe-column>
            <vxe-column min-width="120" field="number" title="退货单编号">
                <template #default="{ row }">
                    <span class="el-span-primary" @click="$refs.backDrawer.Open(row.id)">{{ row.number }}</span>
                </template>
            </vxe-column>
            <vxe-column min-width="80" field="money" title="退货金额"></vxe-column>
            <vxe-column min-width="80" field="date" title="退货日期"></vxe-column>
            <vxe-column min-width="80" field="reason" title="退货原因"></vxe-column>
            <vxe-column min-width="90" field="describe" title="备注"></vxe-column>
            <vxe-column min-width="90" field="created_user.name" title="负责人"></vxe-column>
            <vxe-column min-width="140" field="created_at" title="创建时间"></vxe-column>
        </vxe-table>

        <!-- 组件 -->
        <ims-product-back-create ref="backCreate" @onChange="TableSearch" />
        <ims-product-back-drawer ref="backDrawer" @onChange="TableSearch" />

    </uw-page>
</template>

<script>
export default {
    props: {
        id: {
            type: Number,
            default: null
        },
    },

    data() {
        return {
            loading: false,
            table: [],
        }
    },

    watch: {
        id: {
            handler(val) {
                this.TableSearch()
            },
            immediate: true
        }
    },
    
    methods: {

        // 订单记录
        TableSearch() {
            this.loading = true
            this.$http.post('/9api/ims/product-back/search-all', { search: { product_order_id: this.id }, append: ['created_user'] }).then(rps => {
                this.table = rps.data
                this.loading = false
            })
        },
    }
}
</script>
<template>
    <el-card v-loading="loading">
        <div slot="header" class="clearfix">
            <span><i class="el-icon-data-board" :style="{ marginRight: '5px', color: '#0027ff' }"></i>销售简报</span>
            <div>
                <hrm-employee-select-range @onChange="UpdateEmployeeRang" :style="{ marginRight: '5px' }" />
                <uw-date-range @onChange="UpdateDataRang" />
            </div>
        </div>

        <uw-statistic :column="3" gutter="10px">
            <!-- 新增客户 -->
            <uw-statistic-item
                @onClick="$refs.customerSelect.Open({ created_at: [search.date_start, search.date_end], users_ids: search.users_ids })"
                type="primary"
                title="新增客户"
                unit="个"
                :value="current.customer"
                :tip="`相比${tip_tile} ${((current.customer - before.customer)/before.customer * 100).toFixed(2)}%`">
                <echarts-line-mini name="近6个月变化" :title="line.customer?.title" :value="line.customer?.value" />
            </uw-statistic-item>

            <!-- 新增联系人 -->
            <uw-statistic-item
                @onClick="$refs.contactsSelect.Open({ created_at: [search.date_start, search.date_end], user_admin_ids: search.users_ids })"
                title="新增联系人"
                unit="人"
                :value="current.contacts"
                :tip="`相比${tip_tile} ${((current.contacts - before.contacts)/before.contacts * 100).toFixed(2)}%`">
                <echarts-line-mini name="近6个月变化" :title="line.contacts.title" :value="line.contacts.value" type="primary" />
            </uw-statistic-item>

            <!-- 新增回访记录 -->
            <uw-statistic-item
                @onClick="$refs.visitSelect.Open({ created_at: [search.date_start, search.date_end], user_admin_ids: search.users_ids })"
                title="新增回访记录"
                unit="条"
                :value="current.visit"
                :tip="`相比${tip_tile} ${((current.visit - before.visit)/before.visit * 100).toFixed(2)}%`">
                <echarts-line-mini name="近6个月变化" :title="line.visit.title" :value="line.visit.value" type="primary" />
            </uw-statistic-item>

            <!-- 新增线索 -->
            <uw-statistic-item
                @onClick="$refs.leadsSelect.Open({ created_at: [search.date_start, search.date_end], user_admin_ids: search.users_ids })"
                title="新增线索"
                unit="个"
                :value="current.leads"
                :tip="`相比${tip_tile} ${((current.leads - before.leads)/before.leads * 100).toFixed(2)}%`">
                <echarts-line-mini name="近6个月变化" :title="line.leads.title" :value="line.leads.value" type="primary" />
            </uw-statistic-item>

            <!-- 新增商机 -->
            <uw-statistic-item
                @onClick="$refs.businessSelect.Open({ created_at: [search.date_start, search.date_end], users_sale_ids: search.users_ids })"
                title="新增商机"
                unit="个"
                :value="current.business"
                :tip="`相比${tip_tile} ${((current.business - before.business)/before.business * 100).toFixed(2)}%`">
                <echarts-line-mini name="近6个月变化" :title="line.business.title" :value="line.business.value" type="primary" />
            </uw-statistic-item>

            <!-- 新增合同 -->
            <uw-statistic-item
                @onClick="$refs.contractSelect.Open({ sign_date: [search.date_start, search.date_end], users_sale_ids: search.users_ids })"
                title="新增合同"
                unit="个"
                :value="current.contract"
                :tip="`相比${tip_tile} ${((current.contract - before.contract)/before.contract * 100).toFixed(2)}%`">
                <echarts-line-mini name="近6个月变化" :title="line.contract.title" :value="line.contract.value" type="primary" />
            </uw-statistic-item>

            <!-- 回款金额 -->
            <uw-statistic-item
                @onClick="$refs.contractPayRecordSelectEnter.Open({ pay_date: [search.date_start, search.date_end], users_ids: search.users_ids })"
                title="回款金额"
                unit="元"
                money
                :value="current.contract_pay"
                :tip="`相比${tip_tile} ${((current.contract_pay - before.contract_pay)/before.contract_pay * 100).toFixed(2)}%`">
                <echarts-line-mini name="近6个月变化" :title="line.contract_pay.title" :value="line.contract_pay.value" type="primary" />
            </uw-statistic-item>

            <!-- 商机金额 -->
            <uw-statistic-item
                @onClick="$refs.businessSelect.Open({ created_at: [search.date_start, search.date_end], users_sale_ids: search.users_ids })"
                title="商机金额"
                unit="元"
                money
                :value="current.business_money"
                :tip="`相比${tip_tile} ${((current.business_money - before.business_money)/before.business_money * 100).toFixed(2)}%`">
                <echarts-line-mini name="近6个月变化" :title="line.business_money.title" :value="line.business_money.value"  type="primary" />
            </uw-statistic-item>

            <!-- 合同金额 -->
            <uw-statistic-item
                @onClick="$refs.contractSelect.Open({ sign_date: [search.date_start, search.date_end], users_sale_ids: search.users_ids })"
                title="合同金额"
                unit="元"
                money
                :value="current.contract_money"
                :tip="`相比${tip_tile} ${((current.contract_money - before.contract_money)/before.contract_money * 100).toFixed(2)}%`">
                <echarts-line-mini name="近6个月变化" :title="line.contract_money.title" :value="line.contract_money.value" type="primary" />
            </uw-statistic-item>
        </uw-statistic>

        <!-- 组件 -->
        <crm-customer-select ref="customerSelect" @onChange="Update" :showInput="false" />
        <crm-contacts-select ref="contactsSelect" @onChange="Update" :showInput="false" />
        <crm-visit-select ref="visitSelect" @onChange="Update" :showInput="false" />
        <crm-leads-select ref="leadsSelect" @onChange="Update" :showInput="false" />
        <crm-business-select ref="businessSelect" @onChange="Update" :showInput="false" />
        <crm-contract-select ref="contractSelect" @onChange="Update" :showInput="false" />
        <crm-contract-pay-record-select-enter ref="contractPayRecordSelectEnter" @onChange="Update" :showInput="false" />

    </el-card>
</template>

<script>
export default {
    data() {
        return {
            current: { },
            before: { },

            line: {
                customer: { title: ['日期', '日期', '日期', '日期', '日期', '日期'], value: [0,0,0,0,0,0]  },
                contacts: { title: ['日期', '日期', '日期', '日期', '日期', '日期'], value: [0,0,0,0,0,0]  },
                visit: { title: ['日期', '日期', '日期', '日期', '日期', '日期'], value: [0,0,0,0,0,0]  },
                leads: { title: ['日期', '日期', '日期', '日期', '日期', '日期'], value: [0,0,0,0,0,0]  },
                business: { title: ['日期', '日期', '日期', '日期', '日期', '日期'], value: [0,0,0,0,0,0]  },
                contract: { title: ['日期', '日期', '日期', '日期', '日期', '日期'], value: [0,0,0,0,0,0]  },
                contract_pay: { title: ['日期', '日期', '日期', '日期', '日期', '日期'], value: [0,0,0,0,0,0]  },
                business_money: { title: ['日期', '日期', '日期', '日期', '日期', '日期'], value: [0,0,0,0,0,0]  },
                contract_money: { title: ['日期', '日期', '日期', '日期', '日期', '日期'], value: [0,0,0,0,0,0]  }
            },

            search: {
                users_ids: [this.$store.state.user.id],
                date_type: 'month',
                date_start: this.$lodash.date_range('month')[0],
                date_end: this.$lodash.date_range('month')[1],
            },

            tip_tile: null
        }
    },

    computed: {
        loading () {
            return !!this.$store.state.httpRequestCount
        }
    },

    watch: {
        search: {
            handler() {
                if (this.search.date_type == 'day') this.tip_tile = '昨天'
                if (this.search.date_type == 'week') this.tip_tile = '上周'
                if (this.search.date_type == 'month') this.tip_tile = '上月'
                if (this.search.date_type == 'quarter') this.tip_tile = '上季度'
                if (this.search.date_type == 'year') this.tip_tile = '上年度'
                this.Update()
            },
            deep:true,
            immediate: true
        }
    },

    methods: {
        Update() {
            this.$http.post('/9api/statistics-crm/xxb-a-a', this.search ).then(rps => {
                this.current = rps.data
            })
            this.$http.post('/9api/statistics-crm/xxb-a-a', { ...this.search, date_start: this.$lodash.date_range(this.search.date_type, -1)[0], date_end: this.$lodash.date_range(this.search.date_type, -1)[1] }  ).then(rps => {
                this.before = rps.data
            })
            this.$http.post('/9api/statistics-crm/xxb-a-b', this.search  ).then(rps => {
                this.line = rps.data
            })
        },

        UpdateEmployeeRang(arr) {
            this.search.users_ids = arr
        },

        UpdateDataRang({ date_type, date_start, date_end }) {
            this.search.date_type = date_type
            this.search.date_start = date_start
            this.search.date_end = date_end
        },
    }
}
</script>
<template>

    <!-- 容器 -->
    <div class="wu-page">

        <!-- 左侧 -->
        <div class="wu-page-left" :style="{ width: visible ? splitWidth : '0px', borderWidth: visible ? '1px' : '0px' }">
            <slot name="split"></slot>
        </div>

        <!-- 右侧 -->
        <div class="wu-page-right">

            <!-- 页头 -->
            <div class="wu-page-header">

                <!-- 展开/收起 -->
                <el-button v-if="split" size="medium" @click="visible = !visible" :icon="visible ? 'el-icon-s-fold' : 'el-icon-s-unfold'" circle />
                <i  v-if="!split" class="el-icon-monitor" :style="{ fontSize: '22px' }"></i>

                <!-- 标题 -->
                <span class="wu-page-title" v-if="title">{{ title }}</span>

                <!-- 页头插槽：左侧 -->
                <div class="wu-page-header-prefix">
                    <slot name="header-prefix"></slot>
                </div>

                <!-- 页头插槽：右侧 -->
                <div class="wu-page-header-suffix">
                    <slot name="header-suffix"></slot>
                </div>

            </div>

            <!-- 检索 -->
            <div class="wu-page-search" v-if="$slots.search">
                <el-form class="wu-page-search-form" label-position="top" id="wu-page-search-form">
                    <slot name="search"></slot>
                </el-form>
            </div>

            <div class="wu-page-content">
                <slot></slot>
            </div>

            <div class="wu-page-footer">
                <slot name="footer"></slot>
            </div>
        </div>
        
    </div>

</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: null
        },
        splitWidth: {
            type: String,
            default: '220px'
        },
        split: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            visible: false
        }
    },

    mounted() {
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                entry.target.style.gridTemplateColumns = 'repeat(' + Math.floor(entry.contentRect.width / 250) + ', 1fr)'
            }
        })
        if (this.$slots.search) {
            resizeObserver.observe(document.getElementById('wu-page-search-form'))
        }
    }
}
</script>

<style lang="less">
    .wu-page { width: 100%; height: 100%; overflow: hidden; }
    .wu-page { display: flex; }
    .wu-page-left  { height: 100%; border-right: 1px solid #EEEEEE; transition: 500ms; overflow: hidden; }
    .wu-page-right { flex: 1 0 0; width: 0; height: 100%; }
    .wu-page-right { display: flex; flex-direction: column; }
    .wu-page-header { padding: 20px 20px 10px 20px; white-space: nowrap; }
    .wu-page-header { display: flex; align-items: center; }
    .wu-page-title { padding-left: 10px; margin-left: 10px; font-weight: bold; font-size: 18px; border-left: 1px solid #EEEEEE; }
    .wu-page-header-prefix { padding-left: 10px; margin-left: 10px; border-left: 1px solid #EEEEEE; }
    .wu-page-header-suffix { flex: 1 0 0; width: 0; text-align: right; }
    .wu-page-search { padding: 0px 20px 20px 20px; }
    .wu-page-search-form { background: #f5f5f5; padding: 10px 20px; border-radius: 5px; }
    .wu-page-search-form { display: grid; grid-template-columns: repeat(4, 1fr); gap: 10px; }
    .wu-page-search-form .el-form-item { margin-bottom: 0px !important; }
    .wu-page-content { flex: 1 0 0; height: 0; padding: 0 20px 10px 20px; overflow: auto; }
    .wu-page-footer { padding: 0px 20px 10px 20px; }
</style>
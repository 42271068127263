<template>
    <el-select
        @change="SelectChange"
        v-model="selectValue"
        :style="{ width: width, margin: margin }"
        size="mini"
        filterable
        allow-create
        default-first-option
        placeholder="请选择计量单位"
        clearable>
        <el-option v-for="(item, idx) in select" :key="idx" :label="item.name" :value="item.name"></el-option>
    </el-select>
</template>

<script>
export default {
    props: {
        value: {
            type: [String, Array, Number],
            default: null
        },

        width: {
            type: String,
            default: '100%'
        },

        margin: {
            type: String,
            default: '0px'
        },
    },

    watch: {
        value () {
            this.selectValue = this.value
        },
    },

    data () {
        return {
            select: [
                { name: '单位' },
                { name: '项' },
                { name: '台' },
                { name: '套' },
                { name: '块' },
                { name: '条' },
                { name: '个' },
                { name: '只' },
                { name: '支' },
                { name: '米' },
                { name: '包' },
                { name: '盒' },
                { name: '对' },
                { name: '年' },
                { name: '袋' },
                { name: '卷' },
                { name: '次' },
                { name: '平方' },
                { name: '箱' },
                { name: '张' },
                { name: '系统' },
                { name: '年' },
                { name: '台' },
                { name: '节' },
                { name: '套' },
                { name: '平方米' },
                { name: '颗' },
                { name: '粒' },
                { name: '个' },
                { name: '人日' },
                { name: '箱' },
                { name: '册' },
                { name: '本' },
                { name: '年/台' },
                { name: '件' },
                { name: '瓶' },
                { name: '项' },
                { name: '年套' },
                { name: 'EA' }
            ],
            selectValue: null,
        }
    },

    methods: {
        SelectChange(data) {
            this.$emit('input', data)
            this.$emit('onChange', data)
        }
    }
}
</script>